import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../../images/back.png";
import nextWhiteIcon from "../../../../../images/next-white.png";
import nextIcon from "../../../../../images/next.png";
import LoadingSpinner from "../../../../custom/loader/LoadingSpinner";
import KneeScoreRangeSlider from "../../../../custom/knee-score-range-slider/KneeScoreRangeSlider";
import FullDropdown from "../../../../custom/dropdown/FullDropdown";
import {
  updateAdultKneeSymptomsScore,
  updateAdultKneeSymptomsScoreField,
  updateAdultKneeScores,
  updateAdultKneePainScores,
  updateAdultKneeQolScore,
  updateAdultKneeSatisfactionScore,
  updateAdultKneeFunctionScore,
  updateAdultKneeNorwichInstaScore,
  updateAllData,
  updateAdultKneeFeedback
} from "../../../../../redux/actions/index";
import useAxios from "../../../../../axiosinstance";
import useTransition from '../../../../customHooks/translations';
import AdultkneeTab from '../../../tab-section/AdultkneeTab';
import { useSearchParams } from "react-router-dom";
import CustomToaster from '../../../../custom/toaster/CustomToaster'
import { LeftSide, LeftTrans, RightSide, RigthTrans } from "../../../../../utilities/RightLeftFormat";
import NameCustom from "../../../../custom/name/NameCustom";


function AdultKneeSymptoms() {
  const translation = useTransition();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const axiosinstance = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  const [swellingR, setSwellingR] = useState("0");
  const [swellingL, setSwellingL] = useState("0");
  const [grindingR, setGrindingR] = useState("0");
  const [grindingL, setGrindingL] = useState("0");
  const [catchingR, setCatchingR] = useState("0");
  const [catchingL, setCatchingL] = useState("0");
  const [limpingR, setLimpingR] = useState("0");
  const [limpingL, setLimpingL] = useState("0");
  const [significantSwellingR, setSignificantSwellingR] = useState("0");
  const [significantSwellingL, setSignificantSwellingL] = useState("0");
  const [awakeningR, setAwakeningR] = useState("0");
  const [awakeningL, setAwakeningL] = useState("0");
  const [restingLaterR, setRestingLaterR] = useState("0");
  const [restingLaterL, setRestingLaterL] = useState("0");
  const [instabilityR, setInstabilityR] = useState("0");
  const [instabilityL, setInstabilityL] = useState("0");
  const [age, setAge] = useState("0")

  const [swollenR, setswollenR] = useState("0")
  const [swollenL, setswollenL] = useState("0")

  const [supportR, setSupportR] = useState("0");
  const [supportL, setSupportL] = useState("0");
  const [kneecapR, setkneecapR] = useState("0");
  const [kneecapL, setkneecapL] = useState("0");
  const evlAdultKnee = useSelector(state => state.adultKneeScore.evlAdultKnee);
  const symptomsScore = useSelector(state => state.adultKneeScore.symptomsScore);
  const [chk, setChk] = useState(false);
  const [updateTotal, setUpdateTotal] = useState(false);
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=5`)
      .then((res) => {

        setAge(res.data.data2[0].age)
        dispatch(updateAdultKneePainScores(res.data.data2[0].painScore));
        dispatch(updateAdultKneeSymptomsScore(res.data.data2[0].symptomsScore));
        dispatch(updateAdultKneeScores(res.data.data2[0].evlAdultKnee));
        dispatch(updateAdultKneeQolScore(res.data.data2[0].qolScore));
        dispatch(updateAdultKneeFunctionScore(res.data.data2[0].functionScore));
        dispatch(updateAdultKneeNorwichInstaScore(res.data.data2[0].norwichInstability));
        dispatch(
          updateAdultKneeSatisfactionScore(res.data.data2[0].satisfactionScore)
        );
        dispatch(
          updateAdultKneeFeedback(res.data.data2[0].doctorFeedback)
        );
        setChk(true);
      }).catch((err) => {

        navigate('/un-authorized-access');

      });
  };

  useEffect(() => {
    save();
  }, []);

  const KneeSymptoms = useSelector((state) => state.adultKneeScore.symptomsScore[0]);
  const json = useSelector((state) => state.adultKneeScore);
  useEffect(() => {
    setSwellingR(KneeSymptoms.swellingR)
    setSwellingL(KneeSymptoms.swellingL)
    setGrindingR(KneeSymptoms.grindingR)
    setGrindingL(KneeSymptoms.grindingL)
    setCatchingR(KneeSymptoms.catchingR)
    setCatchingL(KneeSymptoms.catchingL)
    setLimpingR(KneeSymptoms.limpingR)
    setLimpingL(KneeSymptoms.limpingL)
    setswollenR(KneeSymptoms.swollenR)
    setswollenL(KneeSymptoms.swollenL)
    setSignificantSwellingR(KneeSymptoms.significantSwellingR)
    setSignificantSwellingL(KneeSymptoms.significantSwellingL)
    setAwakeningR(KneeSymptoms.awakeningR)
    setAwakeningL(KneeSymptoms.awakeningL)
    setRestingLaterR(KneeSymptoms.restingLaterR)
    setRestingLaterL(KneeSymptoms.restingLaterL)
    setInstabilityR(KneeSymptoms.instabilityR)
    setInstabilityL(KneeSymptoms.instabilityL)
    setSupportR(KneeSymptoms.supportR)
    setSupportL(KneeSymptoms.supportL)



  }, [KneeSymptoms])

  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true"?"1":"0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [KneeSymptoms, updateTotal]);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleSwellingR = (e) => {
    setSwellingR(e);
    dispatch(updateAdultKneeSymptomsScoreField({
      "key": 'swellingR',
      "value": e
    }))
  };
  const handleSwellingL = (e) => {
    setSwellingL(e);
    dispatch(updateAdultKneeSymptomsScoreField({
      "key": 'swellingL',
      "value": e
    }))
  };

  const handleGrindingR = (e) => {
    setGrindingR(e);
    dispatch(updateAdultKneeSymptomsScoreField({
      "key": 'grindingR',
      "value": e
    }))
  };
  const handleGrindingL = (e) => {
    setGrindingL(e);
    dispatch(updateAdultKneeSymptomsScoreField({
      "key": 'grindingL',
      "value": e
    }))
  };

  const handleCatchingR = (e) => {
    setCatchingR(e);
    dispatch(updateAdultKneeSymptomsScoreField({
      "key": 'catchingR',
      "value": e
    }))
  };
  const handleCatchingL = (e) => {
    setCatchingL(e);
    dispatch(updateAdultKneeSymptomsScoreField({
      "key": 'catchingL',
      "value": e
    }))
  };

  const handleLimpingR = (e) => {
    setLimpingR(e);
    dispatch(updateAdultKneeSymptomsScoreField({
      "key": 'limpingR',
      "value": e
    }))
  };
  const handleLimpingL = (e) => {
    setLimpingL(e);
    dispatch(updateAdultKneeSymptomsScoreField({
      "key": 'limpingL',
      "value": e
    }))
  };

  const handleSignificantSwellingR = (e) => {
    setSignificantSwellingR(e);
    dispatch(updateAdultKneeSymptomsScoreField({
      "key": 'significantSwellingR',
      "value": e
    }))
  };
  const handleSignificantSwellingL = (e) => {
    setSignificantSwellingL(e);
    dispatch(updateAdultKneeSymptomsScoreField({
      "key": 'significantSwellingL',
      "value": e
    }))
  };

  const handleAwakeningR = (e) => {
    setAwakeningR(e);
    dispatch(updateAdultKneeSymptomsScoreField({
      "key": 'awakeningR',
      "value": e
    }))
  };
  const handleAwakeningL = (e) => {
    setAwakeningL(e);
    dispatch(updateAdultKneeSymptomsScoreField({
      "key": 'awakeningL',
      "value": e
    }))

  };

  const handleRestingLaterR = (e) => {
    setRestingLaterR(e);
    dispatch(updateAdultKneeSymptomsScoreField({
      "key": 'restingLaterR',
      "value": e
    }))
  };
  const handleRestingLaterL = (e) => {
    setRestingLaterL(e);
    dispatch(updateAdultKneeSymptomsScoreField({
      "key": 'restingLaterL',
      "value": e
    }))
  };

  const handleInstabilityR = (e) => {
    setInstabilityR(e);
    dispatch(updateAdultKneeSymptomsScoreField({
      "key": 'instabilityR',
      "value": e
    }))
  };
  const handleInstabilityL = (e) => {
    setInstabilityL(e);
    dispatch(updateAdultKneeSymptomsScoreField({
      "key": 'instabilityL',
      "value": e
    }))
  };

  const handleSupportR = (e) => {
    setSupportR(e);
    dispatch(updateAdultKneeSymptomsScoreField({
      "key": 'supportR',
      "value": e
    }))
  };
  const handleSupportL = (e) => {
    setSupportL(e);
    dispatch(updateAdultKneeSymptomsScoreField({
      "key": 'supportL',
      "value": e
    }))
  };

  const handleKneecapR = (e) => {
    setkneecapR(e)
    dispatch(updateAdultKneeSymptomsScoreField({
      "key": 'kneecapR',
      "value": e
    }))
  }

  const handleKneecapL = (e) => {
    setkneecapL(e)
    dispatch(updateAdultKneeSymptomsScoreField({
      "key": 'kneecapL',
      "value": e
    }))
  }


  const handleSwallowR = (e) => {
    setswollenR(e);
    dispatch(updateAdultKneeSymptomsScoreField({
      "key": 'swollenR',
      "value": e
    }))
  };


  const handleSwallowL = (e) => {
    setswollenL(e);
    dispatch(updateAdultKneeSymptomsScoreField({
      "key": 'swollenL',
      "value": e
    }))
  };







  const continueRedirect = (e) => {
    dispatch(updateAdultKneeSymptomsScore(
      [{
        "swellingR": swellingR,
        "swellingL": swellingL,
        "grindingR": grindingR,
        "grindingL": grindingL,
        "catchingR": catchingR,
        "catchingL": catchingL,
        "limpingR": limpingR,
        "limpingL": limpingL,
        "swollenR": swollenR,
        "swollenL": swollenL,
        "significantSwellingR": significantSwellingR,
        "significantSwellingL": significantSwellingL,
        "awakeningR": awakeningR,
        "awakeningL": awakeningL,
        "restingLaterR": restingLaterR,
        "restingLaterL": restingLaterL,
        "instabilityR": instabilityR,
        "instabilityL": instabilityL,
        "supportR": supportR,
        "supportL": supportL,
      }]
    ));
    if (kneecapR > "0" || kneecapL > "0") {
      navigate("/patellar-instability/" + rId)
    } else {

      navigate("/adult-knee-function/" + rId);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  let akpt = searchParams.get("akpt");

  let doctordata =localStorage.getItem("isdoctorForm");
  let viewicon=localStorage.getItem("viewicon")
  const [msg, setMsg] = useState('');
  const [msgType, setmsgType] = useState('');
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update)
    setmsgType('success')
    setTimeout(() => {
      window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + akpt
      setMsg('')
      setmsgType('success')
    }, 2000);
  };
  const handleNext = () => {
    navigate(`/adult-knee-function/${rId}?d=true&akpt=${akpt}`);

  }
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="pain_screen mb_100">
           <div class="d-flex justify-content-end">
            <NameCustom />
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && <AdultkneeTab rId={rId} />}
              <CustomToaster msg={msg} msgType={msgType} />
            
              <h4 className="ph_title f_40">{translation.patient_adult_knee_other_symptoms}</h4>

              {/* {String(age) && String(age) !== "1" ? <h4 className="ph_title f_40">{translation.Adult_Knee_Symptoms}</h4> : <h4 className="ph_title f_40">{translation.Adult_Knee_Symptoms_13_18_year}</h4>} */}


              <div className="function_formtop">
                <div className="row">
                  <div className="col-md-6 col-sm-6 function_head">
                    <label>
                      {translation.patient_adult_knee_other_symptoms_instruction1}
                    </label><br />
                    <label>
                      {translation.patient_adult_knee_other_symptoms_instruction2}
                    </label><br />
                    <label className="mt-2">
                      {translation.patient_adult_knee_other_symptoms_instruction3}
                    </label>

                  </div>
                  <div className="col-md-6 col-sm-6 function_note">

                  </div>
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>

                      <KneeScoreRangeSlider
                        label={translation.patient_adult_knee_other_symptoms_q1 + RigthTrans(evlAdultKnee, translation)}
                        classes=""
                        value={swellingR}
                        onChange={handleSwellingR}
                        sliderData={[
                          { score: "0", label: translation.patient_adult_knee_other_symptoms_q1_a1 },
                          { score: "1", label: translation.patient_adult_knee_other_symptoms_q1_a2 },
                          { score: "2", label: translation.patient_adult_knee_other_symptoms_q1_a3 },
                          { score: "3", label: translation.patient_adult_knee_other_symptoms_q1_a4 },
                          { score: "4", label: translation.patient_adult_knee_other_symptoms_q1_a5 },
                        ]}
                      />
                    </div>
                  ) : ""}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>

                      <KneeScoreRangeSlider
                        label={translation.patient_adult_knee_other_symptoms_q1 + LeftTrans(evlAdultKnee, translation)}
                        classes=""
                        value={swellingL}
                        onChange={handleSwellingL}
                        sliderData={[
                          { score: "0", label: translation.patient_adult_knee_other_symptoms_q1_a1 },
                          { score: "1", label: translation.patient_adult_knee_other_symptoms_q1_a2 },
                          { score: "2", label: translation.patient_adult_knee_other_symptoms_q1_a3 },
                          { score: "3", label: translation.patient_adult_knee_other_symptoms_q1_a4 },
                          { score: "4", label: translation.patient_adult_knee_other_symptoms_q1_a5 },
                        ]}
                      />
                    </div>
                  ) : ""}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_adult_knee_other_symptoms_q2 + RigthTrans(evlAdultKnee, translation)}
                        classes=""
                        value={grindingR}
                        onChange={handleGrindingR}
                        sliderData={[
                          { score: "0", label: translation.patient_adult_knee_other_symptoms_q2_a1 },
                          { score: "1", label: translation.patient_adult_knee_other_symptoms_q2_a2 },
                          { score: "2", label: translation.patient_adult_knee_other_symptoms_q2_a3 },
                          { score: "3", label: translation.patient_adult_knee_other_symptoms_q2_a4 },
                          { score: "4", label: translation.patient_adult_knee_other_symptoms_q2_a5 },
                        ]}
                      />
                    </div>
                  ) : ""}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_adult_knee_other_symptoms_q2 + LeftTrans(evlAdultKnee, translation)}
                        classes=""
                        value={grindingL}
                        onChange={handleGrindingL}
                        sliderData={[
                          { score: "0", label: translation.patient_adult_knee_other_symptoms_q2_a1 },
                          { score: "1", label: translation.patient_adult_knee_other_symptoms_q2_a2 },
                          { score: "2", label: translation.patient_adult_knee_other_symptoms_q2_a3 },
                          { score: "3", label: translation.patient_adult_knee_other_symptoms_q2_a4 },
                          { score: "4", label: translation.patient_adult_knee_other_symptoms_q2_a5 },
                        ]}
                      />
                    </div>
                  ) : ""}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_adult_knee_other_symptoms_q3 + RigthTrans(evlAdultKnee, translation)}
                        classes=""
                        value={catchingR}
                        onChange={handleCatchingR}
                        sliderData={[
                          { score: "0", label: translation.patient_adult_knee_other_symptoms_q3_a1 },
                          { score: "1", label: translation.patient_adult_knee_other_symptoms_q3_a2 },
                          { score: "2", label: translation.patient_adult_knee_other_symptoms_q3_a3 },
                          { score: "3", label: translation.patient_adult_knee_other_symptoms_q3_a4 },
                          { score: "4", label: translation.patient_adult_knee_other_symptoms_q3_a5 },
                        ]}
                      />
                    </div>
                  ) : ""}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_adult_knee_other_symptoms_q3 + LeftTrans(evlAdultKnee, translation)}
                        classes=""
                        value={catchingL}
                        onChange={handleCatchingL}
                        sliderData={[
                          { score: "0", label: translation.patient_adult_knee_other_symptoms_q3_a1 },
                          { score: "1", label: translation.patient_adult_knee_other_symptoms_q3_a2 },
                          { score: "2", label: translation.patient_adult_knee_other_symptoms_q3_a3 },
                          { score: "3", label: translation.patient_adult_knee_other_symptoms_q3_a4 },
                          { score: "4", label: translation.patient_adult_knee_other_symptoms_q3_a5 },
                        ]}
                      />
                    </div>
                  ) : ""}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_adult_knee_other_symptoms_q4 + RigthTrans(evlAdultKnee, translation)}
                        classes=""
                        value={limpingR}
                        onChange={handleLimpingR}
                        sliderData={[
                          { score: "0", label: translation.patient_adult_knee_other_symptoms_q4_a1 },
                          { score: "1", label: translation.patient_adult_knee_other_symptoms_q4_a2 },
                          { score: "2", label: translation.patient_adult_knee_other_symptoms_q4_a3 },
                          { score: "3", label: translation.patient_adult_knee_other_symptoms_q4_a4 },
                          { score: "4", label: translation.patient_adult_knee_other_symptoms_q4_a5 },
                        ]}
                      />
                    </div>
                  ) : ""}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_adult_knee_other_symptoms_q4 + LeftTrans(evlAdultKnee, translation)}
                        classes=""
                        value={limpingL}
                        onChange={handleLimpingL}
                        sliderData={[
                          { score: "0", label: translation.patient_adult_knee_other_symptoms_q4_a1 },
                          { score: "1", label: translation.patient_adult_knee_other_symptoms_q4_a2 },
                          { score: "2", label: translation.patient_adult_knee_other_symptoms_q4_a3 },
                          { score: "3", label: translation.patient_adult_knee_other_symptoms_q4_a4 },
                          { score: "4", label: translation.patient_adult_knee_other_symptoms_q4_a5 },
                        ]}
                      />
                    </div>
                  ) : ""}
                </div>




                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_adult_knee_other_symptoms_q5 + RigthTrans(evlAdultKnee, translation)}
                        styleClass=""
                        value={swollenR}
                        placeholder={translation.none}
                        onChange={handleSwallowR}
                        sliderData={[
                          { score: "0", label: translation.patient_adult_knee_other_symptoms_q5_a1 },
                          { score: "1", label: translation.patient_adult_knee_other_symptoms_q5_a2 },
                          { score: "2", label: translation.patient_adult_knee_other_symptoms_q5_a3 },
                          { score: "3", label: translation.patient_adult_knee_other_symptoms_q5_a4 },
                          { score: "4", label: translation.patient_adult_knee_other_symptoms_q5_a5 },
                        ]}
                      />

                    </div>
                  ) : ""}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_adult_knee_other_symptoms_q5 + LeftTrans(evlAdultKnee, translation)}
                        styleClass=""
                        value={swollenL}
                        placeholder={translation.none}
                        onChange={handleSwallowL}
                        sliderData={[
                          { score: "0", label: translation.patient_adult_knee_other_symptoms_q5_a1 },
                          { score: "1", label: translation.patient_adult_knee_other_symptoms_q5_a2 },
                          { score: "2", label: translation.patient_adult_knee_other_symptoms_q5_a3 },
                          { score: "3", label: translation.patient_adult_knee_other_symptoms_q5_a4 },
                          { score: "4", label: translation.patient_adult_knee_other_symptoms_q5_a5 },
                        ]}
                      />

                    </div>
                  ) : ""}
                </div>



                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <FullDropdown
                        data={[
                          {
                            value: "4",
                            label: translation.patient_adult_knee_other_symptoms_q6_a1,
                          },
                          {
                            value: "3",
                            label: translation.patient_adult_knee_other_symptoms_q6_a2,
                          },
                          {
                            value: "2",
                            label: translation.patient_adult_knee_other_symptoms_q6_a3,
                          },
                          {
                            value: "1",
                            label: translation.patient_adult_knee_other_symptoms_q6_a4,
                          },
                          {
                            value: "0",
                            label: translation.patient_adult_knee_other_symptoms_q6_a5,
                          },
                        ]}
                        label={translation.patient_adult_knee_other_symptoms_q6 + RigthTrans(evlAdultKnee, translation)}
                        styleClass=""
                        value={significantSwellingR}
                        placeholder={translation.none}
                        onChange={handleSignificantSwellingR}
                      />
                    </div>
                  ) : ""}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <FullDropdown
                        data={[
                          {
                            value: "4",
                            label: translation.patient_adult_knee_other_symptoms_q6_a1,
                          },
                          {
                            value: "3",
                            label: translation.patient_adult_knee_other_symptoms_q6_a2,
                          },
                          {
                            value: "2",
                            label: translation.patient_adult_knee_other_symptoms_q6_a3,
                          },
                          {
                            value: "1",
                            label: translation.patient_adult_knee_other_symptoms_q6_a4,
                          },
                          {
                            value: "0",
                            label: translation.patient_adult_knee_other_symptoms_q6_a5,
                          },
                        ]}
                        label={translation.patient_adult_knee_other_symptoms_q6 + LeftTrans(evlAdultKnee, translation)}
                        styleClass=""
                        value={significantSwellingL}
                        placeholder={translation.none}
                        onChange={handleSignificantSwellingL}
                      />
                    </div>
                  ) : ""}
                </div>

                <br />

                <div className="row">
                  <div className="col-md-6 col-sm-6 function_head">
                    <label>
                      {translation.patient_adult_knee_other_symptoms_q7}
                    </label>
                    <br />
                    <span>
                      (<strong>0</strong> = {translation.patient_adult_knee_other_symptoms_q7_0}, <strong>1</strong> = {translation.patient_adult_knee_other_symptoms_q7_1},{" "}
                      <strong>2</strong> = {translation.patient_adult_knee_other_symptoms_q7_2}, <strong>3</strong> = {translation.patient_adult_knee_other_symptoms_q7_3}, <strong>4</strong> = {translation.patient_adult_knee_other_symptoms_q7_4})
                    </span>
                  </div>
                  <div className="col-md-6 col-sm-6 function_note">
                  </div>
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_adult_knee_other_symptoms_q7_q1 + RigthTrans(evlAdultKnee, translation)}
                        classes=""
                        value={awakeningR}
                        onChange={handleAwakeningR}
                        sliderData={[
                          { score: "0", label: translation.patient_adult_knee_other_symptoms_q7_q1_a1 },
                          { score: "1", label: translation.patient_adult_knee_other_symptoms_q7_q1_a2 },
                          { score: "2", label: translation.patient_adult_knee_other_symptoms_q7_q1_a3 },
                          { score: "3", label: translation.patient_adult_knee_other_symptoms_q7_q1_a4 },
                          { score: "4", label: translation.patient_adult_knee_other_symptoms_q7_q1_a5 },
                        ]}
                      />
                    </div>
                  ) : ""}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_adult_knee_other_symptoms_q7_q1 + LeftTrans(evlAdultKnee, translation)}
                        classes=""
                        value={awakeningL}
                        onChange={handleAwakeningL}
                        sliderData={[
                          { score: "0", label: translation.patient_adult_knee_other_symptoms_q7_q1_a1 },
                          { score: "1", label: translation.patient_adult_knee_other_symptoms_q7_q1_a2 },
                          { score: "2", label: translation.patient_adult_knee_other_symptoms_q7_q1_a3 },
                          { score: "3", label: translation.patient_adult_knee_other_symptoms_q7_q1_a4 },
                          { score: "4", label: translation.patient_adult_knee_other_symptoms_q7_q1_a5 },
                        ]}
                      />
                    </div>
                  ) : ""}

                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_adult_knee_other_symptoms_q7_q2 + RigthTrans(evlAdultKnee, translation)}
                        classes=""
                        value={restingLaterR}
                        onChange={handleRestingLaterR}
                        sliderData={[
                          { score: "0", label: translation.patient_adult_knee_other_symptoms_q7_q2_a1 },
                          { score: "1", label: translation.patient_adult_knee_other_symptoms_q7_q2_a2 },
                          { score: "2", label: translation.patient_adult_knee_other_symptoms_q7_q2_a3 },
                          { score: "3", label: translation.patient_adult_knee_other_symptoms_q7_q2_a4 },
                          { score: "4", label: translation.patient_adult_knee_other_symptoms_q7_q2_a5 },
                        ]}
                      />
                    </div>
                  ) : ""}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_adult_knee_other_symptoms_q7_q2 + LeftTrans(evlAdultKnee, translation)}
                        classes=""
                        value={restingLaterL}
                        onChange={handleRestingLaterL}
                        sliderData={[
                          { score: "0", label: translation.patient_adult_knee_other_symptoms_q7_q2_a1 },
                          { score: "1", label: translation.patient_adult_knee_other_symptoms_q7_q2_a2 },
                          { score: "2", label: translation.patient_adult_knee_other_symptoms_q7_q2_a3 },
                          { score: "3", label: translation.patient_adult_knee_other_symptoms_q7_q2_a4 },
                          { score: "4", label: translation.patient_adult_knee_other_symptoms_q7_q2_a5 },
                        ]}
                      />
                    </div>
                  ) : ""}
                </div>




                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <FullDropdown
                        data={[
                          {
                            value: "5",
                            label: translation.patient_adult_knee_other_symptoms_q8_a1,
                          },
                          {
                            value: "4",
                            label: translation.patient_adult_knee_other_symptoms_q8_a2,
                          },
                          {
                            value: "3",
                            label: translation.patient_adult_knee_other_symptoms_q8_a3,
                          },
                          {
                            value: "2",
                            label: translation.patient_adult_knee_other_symptoms_q8_a4,
                          },
                          {
                            value: "1",
                            label: translation.patient_adult_knee_other_symptoms_q8_a5,
                          },
                          {
                            value: "0",
                            label: translation.patient_adult_knee_other_symptoms_q8_a6,
                          },
                        ]}
                        label={translation.patient_adult_knee_other_symptoms_q8 + RigthTrans(evlAdultKnee, translation)}
                        styleClass=""
                        value={instabilityR}
                        placeholder={translation.none}
                        onChange={handleInstabilityR}
                      />
                    </div>
                  ) : ""}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <FullDropdown
                        data={[
                          {
                            value: "5",
                            label: translation.patient_adult_knee_other_symptoms_q8_a1,
                          },
                          {
                            value: "4",
                            label: translation.patient_adult_knee_other_symptoms_q8_a2,
                          },
                          {
                            value: "3",
                            label: translation.patient_adult_knee_other_symptoms_q8_a3,
                          },
                          {
                            value: "2",
                            label: translation.patient_adult_knee_other_symptoms_q8_a4,
                          },
                          {
                            value: "1",
                            label: translation.patient_adult_knee_other_symptoms_q8_a5,
                          },
                          {
                            value: "0",
                            label: translation.patient_adult_knee_other_symptoms_q8_a6,
                          },
                        ]}
                        label={translation.patient_adult_knee_other_symptoms_q8 + LeftTrans(evlAdultKnee, translation)}
                        styleClass=""
                        value={instabilityL}
                        placeholder={translation.none}
                        onChange={handleInstabilityL}
                      />
                    </div>
                  ) : ""}
                </div>






                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_20" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_adult_knee_other_symptoms_q9 + RigthTrans(evlAdultKnee, translation)}
                        classes=""
                        value={supportR}
                        maxnum="2"
                        minnum="0"
                        onChange={handleSupportR}
                        sliderData={[
                          { score: "0", label: translation.patient_adult_knee_other_symptoms_q9_a1 },
                          { score: "1", label: translation.patient_adult_knee_other_symptoms_q9_a2 },
                          { score: "2", label: translation.patient_adult_knee_other_symptoms_q9_a3 },

                        ]}
                      />

                    </div>
                  ) : ""}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_20" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_adult_knee_other_symptoms_q9 + LeftTrans(evlAdultKnee, translation)}
                        classes=""
                        value={supportL}
                        maxnum="2"
                        minnum="0"
                        onChange={handleSupportL}
                        sliderData={[
                          { score: "0", label: translation.patient_adult_knee_other_symptoms_q9_a1 },
                          { score: "1", label: translation.patient_adult_knee_other_symptoms_q9_a2 },
                          { score: "2", label: translation.patient_adult_knee_other_symptoms_q9_a3 },

                        ]}
                      />
                      
                    </div>
                  ) : ""}
                </div>





                {/* <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={evlAdultKnee === "3" ?  translation.Kneecap_getting_out_of_place_or_feels_like_it_is_getting_out_of_place_R : translation.Kneecap_getting_out_of_place_or_feels_like_it_is_getting_out_of_place}
                        classes=""
                        value={kneecapR}
                        onChange={handleKneecapR}
                      />
                    </div>
                  ) : ""}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={evlAdultKnee === "3" ?  translation.Kneecap_getting_out_of_place_or_feels_like_it_is_getting_out_of_place_L : translation.Kneecap_getting_out_of_place_or_feels_like_it_is_getting_out_of_place}
                        classes=""
                        value={kneecapL}
                        onChange={handleKneecapL}
                      />
                    </div>
                  ) : ""}
                </div> */}
              </div>
              {doctorEnable === "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6">
                  </div>
                  <div className="col-md-6 col-sm-6 text-end">
                  <button
                      className="next_btn btn_fill me-3"
                      onClick={() => handleNext()}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                    <button
                      className="next_btn btn_fill"
                      onClick={() => handleSubmit()}
                    >
                      {translation.submit}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
                </div>
              )}

              {doctorEnable !== "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    <Link to={"/adult-knee-pain/" + rId+`?is_doctor_form=${doctordata}&edit=${viewicon}
`} className="assm_back_btn">
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}



            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AdultKneeSymptoms;
