import React, { useMemo, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../../images/back.png";
import nextWhiteIcon from "../../../../../images/next-white.png";
import nextIcon from "../../../../../images/next.png";
import LoadingSpinner from "../../../../custom/loader/LoadingSpinner";
import RangeSlider from "../../../../custom/range-slider/RangeSlider";
import KneeScoreRangeSlider from "../../../../custom/knee-score-range-slider/KneeScoreRangeSlider";
import Dropdown from "../../../../custom/dropdown/Dropdown";

import {
  updatePediaScores,
  updatePediaPainFieldScore,
  updatePediaPainData,
  updatePediaSymtomsScore,
  updatePediaFunctionScore,
  updatePediaQolScore,
  updatePediaSatisfactionScore,
  updatePediaTotalScores,
  updatePediaKneeNorwichInstaScore,
  updatePediaKneeFeedbackScores,
  updateAllData,
} from "../../../../../redux/actions/pediaKnee";
import { updateAdultKneeFeedback } from "../../../../../redux/actions/index";
import { useDispatch, useSelector } from "react-redux";
import useAxios from "../../../../../axiosinstance";
import useTransition from "../../../../customHooks/translations";
import PediakneeTab from "../../../tab-section/PediakneeTab";
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../../custom/toaster/CustomToaster";
import {
  LeftSide,
  LeftTrans,
  RightSide,
  RigthTrans,
} from "../../../../../../src/utilities/RightLeftFormat";
import NameCustom from "../../../../custom/name/NameCustom";
function PediaKneePain() {
  const translation = useTransition();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const axiosinstance = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  const evlPediaPain = useSelector((state) => state.pediaKnee.evlPediaPain);
  let lg = sessionStorage.getItem("language");
  const [painPastFourWeeksR, setPainPastFourWeeksR] = useState("0");
  const [painPastFourWeeksL, setPainPastFourWeeksL] = useState("0");
  const [painHurtTodayR, setPainHurtTodayR] = useState("0");
  const [painHurtTodayL, setPainHurtTodayL] = useState("0");
  const [twistingR, setTwistingR] = useState("0");
  const [twistingL, setTwistingL] = useState("0");
  const [straighteningR, setStraighteningR] = useState("0");
  const [straighteningL, setStraighteningL] = useState("0");
  const [fullyBendKneeR, setFullyBendKneeR] = useState("0");
  const [fullyBendKneeL, setFullyBendKneeL] = useState("0");
  const [walkingDownStairsR, setWalkingDownStairsR] = useState("0");
  const [walkingDownStairsL, setWalkingDownStairsL] = useState("0");
  const [walkingUpStairsR, setWalkingUpStairsR] = useState("0");
  const [walkingUpStairsL, setWalkingUpStairsL] = useState("0");
  const [sittingKneeBentR, setSittingKneeBentR] = useState("0");
  const [sittingKneeBentL, setSittingKneeBentL] = useState("0");
  const [sittingKneeBentUprightR, setSittingKneeBentUprightR] = useState("0");
  const [sittingKneeBentUprightL, setSittingKneeBentUprightL] = useState("0");
  const [updateTotal, setUpdateTotal] = useState(false);
  const [backD, setbackD] = useState({});
  const [chk, setChk] = useState(false);
  const [backDoc, setBackDoc] = useState("");
  let isdoctorForm = searchParams.get("is_doctor_form");
 let viewicon= searchParams.get("edit")
  localStorage.setItem("viewicon",viewicon)
  useEffect(() => {
    if (doctorEnable === "true") {
      let doctordata = localStorage.getItem("isdoctorForm")
      localStorage.setItem("isdoctorForm", doctordata)
    } else {
      localStorage.setItem("isdoctorForm", isdoctorForm)
    }
    localStorage.setItem("isdoctorForm", isdoctorForm)
  }, [isdoctorForm])
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=20`)
      .then((res) => {
        setBackDoc(res.data.data2[0].is_doctor_form);
        dispatch(updatePediaScores(res.data.data2[0].evlPediaPain));
        dispatch(updatePediaPainData(res.data.data2[0].painScore));
        dispatch(updatePediaSymtomsScore(res.data.data2[0].symtomsScore));
        dispatch(updatePediaFunctionScore(res.data.data2[0].functionScore));
        dispatch(updatePediaQolScore(res.data.data2[0].qolScore));
        dispatch(
          updatePediaSatisfactionScore(res.data.data2[0].satisfactionScore)
        );
        dispatch(
          updatePediaKneeNorwichInstaScore(res.data.data2[0].norwichInstability)
        );
        dispatch(updateAdultKneeFeedback(res.data.data2[0].doctorFeedback));
        dispatch(updatePediaTotalScores(res.data.data2[0].TotalScores));
        setChk(true);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };
  useEffect(() => {
    save();
  }, []);
  const backData = () => {
    axiosinstance.current
      .get(`extapp/forms/urgent_data?resource_id=${rId}`)
      .then((res) => {
        setbackD(res.data.data);
        sessionStorage.setItem("ptid", res.data.data.ak_pt);
      });
  };
  useEffect(() => {
    save();
    backData();
  }, []);
  const pediaScore = useSelector((state) => state.pediaKnee.painScore[0]);
  const json = useSelector((state) => state.pediaKnee);

  useEffect(() => {
    setPainPastFourWeeksR(pediaScore.painPastFourWeeksR);
    setPainPastFourWeeksL(pediaScore.painPastFourWeeksL);
    setPainHurtTodayR(pediaScore.painHurtTodayR);
    setPainHurtTodayL(pediaScore.painHurtTodayL);
    setTwistingR(pediaScore.twistingR);
    setTwistingL(pediaScore.twistingL);
    setStraighteningR(pediaScore.straighteningR);
    setStraighteningL(pediaScore.straighteningL);
    setFullyBendKneeR(pediaScore.fullyBendKneeR);
    setFullyBendKneeL(pediaScore.fullyBendKneeL);
    setWalkingDownStairsR(pediaScore.walkingDownStairsR);
    setWalkingDownStairsL(pediaScore.walkingDownStairsL);
    setWalkingUpStairsR(pediaScore.walkingUpStairsR);
    setWalkingUpStairsL(pediaScore.walkingUpStairsL);
    setSittingKneeBentR(pediaScore.sittingKneeBentR);
    setSittingKneeBentL(pediaScore.sittingKneeBentL);
    setSittingKneeBentUprightR(pediaScore.sittingKneeBentUprightR);
    setSittingKneeBentUprightL(pediaScore.sittingKneeBentUprightL);
  }, [pediaScore]);

  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true"?"1":"0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [pediaScore, updateTotal]);

  const sliderLabels = [
    { score: "0", label: translation.no_pain },
    { score: "1", label: translation.a_little_pain },
    { score: "2", label: translation.some_pain },
    { score: "3", label: translation.a_lot_of_pain },
    { score: "4", label: translation.extreme_pain },
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleEvolutedPediaPain = (e) => {
    dispatch(updatePediaScores(e));
  };

  const handlePainPastFourWeeksL = (e) => {
    setPainPastFourWeeksL(e);
    dispatch(
      updatePediaPainFieldScore({
        key: "painPastFourWeeksL",
        value: e,
      })
    );
  };
  const handlePainPastFourWeeksR = (e) => {
    setPainPastFourWeeksR(e);
    dispatch(
      updatePediaPainFieldScore({
        key: "painPastFourWeeksR",
        value: e,
      })
    );
  };

  const handlePainHurtTodayL = (e) => {
    setPainHurtTodayL(e);
    dispatch(
      updatePediaPainFieldScore({
        key: "painHurtTodayL",
        value: e,
      })
    );
  };
  const handlePainHurtTodayR = (e) => {
    setPainHurtTodayR(e);
    dispatch(
      updatePediaPainFieldScore({
        key: "painHurtTodayR",
        value: e,
      })
    );
  };

  const handleTwistingR = (e) => {
    setTwistingR(e);
    dispatch(
      updatePediaPainFieldScore({
        key: "twistingR",
        value: e,
      })
    );
  };
  const handleTwistingL = (e) => {
    setTwistingL(e);
    dispatch(
      updatePediaPainFieldScore({
        key: "twistingL",
        value: e,
      })
    );
  };

  const handleStraighteningR = (e) => {
    setStraighteningR(e);
    dispatch(
      updatePediaPainFieldScore({
        key: "straighteningR",
        value: e,
      })
    );
  };
  const handleStraighteningL = (e) => {
    setStraighteningL(e);
    dispatch(
      updatePediaPainFieldScore({
        key: "straighteningL",
        value: e,
      })
    );
  };

  const handleFullyBendKneeR = (e) => {
    setFullyBendKneeR(e);
    dispatch(
      updatePediaPainFieldScore({
        key: "fullyBendKneeR",
        value: e,
      })
    );
  };
  const handleFullyBendKneeL = (e) => {
    setFullyBendKneeL(e);
    dispatch(
      updatePediaPainFieldScore({
        key: "fullyBendKneeL",
        value: e,
      })
    );
  };

  const handleWalkingDownStairsR = (e) => {
    setWalkingDownStairsR(e);
    dispatch(
      updatePediaPainFieldScore({
        key: "walkingDownStairsR",
        value: e,
      })
    );
  };
  const handleWalkingDownStairsL = (e) => {
    setWalkingDownStairsL(e);
    dispatch(
      updatePediaPainFieldScore({
        key: "walkingDownStairsL",
        value: e,
      })
    );
  };

  const handleWalkingUpStairsL = (e) => {
    setWalkingUpStairsL(e);
    dispatch(
      updatePediaPainFieldScore({
        key: "walkingUpStairsL",
        value: e,
      })
    );
  };
  const handleWalkingUpStairsR = (e) => {
    setWalkingUpStairsR(e);
    dispatch(
      updatePediaPainFieldScore({
        key: "walkingUpStairsR",
        value: e,
      })
    );
  };

  const handleSittingKneeBentR = (e) => {
    setSittingKneeBentR(e);
    dispatch(
      updatePediaPainFieldScore({
        key: "sittingKneeBentR",
        value: e,
      })
    );
  };
  const handleSittingKneeBentL = (e) => {
    setSittingKneeBentL(e);
    dispatch(
      updatePediaPainFieldScore({
        key: "sittingKneeBentL",
        value: e,
      })
    );
  };

  const handleSittingKneeBentUprightR = (e) => {
    setSittingKneeBentUprightR(e);
    dispatch(
      updatePediaPainFieldScore({
        key: "sittingKneeBentUprightR",
        value: e,
      })
    );
  };
  const handleSittingKneeBentUprightL = (e) => {
    setSittingKneeBentUprightL(e);
    dispatch(
      updatePediaPainFieldScore({
        key: "sittingKneeBentUprightL",
        value: e,
      })
    );
  };

  const continueRedirect = (e) => {
    navigate("/pedia-knee-symptoms/" + rId+`?is_doctor_form=${isdoctorForm}`);
    dispatch(
      updatePediaPainData([
        {
          painPastFourWeeksR: painPastFourWeeksR,
          painPastFourWeeksL: painPastFourWeeksL,
          painHurtTodayR: painHurtTodayR,
          painHurtTodayL: painHurtTodayL,
          twistingR: twistingR,
          twistingL: twistingL,
          straighteningR: straighteningR,
          straighteningL: straighteningL,
          fullyBendKneeR: fullyBendKneeR,
          fullyBendKneeL: fullyBendKneeL,
          walkingDownStairsR: walkingDownStairsR,
          walkingDownStairsL: walkingDownStairsL,
          walkingUpStairsR: walkingUpStairsR,
          walkingUpStairsL: walkingUpStairsL,
          sittingKneeBentR: sittingKneeBentR,
          sittingKneeBentL: sittingKneeBentL,
          sittingKneeBentUprightR: sittingKneeBentUprightR,
          sittingKneeBentUprightL: sittingKneeBentUprightL,
        },
      ])
    );
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const handleBack = () => {
    window.location.href = process.env.REACT_APP_DOCTOR_URL;
  };
  let akpt = searchParams.get("akpt");

  const [msg, setMsg] = useState("");
  const [msgType, setmsgType] = useState("");
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update);
    setmsgType("success");
    setTimeout(() => {
      window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + akpt
      setMsg("");
      setmsgType("success");
    }, 2000);
  };
  const handleNext = () => {
    navigate(`/pedia-knee-symptoms/${rId}?d=true&akpt=${akpt}&is_doctor_form=${isdoctorForm}`);

  }
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="pain_screen mb_100">
          <div class="d-flex justify-content-end">
            <NameCustom />
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && <PediakneeTab rId={rId} />}
              <CustomToaster msg={msg} msgType={msgType} />

              <h4 className="ph_title f_40">
                {translation.patient_pedia_knee_pain}
              </h4>
              {/* <div className="row">
                <div className="mb_20">
                  <Dropdown
                    data={[
                      {
                        value: "1",
                        label: "Right",
                      },
                      {
                        value: "2",
                        label: "Left",
                      },
                      {
                        value: "3",
                        label: "Both",
                      },
                    ]}
                    label="Evaluated Pedia"
                    styleClass=""
                    value={evlPediaPain}
                    onChange={handleEvolutedPediaPain}
                  />
                </div>
              </div> */}
              <div className="row pt_20">
                <div className="col-md-12 col-sm-12">
                  <div className="row mb_30">
                    {evlPediaPain === "1" || evlPediaPain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_20" + RightSide()}>
                        <RangeSlider
                          id="painPastFourWeeksR"
                          PediakneeSlider="1"
                          min="0"
                          max="10"
                          step="1"
                          styleClass="w_90"
                          // type="text"
                          // leftTitle={translation.Never_hurt}
                          // rightTitle={translation.All_the_time}
                          label={
                            translation.patient_pedia_knee_pain_q1 +
                            RigthTrans(evlPediaPain, translation)
                          }
                          value={painPastFourWeeksR}
                          onChange={handlePainPastFourWeeksR}
                          sliderData={
                            painPastFourWeeksR === "0"
                              ? translation.patient_pedia_knee_pain_q1_a1
                              : painPastFourWeeksR === "1" ||
                                painPastFourWeeksR === "2" ||
                                painPastFourWeeksR === "3"
                                ? translation.patient_pedia_knee_pain_q1_a2
                                : painPastFourWeeksR === "4" ||
                                  painPastFourWeeksR === "5" ||
                                  painPastFourWeeksR === "6"
                                  ? translation.patient_pedia_knee_pain_q1_a3
                                  : painPastFourWeeksR === "7" ||
                                    painPastFourWeeksR === "8" ||
                                    painPastFourWeeksR === "9"
                                    ? translation.patient_pedia_knee_pain_q1_a4
                                    : painPastFourWeeksR === "10"
                                      ? translation.patient_pedia_knee_pain_q1_a5
                                      : ""
                          }
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {evlPediaPain === "2" || evlPediaPain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_20" + LeftSide()}>
                        <RangeSlider
                          id="painPastFourWeeksL"
                          PediakneeSlider="1"
                          min="0"
                          max="10"
                          step="1"
                          styleClass="w_90"
                          // type="text"
                          // leftTitle={translation.Never_hurt}
                          // rightTitle={translation.All_the_time}
                          label={
                            translation.patient_pedia_knee_pain_q1 +
                            LeftTrans(evlPediaPain, translation)
                          }
                          value={painPastFourWeeksL}
                          onChange={handlePainPastFourWeeksL}
                          sliderData={
                            painPastFourWeeksL === "0"
                              ? translation.patient_pedia_knee_pain_q1_a1
                              : painPastFourWeeksL === "1" ||
                                painPastFourWeeksL === "2" ||
                                painPastFourWeeksL === "3"
                                ? translation.patient_pedia_knee_pain_q1_a2
                                : painPastFourWeeksL === "4" ||
                                  painPastFourWeeksL === "5" ||
                                  painPastFourWeeksL === "6"
                                  ? translation.patient_pedia_knee_pain_q1_a3
                                  : painPastFourWeeksL === "7" ||
                                    painPastFourWeeksL === "8" ||
                                    painPastFourWeeksL === "9"
                                    ? translation.patient_pedia_knee_pain_q1_a4
                                    : painPastFourWeeksL === "10"
                                      ? translation.patient_pedia_knee_pain_q1_a5
                                      : ""
                          }
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="row mb_30">
                    {evlPediaPain === "1" || evlPediaPain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_20" + RightSide()}>
                        <RangeSlider
                          id="painHurtTodayR"
                          min="0"
                          max="10"
                          step="1"
                          type="both"
                          leftTitle={translation.patient_pedia_knee_pain_q2_a1}
                          rightTitle={translation.patient_pedia_knee_pain_q2_a2}
                          label={
                            translation.patient_pedia_knee_pain_q2 +
                            RigthTrans(evlPediaPain, translation)
                          }
                          value={painHurtTodayR}
                          onChange={handlePainHurtTodayR}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {evlPediaPain === "2" || evlPediaPain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_20" + LeftSide()}>
                        <RangeSlider
                          id="painHurtTodayL"
                          min="0"
                          max="10"
                          step="1"
                          type="both"
                          leftTitle={translation.patient_pedia_knee_pain_q2_a1}
                          rightTitle={translation.patient_pedia_knee_pain_q2_a2}
                          label={
                            translation.patient_pedia_knee_pain_q2 +
                            LeftTrans(evlPediaPain, translation)
                          }
                          value={painHurtTodayL}
                          onChange={handlePainHurtTodayL}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="function_formtop">
                <div className="row">
                  <div className="col-md-6 col-sm-6 function_head">
                    <label>
                      {translation.patient_pedia_knee_pain_instructions}
                    </label>
                    <span dir={lg === "ara" ? "rtl" : " "}>
                      (<strong>0</strong> ={" "}
                      {translation.patient_pedia_knee_pain_instructions_0},{" "}
                      <strong>1</strong> ={" "}
                      {translation.patient_pedia_knee_pain_instructions_1},
                      <strong> 2</strong> ={" "}
                      {translation.patient_pedia_knee_pain_instructions_2},{" "}
                      <strong>3</strong> ={" "}
                      {translation.patient_pedia_knee_pain_instructions_3},{" "}
                      <strong>4</strong> ={" "}
                      {translation.patient_pedia_knee_pain_instructions_4})
                    </span>
                  </div>
                  <div className="col-md-6 col-sm-6 function_note">
                    <p>{translation.patient_pedia_knee_pain_note}</p>
                  </div>
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <strong className="d-inline-block mb_15">
                        {/* {translation.Right_Knee}: */}
                      </strong>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_pain_q3 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        classes=""
                        value={twistingR}
                        // sliderData={sliderLabels}
                        onChange={handleTwistingR}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_pedia_knee_pain_q3_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_pedia_knee_pain_q3_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_pedia_knee_pain_q3_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_pedia_knee_pain_q3_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_pedia_knee_pain_q3_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <strong className="d-inline-block mb_15">
                        {/* {translation.Left_Knee}: */}
                      </strong>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_pain_q3 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        classes=""
                        value={twistingL}
                        // sliderData={sliderLabels}
                        onChange={handleTwistingL}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_pedia_knee_pain_q3_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_pedia_knee_pain_q3_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_pedia_knee_pain_q3_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_pedia_knee_pain_q3_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_pedia_knee_pain_q3_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_pain_q4 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        classes=""
                        value={straighteningR}
                        // sliderData={sliderLabels}
                        onChange={handleStraighteningR}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_pedia_knee_pain_q4_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_pedia_knee_pain_q4_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_pedia_knee_pain_q4_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_pedia_knee_pain_q4_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_pedia_knee_pain_q4_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_pain_q4 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        classes=""
                        value={straighteningL}
                        // sliderData={sliderLabels}
                        onChange={handleStraighteningL}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_pedia_knee_pain_q4_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_pedia_knee_pain_q4_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_pedia_knee_pain_q4_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_pedia_knee_pain_q4_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_pedia_knee_pain_q4_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_pain_q5 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        classes=""
                        value={fullyBendKneeR}
                        // sliderData={sliderLabels}
                        onChange={handleFullyBendKneeR}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_pedia_knee_pain_q5_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_pedia_knee_pain_q5_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_pedia_knee_pain_q5_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_pedia_knee_pain_q5_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_pedia_knee_pain_q5_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_pain_q5 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        classes=""
                        value={fullyBendKneeL}
                        // sliderData={sliderLabels}
                        onChange={handleFullyBendKneeL}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_pedia_knee_pain_q5_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_pedia_knee_pain_q5_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_pedia_knee_pain_q5_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_pedia_knee_pain_q5_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_pedia_knee_pain_q5_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_pain_q6 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        classes=""
                        value={walkingUpStairsR}
                        // sliderData={sliderLabels}
                        onChange={handleWalkingUpStairsR}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_pedia_knee_pain_q6_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_pedia_knee_pain_q6_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_pedia_knee_pain_q6_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_pedia_knee_pain_q6_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_pedia_knee_pain_q6_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_pain_q6 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        classes=""
                        value={walkingUpStairsL}
                        // sliderData={sliderLabels}
                        onChange={handleWalkingUpStairsL}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_pedia_knee_pain_q6_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_pedia_knee_pain_q6_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_pedia_knee_pain_q6_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_pedia_knee_pain_q6_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_pedia_knee_pain_q6_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_pain_q7 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        classes=""
                        value={walkingDownStairsR}
                        // sliderData={sliderLabels}
                        onChange={handleWalkingDownStairsR}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_pedia_knee_pain_q7_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_pedia_knee_pain_q7_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_pedia_knee_pain_q7_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_pedia_knee_pain_q7_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_pedia_knee_pain_q7_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_pain_q7 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        classes=""
                        value={walkingDownStairsL}
                        // sliderData={sliderLabels}
                        onChange={handleWalkingDownStairsL}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_pedia_knee_pain_q7_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_pedia_knee_pain_q7_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_pedia_knee_pain_q7_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_pedia_knee_pain_q7_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_pedia_knee_pain_q7_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_pain_q8 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        // sliderData={sliderLabels}

                        classes=""
                        value={sittingKneeBentR}
                        onChange={handleSittingKneeBentR}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_pedia_knee_pain_q8_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_pedia_knee_pain_q8_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_pedia_knee_pain_q8_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_pedia_knee_pain_q8_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_pedia_knee_pain_q8_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_pain_q8 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        // sliderData={sliderLabels}

                        classes=""
                        value={sittingKneeBentL}
                        onChange={handleSittingKneeBentL}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_pedia_knee_pain_q8_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_pedia_knee_pain_q8_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_pedia_knee_pain_q8_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_pedia_knee_pain_q8_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_pedia_knee_pain_q8_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_pain_q9 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        classes=""
                        value={sittingKneeBentUprightR}
                        // sliderData={sliderLabels}
                        onChange={handleSittingKneeBentUprightR}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_pedia_knee_pain_q9_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_pedia_knee_pain_q9_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_pedia_knee_pain_q9_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_pedia_knee_pain_q9_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_pedia_knee_pain_q9_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_pain_q9 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        classes=""
                        value={sittingKneeBentUprightL}
                        // sliderData={sliderLabels}
                        onChange={handleSittingKneeBentUprightL}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_pedia_knee_pain_q9_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_pedia_knee_pain_q9_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_pedia_knee_pain_q9_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_pedia_knee_pain_q9_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_pedia_knee_pain_q9_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {doctorEnable === "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6"></div>
                  <div className="col-md-6 col-sm-6 text-end">
                  <button
                      className="next_btn btn_fill me-3"
                      onClick={() => handleNext()}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                    <button
                      className="next_btn btn_fill"
                      onClick={() => handleSubmit()}
                    >
                      {translation.submit}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
                </div>
              )}

              {doctorEnable !== "true" && viewicon!=="true"  && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    {backDoc != 1 ? <Link
                      to={`/patient-personal-data/${window.btoa(
                        backD.ak_pt
                      )}/${window.btoa(backD.type)}?r=${window.btoa(rId)}`}
                      className="assm_back_btn"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link> : <Link
                      to={`/personal-data/${window.btoa(
                        backD.ak_pt
                      )}/${window.btoa(backD.ak_dc)}/${window.btoa(
                        backD.org_id
                      )}/${window.btoa(backD.type)}?is_doctor_form=${isdoctorForm}&doctorvalue=0`}
                      className="assm_back_btn"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>}


                    {/* <span onClick={()=>Back()} className="assm_back_btn c_pointer">
                    <img src={backIcon} alt="" />
                    <span>{translation.back_button}</span>
                  </span> */}
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}
              { viewicon==="true"  &&(
                <div className="row mb_30 button_box">
                <div className="col-md-12 col-sm-12 col-12 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                  </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PediaKneePain;
