import React from 'react'
import { Link, useNavigate, useParams } from "react-router-dom";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import FullDropdown from "../../../custom/dropdown/FullDropdown";
import KneeScoreRangeSlider from "../../../custom/knee-score-range-slider/KneeScoreRangeSlider";
import { useDispatch, useSelector } from "react-redux";
// import RangeSlider from "../../../custom/range-slider/RangeSlider";
import RangeSlider from "../../../custom/range-slider/RangeSlider";
import { useState, useEffect } from 'react';
import {
  updateElbowQOLFieldScore,
  updateElbowQOLData,
  updateElbowSymptomsData,
  updateElbowSatisficationData,
  updateElbowScores,
  updateElbowPainData,
  updateElbowFunctionData,
  updateElbowFeedbackScores,
  updateAllData

} from "../../../../redux/actions/elbowAction";
import useAxios from "../../../../axiosinstance";
import useTranslation from "../../../customHooks/translations"
import ElbowTab from '../../tab-section/ElbowTab';
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../custom/toaster/CustomToaster"
import { LeftSide, LeftTrans, RightSide, RigthTrans } from '../../../../utilities/RightLeftFormat';
import NameCustom from '../../../custom/name/NameCustom';


export default function ElbowQualityOfLife() {
  const translation = useTranslation()
  let lg = sessionStorage.getItem("language");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const axiosinstance = useAxios();
  const evlElbowPain = useSelector((state) => state.elbowScore.elbowPain);
  const [elbowDuringL, setElbowDuringL] = useState("0");
  const [elbowDuringR, setElbowDuringR] = useState("0");
  const [problemR, setProblemR] = useState("0");

  const [washingR, setWashingR] = useState("0");

  const [activitiesR, setActivitiesR] = useState("0");

  const [discomfortR, setDiscomfortR] = useState("0");

  const [anxietyR, setAnxietyR] = useState("0");

  const [percentage, setPercentage] = useState("0")

  const [elbowlifeR, setelbowlifeR] = useState("0");
  const [elbowlifeL, setelbowlifeL] = useState("0")
  const [initialAssesment, setInitialAssesment] = useState(0)
  const [chk, setChk] = useState(false);
  const [updateTotal, setUpdateTotal] = useState(false);
  const qol = useSelector((state) => state.elbowScore.qolScore[0]);
  const json = useSelector((state) => state.elbowScore);
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=22`)
      .then((res) => {
        setInitialAssesment(res.data.data2[0].initial_assessment)
        dispatch(updateElbowPainData(res.data.data2[0].painScore));
        dispatch(updateElbowSymptomsData(res.data.data2[0].symptomsScore));
        dispatch(updateElbowScores(res.data.data2[0].elbowPain));
        dispatch(updateElbowFunctionData(res.data.data2[0].functionScore));
        dispatch(updateElbowQOLData(res.data.data2[0].qolScore));
        dispatch(
          updateElbowSatisficationData(res.data.data2[0].satisfactionScore)
        );
        dispatch(updateElbowFeedbackScores(res.data.data2[0].doctorFeedback));
        setChk(true);
      }).catch((err) => {

        navigate('/un-authorized-access');

      });
  };

  useEffect(() => {
    save();
  }, []);

  useEffect(() => {
    setElbowDuringL(qol.elbowDuringL)
    setElbowDuringR(qol.elbowDuringR)
    setProblemR(qol.problemR)

    setWashingR(qol.washingR)

    setActivitiesR(qol.activitiesR)

    setDiscomfortR(qol.discomfortR)

    setAnxietyR(qol.anxietyR)

    setPercentage(qol.percentage)

    setelbowlifeR(qol.elbowlifeR)
    setelbowlifeL(qol.elbowlifeL)


  }, [qol]);

  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true" ? "1" : "0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [qol, updateTotal]);

  const handleElbowDuringL = (e) => {
    setElbowDuringL(e)
    dispatch(updateElbowQOLFieldScore({
      "key": 'elbowDuringL',
      "value": e
    }))
  }

  const handleElbowDuringR = (e) => {
    setElbowDuringR(e);
    dispatch(updateElbowQOLFieldScore({
      "key": 'elbowDuringR',
      "value": e
    }))
  }

  const handleProblemR = (e) => {
    setProblemR(e);
    dispatch(updateElbowQOLFieldScore({
      "key": 'problemR',
      "value": e
    }))
  }



  const handleWashingR = (e) => {
    setWashingR(e);
    dispatch(updateElbowQOLFieldScore({
      "key": 'washingR',
      "value": e
    }))
  }


  const handleActivitiesR = (e) => {
    setActivitiesR(e);
    dispatch(updateElbowQOLFieldScore({
      "key": 'activitiesR',
      "value": e
    }))
  }



  const handleDiscomfortR = (e) => {
    setDiscomfortR(e);
    dispatch(updateElbowQOLFieldScore({
      "key": 'discomfortR',
      "value": e
    }))
  }


  const handleAnxietyR = (e) => {
    setAnxietyR(e);
    dispatch(updateElbowQOLFieldScore({
      "key": 'anxietyR',
      "value": e
    }))
  }



  const handlePercentage = (e) => {
    setPercentage(e);
    dispatch(updateElbowQOLFieldScore({
      "key": 'percentage',
      "value": e
    }))
  }

  const handleElbowLifeR = (e) => {
    setelbowlifeR(e);
    dispatch(updateElbowQOLFieldScore({
      "key": 'elbowlifeR',
      "value": e
    }))
  }
  const handleElbowLifeL = (e) => {
    setelbowlifeL(e);
    dispatch(updateElbowQOLFieldScore({
      "key": 'elbowlifeL',
      "value": e
    }))
  }
  const continueRedirect = (e) => {
    dispatch(updateElbowQOLData(
      [{
        elbowlifeR,
        elbowlifeL,
        elbowDuringL,
        elbowDuringR,
        problemR,

        washingR,

        activitiesR,

        discomfortR,

        anxietyR,

        percentage,

      }]
    ))
      navigate("/elbow-satisfaction/" + rId);
  };


  let akpt = searchParams.get("akpt");
  const [msg, setMsg] = useState('');
  const [msgType, setmsgType] = useState('');
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update)
    setmsgType('success')
    setTimeout(() => {
      window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + akpt
      setMsg('')
      setmsgType('success')
    }, 2000);
  };
  const handleNext = () => {
    navigate(`/elbow-satisfaction/${rId}?d=true&akpt=${akpt}`);

  }

  return (
    <div className="pain_screen mb_100">
      <div class="d-flex justify-content-end">
        <NameCustom />
      </div>
      <div className="panel">
        <div className="panel-body p_25">
          {doctorEnable === "true" && <ElbowTab rId={rId} />}
          <CustomToaster msg={msg} msgType={msgType} />

          <h4 className="ph_title f_40">{translation.patient_elbow_quality_of_life}</h4>
          <strong>{translation.patient_elbow_quality_of_life_instruction}</strong>

          <div className="row mt-3">
            {evlElbowPain === "1" || evlElbowPain === "3" ? (
              <div className={"col-md-6 col-sm-6 mb_20" + RightSide()}>
                <KneeScoreRangeSlider
                  label={translation.patient_elbow_quality_of_life_q1 + RigthTrans(evlElbowPain, translation)}
                  styleClass=""
                  value={elbowlifeR}
                  onChange={handleElbowLifeR}
                  sliderData={[
                    { score: "0", label: translation.patient_elbow_quality_of_life_q1_a1 },
                    { score: "1", label: translation.patient_elbow_quality_of_life_q1_a2 },
                    { score: "2", label: translation.patient_elbow_quality_of_life_q1_a3 },
                    { score: "3", label: translation.patient_elbow_quality_of_life_q1_a4 },
                    { score: "4", label: translation.patient_elbow_quality_of_life_q1_a5 },
                  ]}
                />
              </div>
            ) : (
              " "
            )}
            {evlElbowPain === "2" || evlElbowPain === "3" ? (
              <div className={"col-md-6 col-sm-6 mb_20" + LeftSide()}>
                <KneeScoreRangeSlider
                  label={translation.patient_elbow_quality_of_life_q1 + LeftTrans(evlElbowPain, translation)}
                  styleClass=""
                  value={elbowlifeL}
                  onChange={handleElbowLifeL}
                  sliderData={[
                    { score: "0", label: translation.patient_elbow_quality_of_life_q1_a1 },
                    { score: "1", label: translation.patient_elbow_quality_of_life_q1_a2 },
                    { score: "2", label: translation.patient_elbow_quality_of_life_q1_a3 },
                    { score: "3", label: translation.patient_elbow_quality_of_life_q1_a4 },
                    { score: "4", label: translation.patient_elbow_quality_of_life_q1_a5 },
                  ]}
                />
              </div>
            ) : (
              " "
            )}
          </div>

          <div className="row">
            {evlElbowPain === "1" || evlElbowPain === "3" ? (
              <div className={"col-md-6 col-sm-6 mb_20" + RightSide()}>
                <KneeScoreRangeSlider
                  label={translation.patient_elbow_quality_of_life_q2 + RigthTrans(evlElbowPain, translation)}
                  styleClass=""
                  value={elbowDuringR}
                  onChange={handleElbowDuringR}
                  sliderData={[
                    { score: "0", label: translation.patient_elbow_quality_of_life_q2_a1 },
                    { score: "1", label: translation.patient_elbow_quality_of_life_q2_a2 },
                    { score: "2", label: translation.patient_elbow_quality_of_life_q2_a3 },
                    { score: "3", label: translation.patient_elbow_quality_of_life_q2_a4 },
                    { score: "4", label: translation.patient_elbow_quality_of_life_q2_a5 },
                  ]}
                />
              </div>
            ) : (
              " "
            )}
            {evlElbowPain === "2" || evlElbowPain === "3" ? (
              <div className={"col-md-6 col-sm-6 mb_20" + LeftSide()}>
                <KneeScoreRangeSlider
                  label={translation.patient_elbow_quality_of_life_q2 + LeftTrans(evlElbowPain, translation)}
                  styleClass=""
                  value={elbowDuringL}
                  onChange={handleElbowDuringL}
                  sliderData={[
                    { score: "0", label: translation.patient_elbow_quality_of_life_q2_a1 },
                    { score: "1", label: translation.patient_elbow_quality_of_life_q2_a2 },
                    { score: "2", label: translation.patient_elbow_quality_of_life_q2_a3 },
                    { score: "3", label: translation.patient_elbow_quality_of_life_q2_a4 },
                    { score: "4", label: translation.patient_elbow_quality_of_life_q2_a5 },
                  ]}
                />

              </div>
            ) : (
              " "
            )}
          </div>



          <div className="function_formtop">
            <div className="row">
              <div className="col-md-6 col-sm-6 function_head">
                <label>
                  {translation.patient_elbow_quality_of_life_instruction1}
                </label> <br />
                <span dir={lg === "ara" ? "rtl" : " "}>
                  (<strong>0</strong> = {translation.patient_elbow_quality_of_life_instruction1_0}, <strong>1</strong> = {translation.patient_elbow_quality_of_life_instruction1_1},{""} <strong> 2</strong> = {translation.patient_elbow_quality_of_life_instruction1_2}, <strong>3</strong> = {translation.patient_elbow_quality_of_life_instruction1_3},<strong> 4</strong> = {translation.patient_elbow_quality_of_life_instruction1_4})
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-6 mb_10">
                <KneeScoreRangeSlider
                  label={translation.patient_elbow_quality_of_life_q3}
                  classes=""
                  value={problemR}
                  onChange={handleProblemR}
                  sliderData={[
                    { score: "0", label: translation.patient_elbow_quality_of_life_q3_a1 },
                    { score: "1", label: translation.patient_elbow_quality_of_life_q3_a2 },
                    { score: "2", label: translation.patient_elbow_quality_of_life_q3_a3 },
                    { score: "3", label: translation.patient_elbow_quality_of_life_q3_a4 },
                    { score: "4", label: translation.patient_elbow_quality_of_life_q3_a5 },
                  ]}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-6 mb_10">
                <KneeScoreRangeSlider
                  label={translation.patient_elbow_quality_of_life_q4}
                  classes=""
                  value={washingR}
                  onChange={handleWashingR}
                  sliderData={[
                    { score: "0", label: translation.patient_elbow_quality_of_life_q4_a1 },
                    { score: "1", label: translation.patient_elbow_quality_of_life_q4_a2 },
                    { score: "2", label: translation.patient_elbow_quality_of_life_q4_a3 },
                    { score: "3", label: translation.patient_elbow_quality_of_life_q4_a4 },
                    { score: "4", label: translation.patient_elbow_quality_of_life_q4_a5 },
                  ]}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-6 mb_10">
                <KneeScoreRangeSlider
                  label={translation.patient_elbow_quality_of_life_q5}
                  classes=""
                  value={activitiesR}
                  onChange={handleActivitiesR}
                  sliderData={[
                    { score: "0", label: translation.patient_elbow_quality_of_life_q5_a1 },
                    { score: "1", label: translation.patient_elbow_quality_of_life_q5_a2 },
                    { score: "2", label: translation.patient_elbow_quality_of_life_q5_a3 },
                    { score: "3", label: translation.patient_elbow_quality_of_life_q5_a4 },
                    { score: "4", label: translation.patient_elbow_quality_of_life_q5_a5 },
                  ]}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-6 mb_10">
                <KneeScoreRangeSlider
                  label={translation.patient_elbow_quality_of_life_q6}
                  classes=""
                  value={discomfortR}
                  onChange={handleDiscomfortR}
                  sliderData={[
                    { score: "0", label: translation.patient_elbow_quality_of_life_q6_a1 },
                    { score: "1", label: translation.patient_elbow_quality_of_life_q6_a2 },
                    { score: "2", label: translation.patient_elbow_quality_of_life_q6_a3 },
                    { score: "3", label: translation.patient_elbow_quality_of_life_q6_a4 },
                    { score: "4", label: translation.patient_elbow_quality_of_life_q6_a5 },
                  ]}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-6 mb_10">
                <KneeScoreRangeSlider
                  label={translation.patient_elbow_quality_of_life_q7}
                  classes=""
                  value={anxietyR}
                  onChange={handleAnxietyR}
                  sliderData={[
                    { score: "0", label: translation.patient_elbow_quality_of_life_q7_a1 },
                    { score: "1", label: translation.patient_elbow_quality_of_life_q7_a2 },
                    { score: "2", label: translation.patient_elbow_quality_of_life_q7_a3 },
                    { score: "3", label: translation.patient_elbow_quality_of_life_q7_a4 },
                    { score: "4", label: translation.patient_elbow_quality_of_life_q7_a5 },
                  ]}
                />
              </div>
            </div>
          </div>




          <div className="row">
            <div className="mb_20">
              <RangeSlider
                colorcode="1"
                id="hipPer"
                min="0"
                max="100"
                step="1"
                type="text"
                leftTitle={translation.patient_elbow_quality_of_life_q8_a1}
                rightTitle={translation.patient_elbow_quality_of_life_q8_a2}
                label={translation.patient_elbow_quality_of_life_q8}
                span1={translation.patient_elbow_quality_of_life_q8_instruction}
                value={percentage}
                onChange={handlePercentage}
              />
            </div>

          </div>

          {doctorEnable === "true" && (
            <div className="row mb_30 button_box">
              <div className="col-md-6 col-sm-6">
                {/* <Link to="/personal-data" className="assm_back_btn">
                       <img src={backIcon} alt="" />
                       <span>{translation.back_button}</span>
                     </Link> */}
              </div>
              <div className="col-md-6 col-sm-6 text-end">
                <button
                  className="next_btn btn_fill me-3"
                  onClick={() => handleNext()}
                >
                  {translation.next_button}
                  <span>
                    <img src={nextIcon} alt="" />
                    {/* <img src={nextWhiteIcon} alt="" /> */}
                  </span>
                </button>
                <button
                  className="next_btn btn_fill"
                  onClick={() => handleSubmit()}
                >
                  {translation.submit}
                  <span>
                    <img src={nextIcon} alt="" />
                    {/* <img src={nextWhiteIcon} alt="" /> */}
                  </span>
                </button>
              </div>
            </div>
          )}

          {doctorEnable !== "true" && (
            <div className="row mb_30 button_box">
              <div className="col-md-6 col-sm-6 col-6">
                <Link to={"/elbow-symptoms/" + rId} className="assm_back_btn">
                  <img src={backIcon} alt="" />
                  <span>{translation.back_button}</span>
                </Link>
              </div>
              <div className="col-md-6 col-sm-6 col-6 text-end">
                <button
                  className="next_btn btn_fill"
                  onClick={continueRedirect}
                >
                  {translation.next_button}
                  <span>
                    <img src={nextIcon} alt="" />
                    <img src={nextWhiteIcon} alt="" />
                  </span>
                </button>
              </div>
            </div>

          )}

        </div>
      </div>
    </div>

  )
}
