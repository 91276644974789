import React, { useMemo, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../../images/back.png";
import nextWhiteIcon from "../../../../../images/next-white.png";
import nextIcon from "../../../../../images/next.png";
import LoadingSpinner from "../../../../custom/loader/LoadingSpinner";
import RangeSlider from "../../../../custom/range-slider/RangeSlider";
import Dropdown from "../../../../custom/dropdown/Dropdown";
import KneeScoreRangeSlider from "../../../../custom/knee-score-range-slider/KneeScoreRangeSlider";
import { useDispatch, useSelector } from "react-redux";
import {
  updatePediaScores,
  updatePediaQolFieldScore,
  updatePediaQolScore,
  updatePediaPainData,
  updatePediaSymtomsScore,
  updatePediaFunctionScore,
  updatePediaSatisfactionScore,
  updatePediaTotalScores,
  updatePediaKneeNorwichInstaScore,
  updatePediaKneeFeedbackScores,
  updateAllData

} from "../../../../../redux/actions/pediaKnee";
// import "./AdultKneeQualityOfLife.css";
// import Button from "../../custom/button/Button";
import useAxios from "../../../../../axiosinstance";
import useTransition from '../../../../customHooks/translations';
import PediakneeTab from '../../../tab-section/PediakneeTab';
import { useSearchParams } from "react-router-dom";
import CustomToaster from '../../../../custom/toaster/CustomToaster'
import {
  updateAdultKneeFeedback

} from "../../../../../redux/actions/index";
import { LeftSide, LeftTrans, RightSide, RigthTrans } from "../../../../../utilities/RightLeftFormat";
import NameCustom from "../../../../custom/name/NameCustom";

function PediaKneeQualityOfLife() {
  const translation = useTransition();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const axiosinstance = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  const [howOftenR, setHowOftenR] = useState("4");
  const [howOftenL, setHowOftenL] = useState("4");
  const [lifestyleR, setLifestyleR] = useState("4");
  const [lifestyleL, setLifestyleL] = useState("4");
  const [HowMuchInjuredR, setHowMuchInjuredR] = useState("4");
  const [HowMuchInjuredL, setHowMuchInjuredL] = useState("4");
  const [difficultyR, setDifficultyR] = useState("4");
  const [difficultyL, setDifficultyL] = useState("4");
  const evlPediaPain = useSelector((state) => state.pediaKnee.evlPediaPain);
  const navigate = useNavigate();
  const [updateTotal, setUpdateTotal] = useState(false);
  const [initialAssesment, setInitialAssesment] = useState(0)
  const [chk, setChk] = useState(false);
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=20`)
      .then((res) => {
        setInitialAssesment(res.data.data2[0].initial_assessment
        )
        dispatch(updatePediaScores(res.data.data2[0].evlPediaPain));
        dispatch(updatePediaPainData(res.data.data2[0].painScore));
        dispatch(updatePediaSymtomsScore(res.data.data2[0].symtomsScore));
        dispatch(updatePediaFunctionScore(res.data.data2[0].functionScore));
        dispatch(updatePediaQolScore(res.data.data2[0].qolScore));
        dispatch(
          updatePediaSatisfactionScore(res.data.data2[0].satisfactionScore)
        );
        dispatch(
          updatePediaKneeNorwichInstaScore(res.data.data2[0].norwichInstability)
        );
        dispatch(
          updateAdultKneeFeedback(res.data.data2[0].doctorFeedback)
        );
        // dispatch(updatePediaTotalScores(res.data.data2[0].TotalScores));
        setChk(true);
      }).catch((err) => {

        navigate('/un-authorized-access');

      })
  };
  useEffect(() => {
    save();
  }, []);

  const qolScore = useSelector((state) => state.pediaKnee.qolScore[0]);
  const pattelar = useSelector(state => state.pediaKnee.functionScore[0].patellarInstability);
  const pattelarL = useSelector(state => state.pediaKnee.functionScore[0].patellarInstabilityLeft);
  const json = useSelector((state) => state.pediaKnee);

  useEffect(() => {
    setHowOftenR(qolScore.howOftenR);
    setHowOftenL(qolScore.howOftenL);
    setLifestyleR(qolScore.lifestyleR);
    setLifestyleL(qolScore.lifestyleL);
    setHowMuchInjuredR(qolScore.HowMuchInjuredR);
    setHowMuchInjuredL(qolScore.HowMuchInjuredL);
    setDifficultyR(qolScore.difficultyR);
    setDifficultyL(qolScore.difficultyL);
  }, [qolScore]);

  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true" ? "1" : "0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [qolScore, updateTotal]);

  const handleHowOftenR = (e) => {
    dispatch(
      updatePediaQolFieldScore({
        key: "howOftenR",
        value: e,
      })
    );
    setHowOftenR(e);
  };
  const handleHowOftenL = (e) => {
    dispatch(
      updatePediaQolFieldScore({
        key: "howOftenL",
        value: e,
      })
    );
    setHowOftenL(e);
  };
  const handleLifestyleR = (e) => {
    dispatch(
      updatePediaQolFieldScore({
        key: "lifestyleR",
        value: e,
      })
    );
    setLifestyleR(e);
  };
  const handleLifestyleL = (e) => {
    dispatch(
      updatePediaQolFieldScore({
        key: "lifestyleL",
        value: e,
      })
    );
    setLifestyleL(e);
  };

  const handleHowMuchInjuredR = (e) => {
    dispatch(
      updatePediaQolFieldScore({
        key: "HowMuchInjuredR",
        value: e,
      })
    );
    setHowMuchInjuredR(e);
  };
  const handleHowMuchInjuredL = (e) => {
    dispatch(
      updatePediaQolFieldScore({
        key: "HowMuchInjuredL",
        value: e,
      })
    );
    setHowMuchInjuredL(e);
  };

  const handleDifficultyR = (e) => {
    dispatch(
      updatePediaQolFieldScore({
        key: "difficultyR",
        value: e,
      })
    );
    setDifficultyR(e);
  };
  const handleDifficultyL = (e) => {
    dispatch(
      updatePediaQolFieldScore({
        key: "difficultyL",
        value: e,
      })
    );
    setDifficultyL(e);
  };

  const handleEvolutedPediaPain = (e) => {
    dispatch(updatePediaScores(e));
  };

  const dispatch = useDispatch();

  const continueRedirect = (e) => {
    // navigate("/pedia-knee-satisfaction/" + rId);
    dispatch(
      updatePediaQolScore([
        {
          howOftenR: howOftenR,
          howOftenL: howOftenL,
          lifestyleR: lifestyleR,
          lifestyleL: lifestyleL,
          HowMuchInjuredR: HowMuchInjuredR,
          HowMuchInjuredL: HowMuchInjuredL,
          difficultyR: difficultyR,
          difficultyL: difficultyL,
        },
      ])
    );
   
      navigate("/pedia-knee-satisfaction/" + rId);
    
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  let akpt = searchParams.get("akpt");

  const [msg, setMsg] = useState('');
  const [msgType, setmsgType] = useState('');
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update)
    setmsgType('success')
    setTimeout(() => {
      window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + akpt

      setMsg('')
      setmsgType('success')
    }, 2000);
  };
  const handleNext = () => {
    navigate(`/pedia-knee-satisfaction/${rId}?d=true&akpt=${akpt}`);

  }
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="pain_screen mb_100">
          <div class="d-flex justify-content-end">
            <NameCustom />
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && <PediakneeTab rId={rId} />}
              <CustomToaster msg={msg} msgType={msgType} />

              <h4 className="ph_title f_40">{translation.patient_pedia_knee_quality_of_life}</h4>


              <div className="function_formtop">
                <strong>
                  {translation.patient_pedia_knee_quality_of_life_instruction}
                </strong>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_pedia_knee_quality_of_life_q1 + RigthTrans(evlPediaPain, translation)}
                        styleClass=""
                        value={howOftenR}
                        placeholder={translation.none}
                        onChange={handleHowOftenR}
                        sliderData={[
                          { score: "0", label: translation.patient_pedia_knee_quality_of_life_q1_a1 },
                          { score: "1", label: translation.patient_pedia_knee_quality_of_life_q1_a2 },
                          { score: "2", label: translation.patient_pedia_knee_quality_of_life_q1_a3 },
                          { score: "3", label: translation.patient_pedia_knee_quality_of_life_q1_a4 },
                          { score: "4", label: translation.patient_pedia_knee_quality_of_life_q1_a5 },
                        ]}
                      />
                    </div>
                  ) : null}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_pedia_knee_quality_of_life_q1 + LeftTrans(evlPediaPain, translation)}
                        styleClass=""
                        value={howOftenL}
                        placeholder={translation.none}
                        onChange={handleHowOftenL}
                        sliderData={[
                          { score: "0", label: translation.patient_pedia_knee_quality_of_life_q1_a1 },
                          { score: "1", label: translation.patient_pedia_knee_quality_of_life_q1_a2 },
                          { score: "2", label: translation.patient_pedia_knee_quality_of_life_q1_a3 },
                          { score: "3", label: translation.patient_pedia_knee_quality_of_life_q1_a4 },
                          { score: "4", label: translation.patient_pedia_knee_quality_of_life_q1_a5 },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_pedia_knee_problem_q2 + RigthTrans(evlPediaPain, translation)}
                        styleClass=""
                        value={lifestyleR}
                        placeholder={translation.none}
                        onChange={handleLifestyleR}
                        sliderData={[
                          { score: "0", label: translation.patient_pedia_knee_problem_q2_a1 },
                          { score: "1", label: translation.patient_pedia_knee_problem_q2_a2 },
                          { score: "2", label: translation.patient_pedia_knee_problem_q2_a3 },
                          { score: "3", label: translation.patient_pedia_knee_problem_q2_a4 },
                          { score: "4", label: translation.patient_pedia_knee_problem_q2_a5 },
                        ]}
                      />

                    </div>
                  ) : null}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_pedia_knee_problem_q2 + LeftTrans(evlPediaPain, translation)}
                        styleClass=""
                        value={lifestyleL}
                        placeholder={translation.none}
                        onChange={handleLifestyleL}
                        sliderData={[
                          { score: "0", label: translation.patient_pedia_knee_problem_q2_a1 },
                          { score: "1", label: translation.patient_pedia_knee_problem_q2_a2 },
                          { score: "2", label: translation.patient_pedia_knee_problem_q2_a3 },
                          { score: "3", label: translation.patient_pedia_knee_problem_q2_a4 },
                          { score: "4", label: translation.patient_pedia_knee_problem_q2_a5 },
                        ]}
                      />

                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_pedia_knee_problem_q3 + RigthTrans(evlPediaPain, translation)}
                        styleClass=""
                        value={HowMuchInjuredR}
                        placeholder={translation.none}
                        onChange={handleHowMuchInjuredR}
                        sliderData={[
                          { score: "0", label: translation.patient_pedia_knee_problem_q3_a1 },
                          { score: "1", label: translation.patient_pedia_knee_problem_q3_a2 },
                          { score: "2", label: translation.patient_pedia_knee_problem_q3_a3 },
                          { score: "3", label: translation.patient_pedia_knee_problem_q3_a4 },
                          { score: "4", label: translation.patient_pedia_knee_problem_q3_a5 },
                        ]}
                      />

                    </div>
                  ) : null}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_pedia_knee_problem_q3 + LeftTrans(evlPediaPain, translation)}
                        styleClass=""
                        value={HowMuchInjuredL}
                        placeholder={translation.none}
                        onChange={handleHowMuchInjuredL}
                        sliderData={[
                          { score: "0", label: translation.patient_pedia_knee_problem_q3_a1 },
                          { score: "1", label: translation.patient_pedia_knee_problem_q3_a2 },
                          { score: "2", label: translation.patient_pedia_knee_problem_q3_a3 },
                          { score: "3", label: translation.patient_pedia_knee_problem_q3_a4 },
                          { score: "4", label: translation.patient_pedia_knee_problem_q3_a5 },
                        ]}
                      />

                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_pedia_knee_problem_q4 + RigthTrans(evlPediaPain, translation)}
                        styleClass=""
                        value={difficultyR}
                        placeholder={translation.none}
                        onChange={handleDifficultyR}
                        sliderData={[
                          { score: "0", label: translation.patient_pedia_knee_problem_q4_a1 },
                          { score: "1", label: translation.patient_pedia_knee_problem_q4_a2 },
                          { score: "2", label: translation.patient_pedia_knee_problem_q4_a3 },
                          { score: "3", label: translation.patient_pedia_knee_problem_q4_a4 },
                          { score: "4", label: translation.patient_pedia_knee_problem_q4_a5 },
                        ]}
                      />

                    </div>
                  ) : null}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_pedia_knee_problem_q4 + LeftTrans(evlPediaPain, translation)}
                        styleClass=""
                        value={difficultyL}
                        placeholder={translation.none}
                        onChange={handleDifficultyL}
                        sliderData={[
                          { score: "0", label: translation.patient_pedia_knee_problem_q4_a1 },
                          { score: "1", label: translation.patient_pedia_knee_problem_q4_a2 },
                          { score: "2", label: translation.patient_pedia_knee_problem_q4_a3 },
                          { score: "3", label: translation.patient_pedia_knee_problem_q4_a4 },
                          { score: "4", label: translation.patient_pedia_knee_problem_q4_a5 },
                        ]}
                      />

                    </div>
                  ) : null}
                </div>
              </div>
              {doctorEnable === "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6">
                  </div>
                  <div className="col-md-6 col-sm-6 text-end">
                    <button
                      className="next_btn btn_fill me-3"
                      onClick={() => handleNext()}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                    <button
                      className="next_btn btn_fill"
                      onClick={() => handleSubmit()}
                    >
                      {translation.submit}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
                </div>
              )}

              {doctorEnable !== "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    {
                      pattelar === "1" ?
                        <Link
                          to={"/patellar-instability-pedia/" + rId}
                          className="assm_back_btn"
                        >
                          <img src={backIcon} alt="" />
                          <span>{translation.back_button}</span>
                        </Link> : pattelarL === "1" ? <Link
                          to={"/patellar-instability-pedia/" + rId}
                          className="assm_back_btn"
                        >
                          <img src={backIcon} alt="" />
                          <span>{translation.back_button}</span>
                        </Link> : <Link
                          to={"/pedia-knee-function/" + rId}
                          className="assm_back_btn"
                        >
                          <img src={backIcon} alt="" />
                          <span>{translation.back_button}</span>
                        </Link>
                    }

                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}

            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PediaKneeQualityOfLife;
