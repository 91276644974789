import React, { useMemo, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import KneeScoreRangeSlider from "../../../custom/knee-score-range-slider/KneeScoreRangeSlider";
import HandWristQolScoreRangeSlider from "../../../custom/hand-wrist-qol-score-range-slider/HandWristQolScoreRangeSlider";
import CustomToaster from "../../../custom/toaster/CustomToaster";
import HandWristRangeSlider from "../../../custom/hand-wrist-range-slider/HandWristRangeSlider";
import RangeSlider from "../../../custom/range-slider/RangeSlider";
import {
  updateHandWristqolFieldScore,
  updateHandWristqolScores,
  updateHandWristScores,
  updateHandWristfunctionScores,
  updateHandWristPainScores,
  updateHandWristSymptomsScores,
  updateHandWristsatisfactionScores,
  updateHandWristFeedbackScores,
  updateHandWristUramScores,
  updateHandWristbctqScores,
  updateAllData,
} from "../../../../redux/actions/handWrist";
import useAxios from "../../../../axiosinstance";
import useTranslation from "../../../customHooks/translations";
import HandWristTab from "../../tab-section/HandWristTab";
import { useSearchParams } from "react-router-dom";
import NameCustom from "../../../custom/name/NameCustom";
function HandWristQQL() {
  const translation = useTranslation();
  let lg = sessionStorage.getItem("language");
  const dispatch = useDispatch();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const axiosinstance = useAxios();
  const evaluated = useSelector((state) => state.handWrist.evaluated);
  const [isLoading, setIsLoading] = useState(true);
  const [problem, setProblemR] = useState("0");

  const [washing, setWashingR] = useState("0");

  const [act, setActR] = useState("0");

  const [pain, setPainR] = useState("0");

  const [depression, setDepressionR] = useState("0");

  const [handpercent, setHandpercent] = useState("0");

  const [updateTotal, setUpdateTotal] = useState(false);
  const [initialAssesment, setInitialAssesment] = useState(0);

  const [syndrome, setSyndrome] = useState("0");
  const [disease, setDisease] = useState("0");

  const navigate = useNavigate();

  const [chk, setChk] = useState(false);
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=23`)
      .then((res) => {
        setSyndrome(res.data.data2[0].isSyndrome);
        setDisease(res.data.data2[0].isDisease);

        setInitialAssesment(res.data.data2[0].initial_assessment);
        dispatch(updateHandWristPainScores(res.data.data2[0].painScore));
        dispatch(
          updateHandWristSymptomsScores(res.data.data2[0].symptomsScore)
        );
        dispatch(updateHandWristScores(res.data.data2[0].evaluated));
        dispatch(
          updateHandWristfunctionScores(res.data.data2[0].functionScore)
        );
        dispatch(updateHandWristqolScores(res.data.data2[0].qolScore));
        dispatch(updateHandWristUramScores(res.data.data2[0].uramScore));
        dispatch(updateHandWristbctqScores(res.data.data2[0].bctq));

        dispatch(
          updateHandWristsatisfactionScores(res.data.data2[0].satisfactionScore)
        );
        dispatch(
          updateHandWristFeedbackScores(res.data.data2[0].doctorFeedback)
        );
        setChk(true);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };

  useEffect(() => {
    save();
  }, []);
  const HandQol = useSelector((state) => state.handWrist.qolScore[0]);
  const json = useSelector((state) => state.handWrist);

  useEffect(() => {
    setProblemR(HandQol.problem);

    setWashingR(HandQol.washing);

    setActR(HandQol.act);

    setPainR(HandQol.pain);

    setDepressionR(HandQol.depression);

    setHandpercent(HandQol.handpercent);
  }, [HandQol]);

  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true"?"1":"0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [HandQol, updateTotal]);

  const handleProblemR = (e) => {
    dispatch(
      updateHandWristqolFieldScore({
        key: "problem",
        value: e,
      })
    );
    setProblemR(e);
  };

  const handleWashingR = (e) => {
    dispatch(
      updateHandWristqolFieldScore({
        key: "washing",
        value: e,
      })
    );
    setWashingR(e);
  };

  const handleActR = (e) => {
    dispatch(
      updateHandWristqolFieldScore({
        key: "act",
        value: e,
      })
    );
    setActR(e);
  };

  const handlePainR = (e) => {
    dispatch(
      updateHandWristqolFieldScore({
        key: "pain",
        value: e,
      })
    );
    setPainR(e);
  };

  const handleDepressionR = (e) => {
    dispatch(
      updateHandWristqolFieldScore({
        key: "depression",
        value: e,
      })
    );

    setDepressionR(e);
  };

  const handleHandpercent = (e) => {
    dispatch(
      updateHandWristqolFieldScore({
        key: "handpercent",
        value: e,
      })
    );
    setHandpercent(e);
  };

  const continueRedirect = (e) => {
    dispatch(
      updateHandWristqolScores([
        {
          problem: problem,
          washing: washing,
          act: act,
          pain: pain,
          depression: depression,
          handpercent: handpercent,
        },
      ])
    );

    navigate("/hand-wrist-satisfaction/" + rId);
  };

  let akpt = searchParams.get("akpt");
  const [msg, setMsg] = useState("");
  const [msgType, setmsgType] = useState("");
  
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update);
    setmsgType("success");
    setTimeout(() => {
      window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" +akpt

      setMsg("");
      setmsgType("success");
    }, 2000);
  };

  const handleNext=()=>{
    navigate(`/hand-wrist-satisfaction/${rId}?d=true&akpt=${akpt}`);

  }
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="pain_screen mb_100">
          <div class="d-flex justify-content-end">
            <NameCustom />
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && <HandWristTab rId={rId} />}
              <CustomToaster msg={msg} msgType={msgType} />

              <h4 className="ph_title f_40">
                {translation.patient_hand_quality_of_life}
              </h4>

              <div className="function_formtop">
                <div className="row">
                  <div className="col-md-6 col-sm-6 function_head">
                    <label>
                      {translation.patient_hand_quality_of_life_instruction}
                    </label>{" "}
                    <br />
                    <span dir={lg === "ara" ? "rtl" : " "}>
                      (<strong>0</strong> ={" "}
                      {translation.patient_hand_quality_of_life_instruction_0},{" "}
                      <strong>1</strong> ={" "}
                      {translation.patient_hand_quality_of_life_instruction_1},{" "}
                      <strong>2</strong> ={" "}
                      {translation.patient_hand_quality_of_life_instruction_2},{" "}
                      <strong>3</strong> =
                      {translation.patient_hand_quality_of_life_instruction_3},{" "}
                      <strong>4</strong> ={" "}
                      {translation.patient_hand_quality_of_life_instruction_4})
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-6 mb_10">
                    <HandWristQolScoreRangeSlider
                      label={translation.patient_hand_quality_of_life_q1}
                      classes=""
                      value={problem}
                      onChange={handleProblemR}
                      sliderData={[
                        {
                          score: "0",
                          label: translation.patient_hand_quality_of_life_q1_a1,
                        },
                        {
                          score: "1",
                          label: translation.patient_hand_quality_of_life_q1_a2,
                        },
                        {
                          score: "2",
                          label: translation.patient_hand_quality_of_life_q1_a3,
                        },
                        {
                          score: "3",
                          label: translation.patient_hand_quality_of_life_q1_a4,
                        },
                        {
                          score: "4",
                          label: translation.patient_hand_quality_of_life_q1_a5,
                        },
                      ]}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-6 mb_10">
                    <HandWristQolScoreRangeSlider
                      label={translation.patient_hand_quality_of_life_q2}
                      classes=""
                      value={washing}
                      onChange={handleWashingR}
                      sliderData={[
                        {
                          score: "0",
                          label: translation.patient_hand_quality_of_life_q2_a1,
                        },
                        {
                          score: "1",
                          label: translation.patient_hand_quality_of_life_q2_a2,
                        },
                        {
                          score: "2",
                          label: translation.patient_hand_quality_of_life_q2_a3,
                        },
                        {
                          score: "3",
                          label: translation.patient_hand_quality_of_life_q2_a4,
                        },
                        {
                          score: "4",
                          label: translation.patient_hand_quality_of_life_q2_a5,
                        },
                      ]}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-6 mb_10">
                    <HandWristQolScoreRangeSlider
                      label={translation.patient_hand_quality_of_life_q3}
                      classes=""
                      value={act}
                      onChange={handleActR}
                      sliderData={[
                        {
                          score: "0",
                          label: translation.patient_hand_quality_of_life_q3_a1,
                        },
                        {
                          score: "1",
                          label: translation.patient_hand_quality_of_life_q3_a2,
                        },
                        {
                          score: "2",
                          label: translation.patient_hand_quality_of_life_q3_a3,
                        },
                        {
                          score: "3",
                          label: translation.patient_hand_quality_of_life_q3_a4,
                        },
                        {
                          score: "4",
                          label: translation.patient_hand_quality_of_life_q3_a5,
                        },
                      ]}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-6 mb_10">
                    <HandWristQolScoreRangeSlider
                      label={translation.patient_hand_quality_of_life_q4}
                      classes=""
                      value={pain}
                      onChange={handlePainR}
                      sliderData={[
                        {
                          score: "0",
                          label: translation.patient_hand_quality_of_life_q4_a1,
                        },
                        {
                          score: "1",
                          label: translation.patient_hand_quality_of_life_q4_a2,
                        },
                        {
                          score: "2",
                          label: translation.patient_hand_quality_of_life_q4_a3,
                        },
                        {
                          score: "3",
                          label: translation.patient_hand_quality_of_life_q4_a4,
                        },
                        {
                          score: "4",
                          label: translation.patient_hand_quality_of_life_q4_a5,
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-6 mb_10">
                    <HandWristQolScoreRangeSlider
                      label={translation.patient_hand_quality_of_life_q5}
                      classes=""
                      value={depression}
                      onChange={handleDepressionR}
                      sliderData={[
                        {
                          score: "0",
                          label: translation.patient_hand_quality_of_life_q5_a1,
                        },
                        {
                          score: "1",
                          label: translation.patient_hand_quality_of_life_q5_a2,
                        },
                        {
                          score: "2",
                          label: translation.patient_hand_quality_of_life_q5_a3,
                        },
                        {
                          score: "3",
                          label: translation.patient_hand_quality_of_life_q5_a4,
                        },
                        {
                          score: "4",
                          label: translation.patient_hand_quality_of_life_q5_a5,
                        },
                      ]}
                    />
                  </div>
                </div>
                <br />
              </div>
              <div className="row">
                <div className="mb_30">
                  <RangeSlider
                    id="handwristpercent"
                    min="0"
                    max="100"
                    step="1"
                    type="text"
                    leftTitle={translation.patient_hand_quality_of_life_q6_a1}
                    rightTitle={translation.patient_hand_quality_of_life_q6_a2}
                    colorcode="1"
                    label={translation.patient_hand_quality_of_life_q6}
                    value={handpercent}
                    onChange={handleHandpercent}
                  />
                </div>
              </div>
              {doctorEnable === "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6"></div>
                  <div className="col-md-6 col-sm-6 text-end">
                  <button
                      className="next_btn btn_fill me-3"
                      onClick={() => handleNext()}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                    <button
                      className="next_btn btn_fill"
                      onClick={() => handleSubmit()}
                    >
                      {translation.submit}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
                </div>
              )}
              {doctorEnable !== "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    {disease === "2" ? (
                      <Link
                        to={"/hand-wrist-uram/" + rId}
                        className="assm_back_btn"
                      >
                        <img src={backIcon} alt="" />
                        <span>{translation.back_button}</span>
                      </Link>
                    ) : syndrome === "2" ? (
                      <Link
                        to={"/hand-wrist-bctq/" + rId}
                        className="assm_back_btn"
                      >
                        <img src={backIcon} alt="" />
                        <span>{translation.back_button}</span>
                      </Link>
                    ) : (
                      <Link
                        to={"/hand-wrist-function/" + rId}
                        className="assm_back_btn"
                      >
                        <img src={backIcon} alt="" />
                        <span>{translation.back_button}</span>
                      </Link>
                    )}
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default HandWristQQL;
