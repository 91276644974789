import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../../images/back.png";
import nextWhiteIcon from "../../../../../images/next-white.png";
import nextIcon from "../../../../../images/next.png";
import LoadingSpinner from "../../../../custom/loader/LoadingSpinner";
import KneeScoreRangeSlider from "../../../../custom/knee-score-range-slider/KneeScoreRangeSlider";
import FullDropdown from "../../../../custom/dropdown/FullDropdown";
import RadioSwitch from "../../../../custom/radio-switch/RadioSwitch";
import RadioButton from "../../../../custom/radio-button/RadioButton";
import RangeSlider from "../../../../custom/range-slider/RangeSlider";
import {
  updateAdultKneeFunctionFieldScore,
  updateAdultKneeFunctionScore,
  updateAdultKneeSymptomsScore,
  updateAdultKneeScores,
  updateAdultKneePainScores,
  updateAdultKneeQolScore,
  updateAdultKneeSatisfactionScore,
  updateAdultKneeNorwichInstaScore,
  updateAllData,
  updateAdultKneeFeedback,
} from "../../../../../redux/actions/index";
import useAxios from "../../../../../axiosinstance";
import useTransition from "../../../../customHooks/translations";
import AdultkneeTab from "../../../tab-section/AdultkneeTab";
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../../custom/toaster/CustomToaster";
import {
  LeftSide,
  LeftTrans,
  RightSide,
  RigthTrans,
} from "../../../../../utilities/RightLeftFormat";
import NameCustom from "../../../../custom/name/NameCustom";

function AdultKneeFunction() {
  const translation = useTransition();
  let lg = sessionStorage.getItem("language");
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const axiosinstance = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  const [goUpStairsR, setGoUpStairsR] = useState("0");
  const [goUpStairsL, setGoUpStairsL] = useState("0");
  const [goDownStairsR, setGoDownStairsR] = useState("0");
  const [goDownStairsL, setGoDownStairsL] = useState("0");
  const [kneelFrontR, setKneelFrontR] = useState("0");
  const [kneelFrontL, setKneelFrontL] = useState("0");
  const [straighteningR, setStraighteningR] = useState("0");
  const [straighteningL, setStraighteningL] = useState("0");
  const [bendingKneeR, setBendingKneeR] = useState("0");
  const [bendingKneeL, setBendingKneeL] = useState("0");
  const [squatR, setSquatR] = useState("0");
  const [squatL, setSquatL] = useState("0");
  const [sitKneeBentR, setSitKneeBentR] = useState("0");
  const [sitKneeBentL, setSitKneeBentL] = useState("0");
  const [riseFromChairR, setRiseFromChairR] = useState("0");
  const [riseFromChairL, setRiseFromChairL] = useState("0");
  const [riseFromBedR, setRiseFromBedR] = useState("0");
  const [riseFromBedL, setRiseFromBedL] = useState("0");
  const [lyingInBedR, setLyingInBedR] = useState("0");
  const [lyingInBedL, setLyingInBedL] = useState("0");
  const [bendingToFloorR, setBendingToFloorR] = useState("0");
  const [bendingToFloorL, setBendingToFloorL] = useState("0");
  const [puttingOnSocksR, setPuttingOnSocksR] = useState("0");
  const [puttingOnSocksL, setPuttingOnSocksL] = useState("0");
  const [takingOffSocksR, setTakingOffSocksR] = useState("0");
  const [takingOffSocksL, setTakingOffSocksL] = useState("0");
  const [standingR, setStandingR] = useState("0");
  const [standingL, setStandingL] = useState("0");
  const [walkingR, setWalkingR] = useState("0");
  const [walkingL, setWalkingL] = useState("0");
  const [runStraightAheadR, setRunStraightAheadR] = useState("0");
  const [runStraightAheadL, setRunStraightAheadL] = useState("0");
  const [jumpAndLandR, setJumpAndLandR] = useState("0");
  const [jumpAndLandL, setJumpAndLandL] = useState("0");
  const [stopAndStartR, setStopAndStartR] = useState("0");
  const [stopAndStartL, setStopAndStartL] = useState("0");
  const [twistingR, setTwistingR] = useState("0");
  const [twistingL, setTwistingL] = useState("0");
  const [publicTransportR, setPublicTransportR] = useState("0");
  const [publicTransportL, setPublicTransportL] = useState("0");
  const [gettingBathR, setGettingBathR] = useState("0");
  const [gettingBathL, setGettingBathL] = useState("0");
  const [gettingToiletR, setGettingToiletR] = useState("0");
  const [gettingToiletL, setGettingToiletL] = useState("0");
  const [washingR, setWashingR] = useState("0");
  const [washingL, setWashingL] = useState("0");
  const [shoppingR, setShoppingR] = useState("0");
  const [shoppingL, setShoppingL] = useState("0");
  const [heavyDutiesR, setHeavyDutiesR] = useState("0");
  const [heavyDutiesL, setHeavyDutiesL] = useState("0");
  const [lightDutiesR, setLightDutiesR] = useState("0");
  const [lightDutiesL, setLightDutiesL] = useState("0");
  const [rateKneeFunctionR, setRateKneeFunctionR] = useState("0");
  const [rateKneeFunctionL, setRateKneeFunctionL] = useState("0");
  const [activityLevelR, setActivityLevelR] = useState("0");
  const [activityLevelL, setActivityLevelL] = useState("0");
  const [age, setAge] = useState("0");
  const [highestActivity, setHighestActivity] = useState("0");
  const [highestActivityL, setHighestActivityL] = useState("0");

  const [patellarInstability, setPatellarInstability] = useState("0");
  const [patellarInstabilityL, setPatellarInstabilityL] = useState("0");

  const [sports, setSports] = useState("");
  const [currsports, currSetSports] = useState("");

  const evlAdultKnee = useSelector(
    (state) => state.adultKneeScore.evlAdultKnee
  );
  const [patellarInstab] = useSelector(
    (state) => state.adultKneeScore.functionScore
  );
  // console.log(patellarInstab);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [chk, setChk] = useState(false);
  const [updateTotal, setUpdateTotal] = useState(false);
  const [initialAssesment, setInitialAssesment] = useState(0);
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=5`)
      .then((res) => {
        setInitialAssesment(res.data.data2[0].initial_assessment);
        setAge(res.data.data2[0].age);
        dispatch(updateAdultKneePainScores(res.data.data2[0].painScore));
        dispatch(updateAdultKneeSymptomsScore(res.data.data2[0].symptomsScore));
        dispatch(updateAdultKneeScores(res.data.data2[0].evlAdultKnee));
        dispatch(updateAdultKneeQolScore(res.data.data2[0].qolScore));
        dispatch(updateAdultKneeFunctionScore(res.data.data2[0].functionScore));
        dispatch(
          updateAdultKneeNorwichInstaScore(res.data.data2[0].norwichInstability)
        );
        dispatch(
          updateAdultKneeSatisfactionScore(res.data.data2[0].satisfactionScore)
        );
        dispatch(updateAdultKneeFeedback(res.data.data2[0].doctorFeedback));
        setChk(true);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };

  useEffect(() => {
    save();
  }, []);

  const KneeFunction = useSelector(
    (state) => state.adultKneeScore.functionScore[0]
  );
  const json = useSelector((state) => state.adultKneeScore);

  useEffect(() => {
    setGoUpStairsR(KneeFunction.goUpStairsR);
    setGoUpStairsL(KneeFunction.goUpStairsL);
    setGoDownStairsR(KneeFunction.goDownStairsR);
    setGoDownStairsL(KneeFunction.goDownStairsL);
    setKneelFrontR(KneeFunction.kneelFrontR);
    setKneelFrontL(KneeFunction.kneelFrontL);
    setStraighteningR(KneeFunction.straighteningR);
    setStraighteningL(KneeFunction.straighteningL);
    setBendingKneeR(KneeFunction.bendingKneeR);
    setBendingKneeL(KneeFunction.bendingKneeL);
    setSquatR(KneeFunction.squatR);
    setSquatL(KneeFunction.squatL);
    setSitKneeBentR(KneeFunction.sitKneeBentR);
    setSitKneeBentL(KneeFunction.sitKneeBentL);
    setRiseFromChairR(KneeFunction.riseFromChairR);
    setRiseFromChairL(KneeFunction.riseFromChairL);
    setRiseFromBedR(KneeFunction.riseFromBedR);
    setRiseFromBedL(KneeFunction.riseFromBedL);
    setLyingInBedR(KneeFunction.lyingInBedR);
    setLyingInBedL(KneeFunction.lyingInBedL);
    setBendingToFloorR(KneeFunction.bendingToFloorR);
    setBendingToFloorL(KneeFunction.bendingToFloorL);
    setPuttingOnSocksR(KneeFunction.puttingOnSocksR);
    setPuttingOnSocksL(KneeFunction.puttingOnSocksL);
    setTakingOffSocksR(KneeFunction.takingOffSocksR);
    setTakingOffSocksL(KneeFunction.takingOffSocksL);
    setStandingR(KneeFunction.standingR);
    setStandingL(KneeFunction.standingL);
    setWalkingR(KneeFunction.walkingR);
    setWalkingL(KneeFunction.walkingL);
    setRunStraightAheadR(KneeFunction.runStraightAheadR);
    setRunStraightAheadL(KneeFunction.runStraightAheadL);
    setJumpAndLandR(KneeFunction.jumpAndLandR);
    setJumpAndLandL(KneeFunction.jumpAndLandL);
    setStopAndStartR(KneeFunction.stopAndStartR);
    setStopAndStartL(KneeFunction.stopAndStartL);
    setTwistingR(KneeFunction.twistingR);
    setTwistingL(KneeFunction.twistingL);
    setPublicTransportR(KneeFunction.publicTransportR);
    setPublicTransportL(KneeFunction.publicTransportL);
    setGettingBathR(KneeFunction.gettingBathR);
    setGettingBathL(KneeFunction.gettingBathL);
    setGettingToiletR(KneeFunction.gettingToiletR);
    setGettingToiletL(KneeFunction.gettingToiletL);
    setWashingR(KneeFunction.washingR);
    setWashingL(KneeFunction.washingL);
    setShoppingR(KneeFunction.shoppingR);
    setShoppingL(KneeFunction.shoppingL);
    setHeavyDutiesR(KneeFunction.heavyDutiesR);
    setHeavyDutiesL(KneeFunction.heavyDutiesL);
    setLightDutiesR(KneeFunction.lightDutiesR);
    setLightDutiesL(KneeFunction.lightDutiesL);
    setRateKneeFunctionR(KneeFunction.rateKneeFunctionR);
    setRateKneeFunctionL(KneeFunction.rateKneeFunctionL);
    setActivityLevelR(KneeFunction.activityLevelR);
    setActivityLevelL(KneeFunction.activityLevelL);
    setPatellarInstability(KneeFunction.patellarInstability);
    setPatellarInstabilityL(KneeFunction.patellarInstabilityL);
    setSports(KneeFunction.sports);
    currSetSports(KneeFunction.currsports);
    setHighestActivity(KneeFunction.highestActivity)
    setHighestActivityL(KneeFunction.highestActivityL)
  }, [KneeFunction]);

  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true" ? "1" : "0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => { });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [KneeFunction, updateTotal]);

  const scaleLabels = [
    { score: "0", label: translation.no_difficulty },
    { score: "1", label: translation.minimal_difficulty },
    { score: "2", label: translation.moderate_difficulty },
    { score: "3", label: translation.extreme_difficult },
    { score: "4", label: translation.unable_to_do },
  ];

  const handleGoUpStairsR = (e) => {
    setGoUpStairsR(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "goUpStairsR",
        value: e,
      })
    );
  };
  const handleGoUpStairsL = (e) => {
    setGoUpStairsL(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "goUpStairsL",
        value: e,
      })
    );
  };

  const handlegoDownStairsR = (e) => {
    setGoDownStairsR(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "goDownStairsR",
        value: e,
      })
    );
  };
  const handlegoDownStairsL = (e) => {
    setGoDownStairsL(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "goDownStairsL",
        value: e,
      })
    );
  };

  const handleKneelFrontR = (e) => {
    setKneelFrontR(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "kneelFrontR",
        value: e,
      })
    );
  };
  const handleKneelFrontL = (e) => {
    setKneelFrontL(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "kneelFrontL",
        value: e,
      })
    );
  };

  const handleStraighteningR = (e) => {
    setStraighteningR(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "straighteningR",
        value: e,
      })
    );
  };
  const handleStraighteningL = (e) => {
    setStraighteningL(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "straighteningL",
        value: e,
      })
    );
  };

  const handleBendingKneeR = (e) => {
    setBendingKneeR(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "bendingKneeR",
        value: e,
      })
    );
  };
  const handleBendingKneeL = (e) => {
    setBendingKneeL(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "bendingKneeL",
        value: e,
      })
    );
  };

  const handleSquatR = (e) => {
    setSquatR(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "squatR",
        value: e,
      })
    );
  };
  const handleSquatL = (e) => {
    setSquatL(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "squatL",
        value: e,
      })
    );
  };

  const handleSitKneeBentR = (e) => {
    setSitKneeBentR(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "sitKneeBentR",
        value: e,
      })
    );
  };
  const handleSitKneeBentL = (e) => {
    setSitKneeBentL(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "sitKneeBentL",
        value: e,
      })
    );
  };

  const handleRiseFromChairR = (e) => {
    setRiseFromChairR(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "riseFromChairR",
        value: e,
      })
    );
  };
  const handleRiseFromChairL = (e) => {
    setRiseFromChairL(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "riseFromChairL",
        value: e,
      })
    );
  };

  const handleRiseFromBedR = (e) => {
    setRiseFromBedR(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "riseFromBedR",
        value: e,
      })
    );
  };
  const handleRiseFromBedL = (e) => {
    setRiseFromBedL(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "riseFromBedL",
        value: e,
      })
    );
  };

  const handleLyingInBedR = (e) => {
    setLyingInBedR(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "lyingInBedR",
        value: e,
      })
    );
  };
  const handleLyingInBedL = (e) => {
    setLyingInBedL(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "lyingInBedL",
        value: e,
      })
    );
  };

  const handleBendingToFloorR = (e) => {
    setBendingToFloorR(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "bendingToFloorR",
        value: e,
      })
    );
  };
  const handleBendingToFloorL = (e) => {
    setBendingToFloorL(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "bendingToFloorL",
        value: e,
      })
    );
  };

  const handlePuttingOnSocksR = (e) => {
    setPuttingOnSocksR(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "puttingOnSocksR",
        value: e,
      })
    );
  };
  const handlePuttingOnSocksL = (e) => {
    setPuttingOnSocksL(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "puttingOnSocksL",
        value: e,
      })
    );
  };

  const handleTakingOffSocksR = (e) => {
    setTakingOffSocksR(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "takingOffSocksR",
        value: e,
      })
    );
  };
  const handleTakingOffSocksL = (e) => {
    setTakingOffSocksL(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "takingOffSocksL",
        value: e,
      })
    );
  };

  const handleStandingR = (e) => {
    setStandingR(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "standingR",
        value: e,
      })
    );
  };
  const handleStandingL = (e) => {
    setStandingL(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "standingL",
        value: e,
      })
    );
  };

  const handleWalkingR = (e) => {
    setWalkingR(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "walkingR",
        value: e,
      })
    );
  };
  const handleWalkingL = (e) => {
    setWalkingL(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "walkingL",
        value: e,
      })
    );
  };

  const handleRunStraightAheadR = (e) => {
    setRunStraightAheadR(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "runStraightAheadR",
        value: e,
      })
    );
  };
  const handleRunStraightAheadL = (e) => {
    setRunStraightAheadL(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "runStraightAheadL",
        value: e,
      })
    );
  };

  const handleJumpAndLandR = (e) => {
    setJumpAndLandR(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "jumpAndLandR",
        value: e,
      })
    );
  };
  const handleJumpAndLandL = (e) => {
    setJumpAndLandL(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "jumpAndLandL",
        value: e,
      })
    );
  };

  const handleStopAndStartR = (e) => {
    setStopAndStartR(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "stopAndStartR",
        value: e,
      })
    );
  };
  const handleStopAndStartL = (e) => {
    setStopAndStartL(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "stopAndStartL",
        value: e,
      })
    );
  };

  const handleTwistingR = (e) => {
    setTwistingR(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "twistingR",
        value: e,
      })
    );
  };
  const handleTwistingL = (e) => {
    setTwistingL(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "twistingL",
        value: e,
      })
    );
  };

  const handlePublicTransportR = (e) => {
    setPublicTransportR(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "publicTransportR",
        value: e,
      })
    );
  };
  const handlePublicTransportL = (e) => {
    setPublicTransportL(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "publicTransportL",
        value: e,
      })
    );
  };

  const handleGettingBathR = (e) => {
    setGettingBathR(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "gettingBathR",
        value: e,
      })
    );
  };
  const handleGettingBathL = (e) => {
    setGettingBathL(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "gettingBathL",
        value: e,
      })
    );
  };

  const handleGettingToiletR = (e) => {
    setGettingToiletR(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "gettingToiletR",
        value: e,
      })
    );
  };
  const handleGettingToiletL = (e) => {
    setGettingToiletL(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "gettingToiletL",
        value: e,
      })
    );
  };

  const handleWashingR = (e) => {
    setWashingR(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "washingR",
        value: e,
      })
    );
  };
  const handleWashingL = (e) => {
    setWashingL(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "washingL",
        value: e,
      })
    );
  };

  const handleShoppingR = (e) => {
    setShoppingR(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "shoppingR",
        value: e,
      })
    );
  };
  const handleShoppingL = (e) => {
    setShoppingL(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "shoppingL",
        value: e,
      })
    );
  };

  const handleHeavyDutiesR = (e) => {
    setHeavyDutiesR(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "heavyDutiesR",
        value: e,
      })
    );
  };
  const handleHeavyDutiesL = (e) => {
    setHeavyDutiesL(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "heavyDutiesL",
        value: e,
      })
    );
  };

  const handleLightDutiesR = (e) => {
    setLightDutiesR(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "lightDutiesR",
        value: e,
      })
    );
  };
  const handleLightDutiesL = (e) => {
    setLightDutiesL(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "lightDutiesL",
        value: e,
      })
    );
  };

  const handleRateKneeFunctionR = (e) => {
    setRateKneeFunctionR(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "rateKneeFunctionR",
        value: e,
      })
    );
  };
  const handleRateKneeFunctionL = (e) => {
    setRateKneeFunctionL(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "rateKneeFunctionL",
        value: e,
      })
    );
  };

  const handleActivityLevelR = (e) => {
    setActivityLevelR(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "activityLevelR",
        value: e,
      })
    );
  };
  const handleActivityLevelL = (e) => {
    setActivityLevelL(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "activityLevelL",
        value: e,
      })
    );
  };

  const handleHighestActivity = (e) => {
    setHighestActivity(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "highestActivity",
        value: e,
      })
    );
  };

  const handleHighestActivityL = (e) => {
    setHighestActivityL(e);
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "highestActivityL",
        value: e,
      })
    );
  };

  const handlePatellarInstability = (e) => {
    setPatellarInstability(e);

    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "patellarInstability",
        value: e,
      })
    );
  };

  const handlePatellarInstabilityL = (e) => {
    setPatellarInstabilityL(e);

    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "patellarInstabilityL",
        value: e,
      })
    );
  };

  const handleSports = (e) => {
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "sports",
        value: e,
      })
    );
    setSports(e);
  };

  const currhandleSports = (e) => {
    dispatch(
      updateAdultKneeFunctionFieldScore({
        key: "currsports",
        value: e,
      })
    );
    currSetSports(e);
  };

  const continueRedirect = (e) => {
    dispatch(
      updateAdultKneeFunctionScore([
        {
          goUpStairsR: goUpStairsR,
          goUpStairsL: goUpStairsL,
          goDownStairsR: goDownStairsR,
          goDownStairsL: goDownStairsL,
          kneelFrontR: kneelFrontR,
          kneelFrontL: kneelFrontL,
          straighteningR: straighteningR,
          straighteningL: straighteningL,
          bendingKneeR: bendingKneeR,
          bendingKneeL: bendingKneeL,
          squatR: squatR,
          squatL: squatL,
          sitKneeBentR: sitKneeBentR,
          sitKneeBentL: sitKneeBentL,
          riseFromChairR: riseFromChairR,
          riseFromChairL: riseFromChairL,
          riseFromBedR: riseFromBedR,
          riseFromBedL: riseFromBedL,
          lyingInBedR: lyingInBedR,
          lyingInBedL: lyingInBedL,
          bendingToFloorR: bendingToFloorR,
          bendingToFloorL: bendingToFloorL,
          puttingOnSocksR: puttingOnSocksR,
          puttingOnSocksL: puttingOnSocksL,
          takingOffSocksR: takingOffSocksR,
          takingOffSocksL: takingOffSocksL,
          standingR: standingR,
          standingL: standingL,
          walkingR: walkingR,
          walkingL: walkingL,
          runStraightAheadR: runStraightAheadR,
          runStraightAheadL: runStraightAheadL,
          jumpAndLandR: jumpAndLandR,
          jumpAndLandL: jumpAndLandL,
          stopAndStartR: stopAndStartR,
          stopAndStartL: stopAndStartL,
          twistingR: twistingR,
          twistingL: twistingL,
          publicTransportR: publicTransportR,
          publicTransportL: publicTransportL,
          gettingBathR: gettingBathR,
          gettingBathL: gettingBathL,
          gettingToiletR: gettingToiletR,
          gettingToiletL: gettingToiletL,
          washingR: washingR,
          washingL: washingL,
          shoppingR: shoppingR,
          shoppingL: shoppingL,
          heavyDutiesR: heavyDutiesR,
          heavyDutiesL: heavyDutiesL,
          lightDutiesR: lightDutiesR,
          lightDutiesL: lightDutiesL,
          rateKneeFunctionR: rateKneeFunctionR,
          rateKneeFunctionL: rateKneeFunctionL,
          activityLevelR: activityLevelR,
          activityLevelL: activityLevelL,
          highestActivity: highestActivity,
          patellarInstability: patellarInstability,
          patellarInstabilityL: patellarInstabilityL,

          sports: sports,
          currsports: currsports,
        },
      ])
    );
    if (
      patellarInstab.patellarInstability === "1" ||
      patellarInstab.patellarInstabilityL === "1"
    ) {
      navigate("/patellar-instability/" + rId);
    } else {
      navigate("/adult-knee-quality-of-life/" + rId);
    }
    // navigate("/adult-knee-quality-of-life/"+rId);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  let akpt = searchParams.get("akpt");

  const [msg, setMsg] = useState("");
  const [msgType, setmsgType] = useState("");
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update);
    setmsgType("success");
    setTimeout(() => {
      window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + akpt
      setMsg("");
      setmsgType("success");
    }, 2000);
  };
  const handleNext = () => {

    if (
      patellarInstab.patellarInstability === "1" ||
      patellarInstab.patellarInstabilityL === "1"
    ) {
      navigate(`/patellar-instability/${rId}?d=true&akpt=${akpt}`);
    } else {
      navigate(`/adult-knee-quality-of-life/${rId}?d=true&akpt=${akpt}`);

    }

  }
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="pain_screen mb_100">
          <div class="d-flex justify-content-end">
            <NameCustom />
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && <AdultkneeTab rId={rId} />}
              <CustomToaster msg={msg} msgType={msgType} />

              <h4 className="ph_title f_40">
                {translation.patient_adult_knee_function}
              </h4>

              <div className="function_formtop">
                <div className="row">
                  <div className="col-md-6 col-sm-6 function_head">
                    <label>
                      {translation.patient_adult_knee_function_instruction}
                    </label>{" "}
                    <br />
                    <span dir={lg === "ara" ? "rtl" : " "}>
                      (<strong>0</strong> ={" "}
                      {translation.patient_adult_knee_function_instruction_0},{" "}
                      <strong>1</strong> ={" "}
                      {translation.patient_adult_knee_function_instruction_1},
                      <strong> 2</strong> ={" "}
                      {translation.patient_adult_knee_function_instruction_2},{" "}
                      <strong>3</strong> ={" "}
                      {translation.patient_adult_knee_function_instruction_3},{" "}
                      <strong>4</strong> ={" "}
                      {translation.patient_adult_knee_function_instruction_4})
                    </span>
                  </div>
                  <div className="col-md-6 col-sm-6 function_note">
                    <p>{translation.patient_adult_knee_function_note}</p>
                  </div>
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q1 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={goUpStairsR}
                        onChange={handleGoUpStairsR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q1_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q1_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q1_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q1_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q1_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q1 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={goUpStairsL}
                        onChange={handleGoUpStairsL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q1_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q1_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q1_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q1_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q1_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q2 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={goDownStairsR}
                        onChange={handlegoDownStairsR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q2_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q2_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q2_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q2_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q2_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q2 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={goDownStairsL}
                        onChange={handlegoDownStairsL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q2_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q2_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q2_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q2_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q2_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q3 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={kneelFrontR}
                        onChange={handleKneelFrontR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q3_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q3_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q3_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q3_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q3_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q3 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={kneelFrontL}
                        onChange={handleKneelFrontL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q3_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q3_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q3_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q3_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q3_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q4 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={straighteningR}
                        onChange={handleStraighteningR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q4_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q4_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q4_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q4_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q4_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q4 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={straighteningL}
                        onChange={handleStraighteningL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q4_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q4_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q4_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q4_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q4_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q5 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={bendingKneeR}
                        onChange={handleBendingKneeR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q5_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q5_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q5_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q5_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q5_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q5 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={bendingKneeL}
                        onChange={handleBendingKneeL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q5_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q5_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q5_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q5_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q5_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q6 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={squatR}
                        onChange={handleSquatR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q6_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q6_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q6_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q6_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q6_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q6 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={squatL}
                        onChange={handleSquatL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q6_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q6_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q6_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q6_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q6_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q7 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={sitKneeBentR}
                        onChange={handleSitKneeBentR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q7_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q7_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q7_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q7_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q7_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q7 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={sitKneeBentL}
                        onChange={handleSitKneeBentL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q7_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q7_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q7_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q7_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q7_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q8 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={riseFromChairR}
                        onChange={handleRiseFromChairR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q8_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q8_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q8_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q8_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q8_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q8 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={riseFromChairL}
                        onChange={handleRiseFromChairL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q8_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q8_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q8_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q8_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q8_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q9 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={riseFromBedR}
                        onChange={handleRiseFromBedR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q9_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q9_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q9_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q9_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q9_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q9 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={riseFromBedL}
                        onChange={handleRiseFromBedL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q9_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q9_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q9_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q9_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q9_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q10 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={lyingInBedR}
                        onChange={handleLyingInBedR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q10_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q10_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q10_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q10_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q10_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q10 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={lyingInBedL}
                        onChange={handleLyingInBedL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q10_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q10_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q10_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q10_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q10_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q11 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={bendingToFloorR}
                        onChange={handleBendingToFloorR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q11_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q11_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q11_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q11_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q11_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q11 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={bendingToFloorL}
                        onChange={handleBendingToFloorL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q11_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q11_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q11_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q11_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q11_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q12 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={puttingOnSocksR}
                        onChange={handlePuttingOnSocksR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q12_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q12_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q12_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q12_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q12_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q12 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={puttingOnSocksL}
                        onChange={handlePuttingOnSocksL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q12_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q12_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q12_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q12_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q12_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q13 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={takingOffSocksR}
                        onChange={handleTakingOffSocksR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q13_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q13_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q13_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q13_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q13_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q13 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={takingOffSocksL}
                        onChange={handleTakingOffSocksL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q13_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q13_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q13_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q13_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q13_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q14 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={standingR}
                        onChange={handleStandingR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q14_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q14_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q14_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q14_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q14_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q14 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={standingL}
                        onChange={handleStandingL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q14_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q14_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q14_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q14_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q14_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q15 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={walkingR}
                        onChange={handleWalkingR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q15_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q15_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q15_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q15_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q15_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q15 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={walkingL}
                        onChange={handleWalkingL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q15_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q15_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q15_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q15_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q15_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q16 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={runStraightAheadR}
                        onChange={handleRunStraightAheadR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q16_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q16_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q16_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q16_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q16_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q16 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={runStraightAheadL}
                        onChange={handleRunStraightAheadL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q16_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q16_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q16_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q16_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q16_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q17 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={jumpAndLandR}
                        onChange={handleJumpAndLandR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q17_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q17_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q17_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q17_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q17_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q17 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={jumpAndLandL}
                        onChange={handleJumpAndLandL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q17_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q17_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q17_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q17_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q17_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q18 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={stopAndStartR}
                        onChange={handleStopAndStartR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q18_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q18_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q18_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q18_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q18_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q18 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={stopAndStartL}
                        onChange={handleStopAndStartL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q18_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q18_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q18_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q18_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q18_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q19 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={twistingR}
                        onChange={handleTwistingR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q19_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q19_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q19_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q19_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q19_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q19 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={twistingL}
                        onChange={handleTwistingL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q19_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q19_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q19_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q19_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q19_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q20 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={publicTransportR}
                        onChange={handlePublicTransportR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q20_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q20_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q20_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q20_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q20_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q20 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={publicTransportL}
                        onChange={handlePublicTransportL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q20_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q20_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q20_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q20_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q20_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q21 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={gettingBathR}
                        onChange={handleGettingBathR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q21_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q21_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q21_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q21_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q21_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q21 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={gettingBathL}
                        onChange={handleGettingBathL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q21_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q21_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q21_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q21_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q21_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q22 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={gettingToiletR}
                        onChange={handleGettingToiletR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q22_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q22_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q22_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q22_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q22_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q22 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={gettingToiletL}
                        onChange={handleGettingToiletL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q22_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q22_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q22_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q22_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q22_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (                                            
                    ""
                  )}
                </div>

                {/* Adolcent age 2 and adult 1 */}
                {String(age) && String(age) !== "1" ? (
                  <div className="row">
                    {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                        <KneeScoreRangeSlider
                          label={
                            translation.patient_adult_knee_function_q23 +
                            RigthTrans(evlAdultKnee, translation)
                          }
                          classes=""
                          value={washingR}
                          onChange={handleWashingR}
                          sliderData={[
                            {
                              score: "0",
                              label:
                                translation.patient_adult_knee_function_q23_a1,
                            },
                            {
                              score: "1",
                              label:
                                translation.patient_adult_knee_function_q23_a2,
                            },
                            {
                              score: "2",
                              label:
                                translation.patient_adult_knee_function_q23_a3,
                            },
                            {
                              score: "3",
                              label:
                                translation.patient_adult_knee_function_q23_a4,
                            },
                            {
                              score: "4",
                              label:
                                translation.patient_adult_knee_function_q23_a5,
                            },
                          ]}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                        <KneeScoreRangeSlider
                          label={
                            translation.patient_adult_knee_function_q23 +
                            LeftTrans(evlAdultKnee, translation)
                          }
                          classes=""
                          value={washingL}
                          onChange={handleWashingL}
                          sliderData={[
                            {
                              score: "0",
                              label:
                                translation.patient_adult_knee_function_q23_a1,
                            },
                            {
                              score: "1",
                              label:
                                translation.patient_adult_knee_function_q23_a2,
                            },
                            {
                              score: "2",
                              label:
                                translation.patient_adult_knee_function_q23_a3,
                            },
                            {
                              score: "3",
                              label:
                                translation.patient_adult_knee_function_q23_a4,
                            },
                            {
                              score: "4",
                              label:
                                translation.patient_adult_knee_function_q23_a5,
                            },
                          ]}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <></>
                )}

                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          String(age) !== "1"
                            ? translation.patient_adult_knee_function_q24 +
                            RigthTrans(evlAdultKnee, translation)
                            : translation.patient_adolescent_knee_function_q23 +
                            RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={shoppingR}
                        onChange={handleShoppingR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              String(age) && String(age) !== "1"
                                ? translation.patient_adult_knee_function_q24_a1
                                : translation.patient_adolescent_knee_function_q23_a1,
                          },
                          {
                            score: "1",
                            label:
                              String(age) && String(age) !== "1"
                                ? translation.patient_adult_knee_function_q24_a2
                                : translation.patient_adolescent_knee_function_q23_a2,
                          },
                          {
                            score: "2",
                            label:
                              String(age) && String(age) !== "1"
                                ? translation.patient_adult_knee_function_q24_a3
                                : translation.patient_adolescent_knee_function_q23_a3,
                          },
                          {
                            score: "3",
                            label:
                              String(age) && String(age) !== "1"
                                ? translation.patient_adult_knee_function_q24_a1
                                : translation.patient_adolescent_knee_function_q23_a4,
                          },
                          {
                            score: "4",
                            label:
                              String(age) && String(age) !== "1"
                                ? translation.patient_adult_knee_function_q24_a1
                                : translation.patient_adolescent_knee_function_q23_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          String(age) && String(age) !== "1"
                            ? translation.patient_adult_knee_function_q24 +
                            LeftTrans(evlAdultKnee, translation)
                            : translation.patient_adolescent_knee_function_q23 +
                            LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={shoppingL}
                        onChange={handleShoppingL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              String(age) && String(age) !== "1"
                                ? translation.patient_adult_knee_function_q24_a1
                                : translation.patient_adolescent_knee_function_q23_a1,
                          },
                          {
                            score: "1",
                            label:
                              String(age) && String(age) !== "1"
                                ? translation.patient_adult_knee_function_q24_a2
                                : translation.patient_adolescent_knee_function_q23_a2,
                          },
                          {
                            score: "2",
                            label:
                              String(age) && String(age) !== "1"
                                ? translation.patient_adult_knee_function_q24_a3
                                : translation.patient_adolescent_knee_function_q23_a3,
                          },
                          {
                            score: "3",
                            label:
                              String(age) && String(age) !== "1"
                                ? translation.patient_adult_knee_function_q24_a1
                                : translation.patient_adolescent_knee_function_q23_a4,
                          },
                          {
                            score: "4",
                            label:
                              String(age) && String(age) !== "1"
                                ? translation.patient_adult_knee_function_q24_a1
                                : translation.patient_adolescent_knee_function_q23_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q25 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={heavyDutiesR}
                        onChange={handleHeavyDutiesR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q25_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q25_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q25_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q25_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q25_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q25 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={heavyDutiesL}
                        onChange={handleHeavyDutiesL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q25_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q25_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q25_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q25_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q25_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q26 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={lightDutiesR}
                        onChange={handleLightDutiesR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q26_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q26_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q26_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q26_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q26_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_function_q26 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={lightDutiesL}
                        onChange={handleLightDutiesL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_adult_knee_function_q26_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_adult_knee_function_q26_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_adult_knee_function_q26_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_adult_knee_function_q26_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_adult_knee_function_q26_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_20" + RightSide()}>
                      <RangeSlider
                        id="rateKneeFunctionR"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        leftTitle={
                          translation.patient_adult_knee_function_q28_a1
                        }
                        rightTitle={
                          translation.patient_adult_knee_function_q28_a2
                        }
                        colorcode="1"
                        label={
                          translation.patient_adult_knee_function_q27 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        span1={translation.patient_adult_knee_function_q28}
                        value={rateKneeFunctionR}
                        onChange={handleRateKneeFunctionR}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_20" + LeftSide()}>
                      <RangeSlider
                        id="rateKneeFunctionL"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        leftTitle={
                          translation.patient_adult_knee_function_q28_a1
                        }
                        rightTitle={
                          translation.patient_adult_knee_function_q28_a2
                        }
                        colorcode="1"
                        label={
                          translation.patient_adult_knee_function_q27 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        span1={translation.patient_adult_knee_function_q28}
                        value={rateKneeFunctionL}
                        onChange={handleRateKneeFunctionL}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="row mt-4">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_20" + RightSide()}>
                      <FullDropdown
                        data={[
                          {
                            value: "4",
                            label:
                              translation.patient_adult_knee_function_q29_a1,
                          },
                          {
                            value: "3",
                            label:
                              translation.patient_adult_knee_function_q29_a2,
                          },
                          {
                            value: "2",
                            label:
                              translation.patient_adult_knee_function_q29_a3,
                          },
                          {
                            value: "1",
                            label:
                              translation.patient_adult_knee_function_q29_a4,
                          },
                          {
                            value: "0",
                            label:
                              translation.patient_adult_knee_function_q29_a5,
                          },
                        ]}
                        label={
                          translation.patient_adult_knee_function_q29 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        styleClass=""
                        placeholder={translation.none}
                        value={activityLevelR}
                        onChange={handleActivityLevelR}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_20" + LeftSide()}>
                      <FullDropdown
                        data={[
                          {
                            value: "4",
                            label:
                              translation.patient_adult_knee_function_q29_a1,
                          },
                          {
                            value: "3",
                            label:
                              translation.patient_adult_knee_function_q29_a2,
                          },
                          {
                            value: "2",
                            label:
                              translation.patient_adult_knee_function_q29_a3,
                          },
                          {
                            value: "1",
                            label:
                              translation.patient_adult_knee_function_q29_a4,
                          },
                          {
                            value: "0",
                            label:
                              translation.patient_adult_knee_function_q29_a5,
                          },
                        ]}
                        label={
                          translation.patient_adult_knee_function_q29 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        styleClass=""
                        placeholder={translation.none}
                        value={activityLevelL}
                        onChange={handleActivityLevelL}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  <h4>{translation.patient_adult_knee_function_q30}</h4>

               
                    <div className="col-md-6 col-sm-6 mb_20">
                      <FullDropdown
                        data={[
                          {
                            value: "4",
                            label:
                              translation.patient_adult_knee_function_q30_q1_a1,
                          },
                          {
                            value: "3",
                            label:
                              translation.patient_adult_knee_function_q30_q1_a2,
                          },
                          {
                            value: "2",
                            label:
                              translation.patient_adult_knee_function_q30_q1_a3,
                          },
                          {
                            value: "1",
                            label:
                              translation.patient_adult_knee_function_q30_q1_a4,
                          },
                          {
                            value: "0",
                            label:
                              translation.patient_adult_knee_function_q30_q1_a5,
                          },
                        ]}
                        label={translation.patient_adult_knee_function_q30_q1}
                        styleClass=""
                        placeholder={translation.Select}
                        value={highestActivity}
                        onChange={handleHighestActivity}
                      />
                      <div>
                        {highestActivity === "4" && (
                          <>
                            <RadioButton
                              label={
                                translation.patient_adult_knee_function_q30_q1_a1_o1
                              }
                              selected={sports === "1"}
                              onChange={handleSports}
                              styleClass="custom_radio_box large"
                              value="1"
                              id="sports1"
                              name="sports"
                            />

                            <RadioButton
                              label={
                                translation.patient_adult_knee_function_q30_q1_a1_o2
                              }
                              selected={sports === "2"}
                              onChange={handleSports}
                              styleClass="custom_radio_box large"
                              value="2"
                              id="sports2"
                              name="sports"
                            />

                            <RadioButton
                              label={
                                translation.patient_adult_knee_function_q30_q1_a1_o3
                              }
                              selected={sports === "3"}
                              onChange={handleSports}
                              styleClass="custom_radio_box large"
                              value="3"
                              id="sports3"
                              name="sports"
                            />
                          </>
                        )}
                        {highestActivity === "3" && (
                          <>
                            <RadioButton
                              label={
                                translation.patient_adult_knee_function_q30_q1_a2_o1
                              }
                              styleClass="custom_radio_box large"
                              selected={sports === "4"}
                              value="4"
                              id="sports4"
                              name="sports"
                              onChange={handleSports}
                            />
                            <RadioButton
                              label={
                                translation.patient_adult_knee_function_q30_q1_a2_o2
                              }
                              styleClass="custom_radio_box large"
                              selected={sports === "5"}
                              value="5"
                              id="sports5"
                              name="sports"
                              onChange={handleSports}
                            />
                            <RadioButton
                              label={
                                translation.patient_adult_knee_function_q30_q1_a2_o3
                              }
                              styleClass="custom_radio_box large"
                              selected={sports === "6"}
                              value="6"
                              id="sports6"
                              name="sports"
                              onChange={handleSports}
                            />
                            <RadioButton
                              label={
                                translation.patient_adult_knee_function_q30_q1_a2_o4
                              }
                              styleClass="custom_radio_box large"
                              selected={sports === "7"}
                              value="7"
                              id="sports7"
                              name="sports"
                              onChange={handleSports}
                            />
                            <RadioButton
                              label={
                                translation.patient_adult_knee_function_q30_q1_a2_o5
                              }
                              styleClass="custom_radio_box large"
                              selected={sports === "8"}
                              value="8"
                              id="sports8"
                              name="sports"
                              onChange={handleSports}
                            />
                          </>
                        )}

                        {highestActivity === "2" && (
                          <>
                            <RadioButton
                              label={
                                translation.patient_adult_knee_function_q30_q1_a3_o1
                              }
                              styleClass="custom_radio_box large"
                              selected={sports === "9"}
                              value="9"
                              id="sports9"
                              name="sports"
                              onChange={handleSports}
                            />
                            <RadioButton
                              label={
                                translation.patient_adult_knee_function_q30_q1_a3_o2
                              }
                              styleClass="custom_radio_box large"
                              selected={sports === "10"}
                              value="10"
                              id="sports10"
                              name="sports"
                              onChange={handleSports}
                            />
                            <RadioButton
                              label={
                                translation.patient_adult_knee_function_q30_q1_a3_o3
                              }
                              styleClass="custom_radio_box large"
                              selected={sports === "11"}
                              value="11"
                              id="sports11"
                              name="sports"
                              onChange={handleSports}
                            />
                            <RadioButton
                              label={
                                translation.patient_adult_knee_function_q30_q1_a3_o4
                              }
                              styleClass="custom_radio_box large"
                              selected={sports === "12"}
                              value="12"
                              id="sports12"
                              name="sports"
                              onChange={handleSports}
                            />
                          </>
                        )}

                        {highestActivity === "1" && (
                          <>
                            <RadioButton
                              label={
                                translation.patient_adult_knee_function_q30_q1_a4_o1
                              }
                              styleClass="custom_radio_box large"
                              selected={sports === "13"}
                              value="13"
                              id="sports13"
                              name="sports"
                              onChange={handleSports}
                            />
                            <RadioButton
                              label={
                                translation.patient_adult_knee_function_q30_q1_a4_o2
                              }
                              styleClass="custom_radio_box large"
                              selected={sports === "14"}
                              value="14"
                              id="sports14"
                              name="sports"
                              onChange={handleSports}
                            />
                            <RadioButton
                              label={
                                translation.patient_adult_knee_function_q30_q1_a4_o3
                              }
                              styleClass="custom_radio_box large"
                              selected={sports === "15"}
                              value="15"
                              id="sports15"
                              name="sports"
                              onChange={handleSports}
                            />
                          </>
                        )}
                      </div>
                    </div>
              

                  <div className="col-md-6 col-sm-6 mb_20">
                    <FullDropdown
                      data={[
                        {
                          value: "4",
                          label:
                            translation.patient_adult_knee_function_q30_q2_a1,
                        },
                        {
                          value: "3",
                          label:
                            translation.patient_adult_knee_function_q30_q2_a2,
                        },
                        {
                          value: "2",
                          label:
                            translation.patient_adult_knee_function_q30_q2_a3,
                        },
                        {
                          value: "1",
                          label:
                            translation.patient_adult_knee_function_q30_q2_a4,
                        },
                        {
                          value: "0",
                          label:
                            translation.patient_adult_knee_function_q30_q2_a5,
                        },
                      ]}
                      label={translation.patient_adult_knee_function_q30_q2}
                      styleClass=""
                      placeholder={translation.Select}
                      value={highestActivityL}
                      onChange={handleHighestActivityL}
                    />
                    <div>
                      {highestActivityL === "4" && (
                        <>
                          <RadioButton
                            label={
                              translation.patient_adult_knee_function_q30_q2_a1_o1
                            }
                            selected={currsports === "16"}
                            onChange={currhandleSports}
                            styleClass="custom_radio_box large"
                            value="16"
                            id="sports16"
                            name="currsports"
                          />

                          <RadioButton
                            label={
                              translation.patient_adult_knee_function_q30_q2_a1_o2
                            }
                            selected={currsports === "17"}
                            onChange={currhandleSports}
                            styleClass="custom_radio_box large"
                            value="17"
                            id="sports17"
                            name="currsports"
                          />

                          <RadioButton
                            label={
                              translation.patient_adult_knee_function_q30_q2_a1_o3
                            }
                            selected={currsports === "18"}
                            onChange={currhandleSports}
                            styleClass="custom_radio_box large"
                            value="18"
                            id="sports18"
                            name="currsports"
                          />
                        </>
                      )}

                      {highestActivityL === "3" && (
                        <>
                          <RadioButton
                            label={
                              translation.patient_adult_knee_function_q30_q2_a2_o1
                            }
                            styleClass="custom_radio_box large"
                            selected={currsports === "19"}
                            value="19"
                            id="sports19"
                            name="currsports"
                            onChange={currhandleSports}
                          />
                          <RadioButton
                            label={
                              translation.patient_adult_knee_function_q30_q2_a2_o2
                            }
                            styleClass="custom_radio_box large"
                            selected={currsports === "20"}
                            value="20"
                            id="sports20"
                            name="currsports"
                            onChange={currhandleSports}
                          />
                          <RadioButton
                            label={
                              translation.patient_adult_knee_function_q30_q2_a2_o3
                            }
                            styleClass="custom_radio_box large"
                            selected={currsports === "21"}
                            value="21"
                            id="sports21"
                            name="currsports"
                            onChange={currhandleSports}
                          />
                          <RadioButton
                            label={
                              translation.patient_adult_knee_function_q30_q2_a2_o4
                            }
                            styleClass="custom_radio_box large"
                            selected={currsports === "22"}
                            value="22"
                            id="sports22"
                            name="currsports"
                            onChange={currhandleSports}
                          />
                          <RadioButton
                            label={
                              translation.patient_adult_knee_function_q30_q2_a2_o5
                            }
                            styleClass="custom_radio_box large"
                            selected={currsports === "23"}
                            value="23"
                            id="sports23"
                            name="currsports"
                            onChange={currhandleSports}
                          />
                        </>
                      )}

                      {highestActivityL === "2" && (
                        <>
                          <RadioButton
                            label={
                              translation.patient_adult_knee_function_q30_q2_a3_o1
                            }
                            styleClass="custom_radio_box large"
                            selected={currsports === "24"}
                            value="24"
                            id="sports24"
                            name="currsports"
                            onChange={currhandleSports}
                          />
                          <RadioButton
                            label={
                              translation.patient_adult_knee_function_q30_q2_a3_o2
                            }
                            styleClass="custom_radio_box large"
                            selected={currsports === "25"}
                            value="25"
                            id="sports25"
                            name="currsports"
                            onChange={currhandleSports}
                          />
                          <RadioButton
                            label={
                              translation.patient_adult_knee_function_q30_q2_a3_o3
                            }
                            styleClass="custom_radio_box large"
                            selected={currsports === "26"}
                            value="26"
                            id="sports26"
                            name="currsports"
                            onChange={currhandleSports}
                          />
                          <RadioButton
                            label={
                              translation.patient_adult_knee_function_q30_q2_a3_o4
                            }
                            styleClass="custom_radio_box large"
                            selected={currsports === "27"}
                            value="27"
                            id="sports27"
                            name="currsports"
                            onChange={currhandleSports}
                          />
                        </>
                      )}

                      {highestActivityL === "1" && (
                        <>
                          <RadioButton
                            label={
                              translation.patient_adult_knee_function_q30_q2_a4_o1
                            }
                            styleClass="custom_radio_box large"
                            selected={currsports === "28"}
                            value="28"
                            id="sports28"
                            name="currsports"
                            onChange={currhandleSports}
                          />
                          <RadioButton
                            label={
                              translation.patient_adult_knee_function_q30_q2_a4_o2
                            }
                            styleClass="custom_radio_box large"
                            selected={currsports === "29"}
                            value="29"
                            id="sports29"
                            name="currsports"
                            onChange={currhandleSports}
                          />
                          <RadioButton
                            label={
                              translation.patient_adult_knee_function_q30_q2_a4_o3
                            }
                            styleClass="custom_radio_box large"
                            selected={currsports === "30"}
                            value="30"
                            id="sports30"
                            name="currsports"
                            onChange={currhandleSports}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row pt_20">
                {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                  <div className={"col-md-6 col-sm-6" + RightSide()}>
                    <div className="mb_10">
                      <RadioSwitch
                        labelLeft={
                          translation.patient_adult_knee_function_q31_a1
                        }
                        labelRight={
                          translation.patient_adult_knee_function_q31_a2
                        }
                        idLeft="patellarInstabilityNoR"
                        idRight="patellarInstabilityYesR"
                        name="patellarInstabilityR"
                        title={
                          translation.patient_adult_knee_function_q31 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        styleClass=""
                        value={patellarInstability}
                        onChange={handlePatellarInstability}
                        placeholder={translation.Select}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                  <div className={"col-md-6 col-sm-6" + LeftSide()}>
                    <div className="mb_10">
                      <RadioSwitch
                        labelLeft={
                          translation.patient_adult_knee_function_q31_a1
                        }
                        labelRight={
                          translation.patient_adult_knee_function_q31_a2
                        }
                        idLeft="patellarInstabilityNoL"
                        idRight="patellarInstabilityYesL"
                        name="patellarInstabilityL"
                        title={
                          translation.patient_adult_knee_function_q31 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        styleClass=""
                        value={patellarInstabilityL}
                        onChange={handlePatellarInstabilityL}
                        placeholder={translation.Select}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {doctorEnable === "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6"></div>
                  <div className="col-md-6 col-sm-6 text-end">
                    <button
                      className="next_btn btn_fill me-3"
                      onClick={() => handleNext()}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                    <button
                      className="next_btn btn_fill"
                      onClick={() => handleSubmit()}
                    >
                      {translation.submit}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
                </div>
              )}
              {doctorEnable !== "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    <Link
                      to={"/adult-knee-symptoms/" + rId}
                      className="assm_back_btn"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AdultKneeFunction;
