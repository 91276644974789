import React, { useMemo, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import RangeSlider from "../../../custom/range-slider/RangeSlider";
import FullDropdown from "../../../custom/dropdown/FullDropdown";
import KneeScoreRangeSlider from "../../../custom/knee-score-range-slider/KneeScoreRangeSlider";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "../../../customHooks/translations";
import HipTab from "../../tab-section/HipTab";
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../custom/toaster/CustomToaster";
import {
  LeftSide,
  LeftTrans,
  RightSide,
  RigthTrans,
} from "../../../../utilities/RightLeftFormat";
import {
  updateHipScores,
  updateHipPainFieldScore,
  updateHIPPainScores,
  updateHIPSymptomsScores,
  updateHIPfunctionScores,
  updateHIPQOLScores,
  updateHIPSatisfactionScores,
  updateHipFeedbackAll,
  updateAllData,
} from "../../../../redux/actions/hipAction";

// import Button from "../../custom/button/Button";
import useAxios from "../../../../axiosinstance";
import NameCustom from "../../../custom/name/NameCustom";

function Pain() {
  const translation = useTranslation();
  let lg = sessionStorage.getItem("language");
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const axiosinstance = useAxios();
  const [painTodayRatingL, setPainTodayRatingL] = useState("0");
  const [painTodayRatingR, setPainTodayRatingR] = useState("0");
  const [howBadPainRatingL, setHowBadPainRatingL] = useState("0");
  const [howBadPainRatingR, setHowBadPainRatingR] = useState("0");
  const [oftenL, setOftenL] = useState("4");
  const [oftenR, setOftenR] = useState("4");
  const [straighteningL, setStraighteningL] = useState("0");
  const [straighteningR, setStraighteningR] = useState("0");
  const [interferedL, setInterferedL] = useState("0");
  const [interferedR, setInterferedR] = useState("0");
  const [bendingL, setBendingL] = useState("0");
  const [bendingR, setBendingR] = useState("0");
  const [surfaceL, setSurfaceL] = useState("0");
  const [surfaceR, setSurfaceR] = useState("0");
  const [walkL, setWalkL] = useState("4");
  const [walkR, setWalkR] = useState("4");
  const [hardSurfaceL, setHardSurfaceL] = useState("0");
  const [hardSurfaceR, setHardSurfaceR] = useState("0");
  const [unevenSurfaceL, setUnevenSurfaceL] = useState("0");
  const [unevenSurfaceR, setUnevenSurfaceR] = useState("0");
  const [stairsL, setStairsL] = useState("0");
  const [stairsR, setStairsR] = useState("0");
  const [bedL, setBedL] = useState("0");
  const [bedR, setBedR] = useState("0");
  const [troubledL, setTroubledL] = useState("4");
  const [troubledR, setTroubledR] = useState("4");
  const [sittingL, setSittingL] = useState("0");
  const [sittingR, setSittingR] = useState("0");
  const [uprightL, setUprightL] = useState("0");
  const [uprightR, setUprightR] = useState("0");
  const [chairL, setChairL] = useState("0");
  const [chairR, setChairR] = useState("0");
  const [activityL, setActivityL] = useState("0");
  const [activityR, setActivityR] = useState("0");
  const [chk, setChk] = useState(false);
  const [backD, setbackD] = useState({});
  const [updateTotal, setUpdateTotal] = useState(false);
  const [backDoc, setBackDoc] = useState("");
  const [describesR, setdescribesR] = useState("");
  const [describesL, setdescribesL] = useState("");
  let isdoctorForm = searchParams.get("is_doctor_form");
 let viewicon= searchParams.get("edit")
  localStorage.setItem("viewicon",viewicon)
  useEffect(() => {
    if (doctorEnable === "true") {
      let doctordata = localStorage.getItem("isdoctorForm")
      localStorage.setItem("isdoctorForm", doctordata)
    } else {
      localStorage.setItem("isdoctorForm", isdoctorForm)
    }
    localStorage.setItem("isdoctorForm", isdoctorForm)
  }, [isdoctorForm])
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=6`)
      .then((res) => {
        setBackDoc(res.data.data2[0].is_doctor_form);
        dispatch(updateHIPPainScores(res.data.data2[0].painScore));
        dispatch(updateHIPSymptomsScores(res.data.data2[0].symptomsScore));
        dispatch(updateHipScores(res.data.data2[0].evlHipPain));
        dispatch(updateHIPfunctionScores(res.data.data2[0].functionScore));
        dispatch(updateHIPQOLScores(res.data.data2[0].qolScore));
        dispatch(
          updateHIPSatisfactionScores(res.data.data2[0].satisfactionScore)
        );
        dispatch(updateHipFeedbackAll(res.data.data2[0].doctorFeedback));
        setChk(true);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };

  useEffect(() => {
    save();
  }, []);

  const backData = () => {
    axiosinstance.current
      .get(`extapp/forms/urgent_data?resource_id=${rId}`)
      .then((res) => {
        setbackD(res.data.data);
        sessionStorage.setItem("ptid", res.data.data.ak_pt);
      });
  };
  useEffect(() => {
    save();
    backData();
  }, []);

  const evlHipPain = useSelector((state) => state.hipScoreReducer.evlHipPain);
  const HipPain = useSelector((state) => state.hipScoreReducer.painScore[0]);
  const json = useSelector((state) => state.hipScoreReducer);

  useEffect(() => {
    setPainTodayRatingL(HipPain.painTodayRatingL);
    setPainTodayRatingR(HipPain.painTodayRatingR);
    setHowBadPainRatingL(HipPain.howBadPainRatingL);
    setHowBadPainRatingR(HipPain.howBadPainRatingR);
    setOftenR(HipPain.oftenR);
    setOftenL(HipPain.oftenL);
    setStraighteningL(HipPain.straighteningL);
    setStraighteningR(HipPain.straighteningR);
    setInterferedL(HipPain.interferedL);
    setInterferedR(HipPain.interferedR);
    setBendingL(HipPain.bendingL);
    setBendingR(HipPain.bendingR);
    setSurfaceL(HipPain.surfaceL);
    setSurfaceR(HipPain.surfaceR);
    setWalkL(HipPain.walkL);
    setWalkR(HipPain.walkR);
    setHardSurfaceL(HipPain.hardSurfaceL);
    setHardSurfaceR(HipPain.hardSurfaceR);
    setUnevenSurfaceL(HipPain.unevenSurfaceL);
    setUnevenSurfaceR(HipPain.unevenSurfaceR);
    setStairsL(HipPain.stairsL);
    setStairsR(HipPain.stairsR);
    setBedL(HipPain.bedL);
    setBedR(HipPain.bedR);
    setTroubledL(HipPain.troubledL);
    setTroubledR(HipPain.troubledR);
    setSittingL(HipPain.sittingL);
    setSittingR(HipPain.sittingR);
    setUprightL(HipPain.uprightL);
    setUprightR(HipPain.uprightR);
    setChairL(HipPain.chairL);
    setChairR(HipPain.chairR);
    setActivityL(HipPain.activityL);
    setActivityR(HipPain.activityR);
    setdescribesR(HipPain.describesR);
    setdescribesL(HipPain.describesL);
  }, [HipPain]);

  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true"?"1":"0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [HipPain, updateTotal]);

  const navigate = useNavigate();
  const handleEvolutedHipPain = (e) => {
    dispatch(updateHipScores(e));
  };
  const handlePainTodayRatingL = (e) => {
    setPainTodayRatingL(e);
    dispatch(
      updateHipPainFieldScore({
        key: "painTodayRatingL",
        value: e,
      })
    );
  };
  const handlePainTodayRatingR = (e) => {
    setPainTodayRatingR(e);
    dispatch(
      updateHipPainFieldScore({
        key: "painTodayRatingR",
        value: e,
      })
    );
  };

  const handleHowBadPainRatingL = (e) => {
    setHowBadPainRatingL(e);
    dispatch(
      updateHipPainFieldScore({
        key: "howBadPainRatingL",
        value: e,
      })
    );
  };
  const handleHowBadPainRatingR = (e) => {
    setHowBadPainRatingR(e);
    dispatch(
      updateHipPainFieldScore({
        key: "howBadPainRatingR",
        value: e,
      })
    );
  };

  const handleOftenL = (e) => {
    setOftenL(e);
    dispatch(
      updateHipPainFieldScore({
        key: "oftenL",
        value: e,
      })
    );
  };
  const handleOftenR = (e) => {
    setOftenR(e);
    dispatch(
      updateHipPainFieldScore({
        key: "oftenR",
        value: e,
      })
    );
  };
  const handleStraighteningL = (e) => {
    setStraighteningL(e);
    dispatch(
      updateHipPainFieldScore({
        key: "straighteningL",
        value: e,
      })
    );
  };
  const handleStraighteningR = (e) => {
    setStraighteningR(e);
    dispatch(
      updateHipPainFieldScore({
        key: "straighteningR",
        value: e,
      })
    );
  };
  const handleInterferedL = (e) => {
    setInterferedL(e);
    dispatch(
      updateHipPainFieldScore({
        key: "interferedL",
        value: e,
      })
    );
  };
  const handleinterferedR = (e) => {
    setInterferedR(e);
    dispatch(
      updateHipPainFieldScore({
        key: "interferedR",
        value: e,
      })
    );
  };
  const handleBendingL = (e) => {
    setBendingL(e);
    dispatch(
      updateHipPainFieldScore({
        key: "bendingL",
        value: e,
      })
    );
  };
  const handleBendingR = (e) => {
    setBendingR(e);
    dispatch(
      updateHipPainFieldScore({
        key: "bendingR",
        value: e,
      })
    );
  };
  const handleSurfaceR = (e) => {
    setSurfaceR(e);
    dispatch(
      updateHipPainFieldScore({
        key: "surfaceR",
        value: e,
      })
    );
  };
  const handleSurfaceL = (e) => {
    setSurfaceL(e);
    dispatch(
      updateHipPainFieldScore({
        key: "surfaceL",
        value: e,
      })
    );
  };
  const handleWalkL = (e) => {
    setWalkL(e);
    dispatch(
      updateHipPainFieldScore({
        key: "walkL",
        value: e,
      })
    );
  };
  const handleWalkR = (e) => {
    setWalkR(e);
    dispatch(
      updateHipPainFieldScore({
        key: "walkR",
        value: e,
      })
    );
  };
  const handleHardSurfaceL = (e) => {
    dispatch(
      updateHipPainFieldScore({
        key: "hardSurfaceL",
        value: e,
      })
    );
    setHardSurfaceL(e);
  };
  const handleHardSurfaceR = (e) => {
    dispatch(
      updateHipPainFieldScore({
        key: "hardSurfaceR",
        value: e,
      })
    );
    setHardSurfaceR(e);
  };
  const handleUnevenSurfaceL = (e) => {
    dispatch(
      updateHipPainFieldScore({
        key: "unevenSurfaceL",
        value: e,
      })
    );
    setUnevenSurfaceL(e);
  };
  const handleUnevenSurfaceR = (e) => {
    dispatch(
      updateHipPainFieldScore({
        key: "unevenSurfaceR",
        value: e,
      })
    );
    setUnevenSurfaceR(e);
  };
  const handleStairsL = (e) => {
    dispatch(
      updateHipPainFieldScore({
        key: "stairsL",
        value: e,
      })
    );
    setStairsL(e);
  };
  const handleStairsR = (e) => {
    dispatch(
      updateHipPainFieldScore({
        key: "stairsR",
        value: e,
      })
    );
    setStairsR(e);
  };
  const handleBedL = (e) => {
    dispatch(
      updateHipPainFieldScore({
        key: "bedL",
        value: e,
      })
    );
    setBedL(e);
  };
  const handleBedR = (e) => {
    dispatch(
      updateHipPainFieldScore({
        key: "bedR",
        value: e,
      })
    );
    setBedR(e);
  };
  const handleTroubledL = (e) => {
    dispatch(
      updateHipPainFieldScore({
        key: "troubledL",
        value: e,
      })
    );
    setTroubledL(e);
  };
  const handletroubledR = (e) => {
    dispatch(
      updateHipPainFieldScore({
        key: "troubledR",
        value: e,
      })
    );
    setTroubledR(e);
  };
  const handleSittingL = (e) => {
    dispatch(
      updateHipPainFieldScore({
        key: "sittingL",
        value: e,
      })
    );
    setSittingL(e);
  };
  const handleSittingR = (e) => {
    dispatch(
      updateHipPainFieldScore({
        key: "sittingR",
        value: e,
      })
    );
    setSittingR(e);
  };
  const handleUprightL = (e) => {
    dispatch(
      updateHipPainFieldScore({
        key: "uprightL",
        value: e,
      })
    );
    setUprightL(e);
  };
  const handleUprightR = (e) => {
    dispatch(
      updateHipPainFieldScore({
        key: "uprightR",
        value: e,
      })
    );
    setUprightR(e);
  };
  const handleChairL = (e) => {
    dispatch(
      updateHipPainFieldScore({
        key: "chairL",
        value: e,
      })
    );
    setChairL(e);
  };
  const handleChairR = (e) => {
    dispatch(
      updateHipPainFieldScore({
        key: "chairR",
        value: e,
      })
    );
    setChairR(e);
  };
  const handleActivityL = (e) => {
    dispatch(
      updateHipPainFieldScore({
        key: "activityL",
        value: e,
      })
    );
    setActivityL(e);
  };
  const handleActivityR = (e) => {
    dispatch(
      updateHipPainFieldScore({
        key: "activityR",
        value: e,
      })
    );
    setActivityR(e);
  };
  const handledescribesR = (e) => {
    dispatch(
      updateHipPainFieldScore({
        key: "describesR",
        value: e,
      })
    );
    setdescribesR(e);
  };
  const handledescribesL = (e) => {
    dispatch(
      updateHipPainFieldScore({
        key: "describesL",
        value: e,
      })
    );
    setdescribesL(e);
  };
  const continueRedirect = (e) => {
    dispatch(
      updateHIPPainScores([
        {
          painTodayRatingL: painTodayRatingL,
          painTodayRatingR: painTodayRatingR,
          howBadPainRatingL: howBadPainRatingL,
          howBadPainRatingR: howBadPainRatingR,
          oftenL: oftenL,
          oftenR: oftenR,
          straighteningL: straighteningL,
          straighteningR: straighteningR,
          interferedL: interferedL,
          interferedR: interferedR,
          bendingL: bendingL,
          bendingR: bendingR,
          surfaceL: surfaceL,
          surfaceR: surfaceR,
          walkL: walkL,
          walkR: walkR,
          hardSurfaceL: hardSurfaceL,
          hardSurfaceR: hardSurfaceR,
          unevenSurfaceL: unevenSurfaceL,
          unevenSurfaceR: unevenSurfaceR,
          stairsL: stairsL,
          stairsR: stairsR,
          bedL: bedL,
          bedR: bedR,
          troubledL: troubledL,
          troubledR: troubledR,
          sittingL: sittingL,
          sittingR: sittingR,
          uprightL: uprightL,
          uprightR: uprightR,
          chairL: chairL,
          chairR: chairR,
          activityL: activityL,
          activityR: activityR,
        },
      ])
    );
    navigate("/hip-symptoms/" + rId+`?is_doctor_form=${isdoctorForm}`);
  };
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);


  let akpt = searchParams.get("akpt");
  const [msg, setMsg] = useState("");
  const [msgType, setmsgType] = useState("");
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update);
    setmsgType("success");
    setTimeout(() => {
      window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" +akpt
      setMsg("");
      setmsgType("success");
    }, 2000);
  };

  const handleNext=()=>{
    navigate(`/hip-symptoms/${rId}?d=true&akpt=${akpt}&is_doctor_form=${isdoctorForm}`);

  }

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="pain_screen mb_100">
          <div class="d-flex justify-content-end">
            <NameCustom />
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && <HipTab rId={rId} />}
              <CustomToaster msg={msg} msgType={msgType} />

              <h4 className="ph_title f_40">{translation.patient_hip_pain}</h4>

              <div className="row pt_20">
                <div className="col-md-12 col-sm-12">
                  <div className="row">
                    {evlHipPain === "1" || evlHipPain === "3" ? (
                      <div
                        className={
                          `mb_20 ${evlHipPain === "3" ? "col-sm-6 col-12" : ""
                          }` + RightSide()
                        }
                      >
                        <KneeScoreRangeSlider
                          label={
                            translation.patient_hip_pain_q1 +
                            RigthTrans(evlHipPain, translation)
                          }
                          classes=""
                          value={painTodayRatingR}
                          onChange={handlePainTodayRatingR}
                          sliderData={[
                            {
                              score: "0",
                              label: translation.patient_hip_pain_q1_a1,
                            },
                            {
                              score: "1",
                              label: translation.patient_hip_pain_q1_a2,
                            },
                            {
                              score: "2",
                              label: translation.patient_hip_pain_q1_a3,
                            },
                            {
                              score: "3",
                              label: translation.patient_hip_pain_q1_a4,
                            },
                            {
                              score: "4",
                              label: translation.patient_hip_pain_q1_a5,
                            },
                          ]}
                        />

                        {/* <RangeSlider
                          id="painTodayRatingR"
                          min="0"
                          max="10"
                          step="1"
                          type="image"
                          label={translation.patient_hip_pain_q1 + RigthTrans(evlHipPain, translation)}
                          hipSlider="1"
                          value={painTodayRatingR}
                          onChange={handlePainTodayRatingR}
                          sliderData={painTodayRatingR === "0"
                            ? translation.patient_hip_pain_q1_a1
                            : painTodayRatingR === "1"
                              ? translation.patient_hip_pain_q1_a2
                              : painTodayRatingR === "2" || painTodayRatingR === "3"
                                ? translation.patient_hip_pain_q1_a3
                                : painTodayRatingR === "4" || painTodayRatingR === "5" || painTodayRatingR === "6"
                                  ? translation.patient_hip_pain_q1_a4
                                  : painTodayRatingR === "7" || painTodayRatingR === "8" || painTodayRatingR === "9" || painTodayRatingR === "10"
                                    ? translation.patient_hip_pain_q1_a5
                                    : ""}
                        /> */}
                      </div>
                    ) : (
                      ""
                    )}

                    {evlHipPain === "2" || evlHipPain === "3" ? (
                      <div
                        className={
                          `mb_20 ${evlHipPain === "3" ? "col-sm-6 col-12" : ""
                          }` + LeftSide()
                        }
                      >
                        <KneeScoreRangeSlider
                          label={
                            translation.patient_hip_pain_q1 +
                            LeftTrans(evlHipPain, translation)
                          }
                          classes=""
                          value={painTodayRatingL}
                          onChange={handlePainTodayRatingL}
                          sliderData={[
                            {
                              score: "0",
                              label: translation.patient_hip_pain_q1_a1,
                            },
                            {
                              score: "1",
                              label: translation.patient_hip_pain_q1_a2,
                            },
                            {
                              score: "2",
                              label: translation.patient_hip_pain_q1_a3,
                            },
                            {
                              score: "3",
                              label: translation.patient_hip_pain_q1_a4,
                            },
                            {
                              score: "4",
                              label: translation.patient_hip_pain_q1_a5,
                            },
                          ]}
                        />

                        {/* <RangeSlider
                          id="painTodayRatingL"
                          min="0"
                          max="10"
                          step="1"
                          type="image"
                          label={
                            translation.patient_hip_pain_q1 + LeftTrans(evlHipPain, translation)
                          }
                          hipSlider="1"
                          value={painTodayRatingL}
                          onChange={handlePainTodayRatingL}
                          sliderData={painTodayRatingL === "0"
                            ? translation.patient_hip_pain_q1_a1
                            : painTodayRatingL === "1"
                              ? translation.patient_hip_pain_q1_a2
                              : painTodayRatingL === "2" || painTodayRatingL === "3"
                                ? translation.patient_hip_pain_q1_a3
                                : painTodayRatingL === "4" || painTodayRatingL === "5" || painTodayRatingL === "6"
                                  ? translation.patient_hip_pain_q1_a4
                                  : painTodayRatingL === "7" || painTodayRatingL === "8" || painTodayRatingL === "9" || painTodayRatingL === "10"
                                    ? translation.patient_hip_pain_q1_a5
                                    : ""}
                        /> */}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="row mt-3">
                    {evlHipPain === "1" || evlHipPain === "3" ? (
                      <div
                        className={
                          `mb_20 ${evlHipPain === "3" ? "col-sm-6 col-12" : ""
                          }` + RightSide()
                        }
                      >
                        <RangeSlider
                          id="painTodayRatingR"
                          min="0"
                          max="10"
                          step="1"
                          type="both"
                          label={
                            translation.patient_hip_pain_q2 +
                            RigthTrans(evlHipPain, translation)
                          }
                          value={howBadPainRatingR}
                          onChange={handleHowBadPainRatingR}
                          rightTitle={translation.patient_hip_pain_q2_a2}
                          leftTitle={translation.patient_hip_pain_q2_a1}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {evlHipPain === "2" || evlHipPain === "3" ? (
                      <div
                        className={
                          `mb_20 ${evlHipPain === "3" ? "col-sm-6 col-12" : ""
                          }` + LeftSide()
                        }
                      >
                        <RangeSlider
                          id="howBadPainRatingL"
                          min="0"
                          max="10"
                          step="1"
                          type="both"
                          rightTitle={translation.patient_hip_pain_q2_a2}
                          leftTitle={translation.patient_hip_pain_q2_a1}
                          label={
                            translation.patient_hip_pain_q2 +
                            LeftTrans(evlHipPain, translation)
                          }
                          value={howBadPainRatingL}
                          onChange={handleHowBadPainRatingL}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="row mt-3">
                    {evlHipPain === "1" || evlHipPain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                        <FullDropdown
                          data={[
                            {
                              value: "0",
                              label: translation.patient_hip_pain_q3_a1,
                            },
                            {
                              value: "1",
                              label: translation.patient_hip_pain_q3_a2,
                            },
                            {
                              value: "2",
                              label: translation.patient_hip_pain_q3_a3,
                            },
                            {
                              value: "3",
                              label: translation.patient_hip_pain_q3_a4,
                            },
                            {
                              value: "4",
                              label: translation.patient_hip_pain_q3_a5,
                            },
                            {
                              value: "5",
                              label: translation.patient_hip_pain_q3_a6,
                            },
                          ]}
                          label={
                            translation.patient_hip_pain_q3 +
                            RigthTrans(evlHipPain, translation)
                          }
                          styleClass=""
                          value={describesR}
                          onChange={handledescribesR}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {evlHipPain === "2" || evlHipPain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                        <div className="mb_10">
                          <FullDropdown
                            data={[
                              {
                                value: "0",
                                label: translation.patient_hip_pain_q3_a1,
                              },
                              {
                                value: "1",
                                label: translation.patient_hip_pain_q3_a2,
                              },
                              {
                                value: "2",
                                label: translation.patient_hip_pain_q3_a3,
                              },
                              {
                                value: "3",
                                label: translation.patient_hip_pain_q3_a4,
                              },
                              {
                                value: "4",
                                label: translation.patient_hip_pain_q3_a5,
                              },
                              {
                                value: "5",
                                label: translation.patient_hip_pain_q3_a6,
                              },
                            ]}
                            label={
                              translation.patient_hip_pain_q3 +
                              LeftTrans(evlHipPain, translation)
                            }
                            styleClass=""
                            value={describesL}
                            onChange={handledescribesL}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="row">
                    {evlHipPain === "1" || evlHipPain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                        <KneeScoreRangeSlider
                          label={
                            translation.patient_hip_pain_q4 +
                            RigthTrans(evlHipPain, translation)
                          }
                          classes=""
                          value={oftenR}
                          onChange={handleOftenR}
                          sliderData={[
                            {
                              score: "0",
                              label: translation.patient_hip_pain_q4_a1,
                            },
                            {
                              score: "1",
                              label: translation.patient_hip_pain_q4_a2,
                            },
                            {
                              score: "2",
                              label: translation.patient_hip_pain_q4_a3,
                            },
                            {
                              score: "3",
                              label: translation.patient_hip_pain_q4_a4,
                            },
                            {
                              score: "4",
                              label: translation.patient_hip_pain_q4_a5,
                            },
                          ]}
                        />
                        {/* <Dropdown
=======
                      <div className={"col-md-6 col-sm-6 mb_10" +RightSide()}>
                        <FullDropdown
>>>>>>> ddce1da7eb9ca2cb9ad3f3f666048630f51d4c27
                          data={[
                            { value: "4", label: translation.patient_hip_pain_q4_a1 },
                            { value: "3", label: translation.patient_hip_pain_q4_a2 },
                            { value: "2", label: translation.patient_hip_pain_q4_a3 },
                            { value: "1", label: translation.patient_hip_pain_q4_a4 },
                            { value: "0", label: translation.patient_hip_pain_q4_a5 },
                          ]}
                          label={translation.patient_hip_pain_q4 + RigthTrans(evlHipPain, translation)}
                          styleClass=""
                          value={oftenR}
                          onChange={handleOftenR}
                        /> */}
                      </div>
                    ) : (
                      ""
                    )}
                    {evlHipPain === "2" || evlHipPain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                        <div className="mb_10">
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_hip_pain_q4 +
                              LeftTrans(evlHipPain, translation)
                            }
                            classes=""
                            value={oftenL}
                            onChange={handleOftenL}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_hip_pain_q4_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_hip_pain_q4_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_hip_pain_q4_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_hip_pain_q4_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_hip_pain_q4_a5,
                              },
                            ]}
                          />

                          {/* <Dropdown
=======
                          <FullDropdown
>>>>>>> ddce1da7eb9ca2cb9ad3f3f666048630f51d4c27
                            data={[
                              { value: "4", label: translation.patient_hip_pain_q4_a1 },
                              { value: "3", label: translation.patient_hip_pain_q4_a2 },
                              { value: "2", label: translation.patient_hip_pain_q4_a3 },
                              { value: "1", label: translation.patient_hip_pain_q4_a4 },
                              { value: "0", label: translation.patient_hip_pain_q4_a5 },
                            ]}
                            label={translation.patient_hip_pain_q4 + LeftTrans(evlHipPain, translation)}
                            styleClass=""
                            value={oftenL}
                            onChange={handleOftenL}
                          /> */}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="row">
                    {evlHipPain === "1" || evlHipPain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                        <KneeScoreRangeSlider
                          label={
                            translation.patient_hip_pain_q5 +
                            RigthTrans(evlHipPain, translation)
                          }
                          classes=""
                          value={interferedR}
                          onChange={handleinterferedR}
                          sliderData={[
                            {
                              score: "0",
                              label: translation.patient_hip_pain_q5_a1,
                            },
                            {
                              score: "1",
                              label: translation.patient_hip_pain_q5_a2,
                            },
                            {
                              score: "2",
                              label: translation.patient_hip_pain_q5_a3,
                            },
                            {
                              score: "3",
                              label: translation.patient_hip_pain_q5_a4,
                            },
                            {
                              score: "4",
                              label: translation.patient_hip_pain_q5_a5,
                            },
                          ]}
                        />
                        {/* <Dropdown
                          data={[

=======
                      <div className={"col-md-6 col-sm-6 mb_10" +RightSide()}>
                        <FullDropdown
                           data={[
                         
>>>>>>> ddce1da7eb9ca2cb9ad3f3f666048630f51d4c27
                            {
                              value: "4",
                              label:
                                translation.patient_hip_pain_q5_a1,
                            },
                            {
                              value: "3",
                              label:
                                translation.patient_hip_pain_q5_a2,
                            },
                            {
                              value: "2",
                              label:
                                translation.patient_hip_pain_q5_a3,
                            },
                            {
                              value: "1",
                              label:
                                translation.patient_hip_pain_q5_a4,
                            },
                            {
                              value: "0",
                              label:
                                translation.patient_hip_pain_q5_a5,
                            },

                            ,
                          ]}
                          label={translation.patient_hip_pain_q5 + RigthTrans(evlHipPain, translation)}
                          styleClass=""
                          value={interferedR}
                          onChange={handleinterferedR}
                        /> */}
                      </div>
                    ) : (
                      ""
                    )}
                    {evlHipPain === "2" || evlHipPain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                        <KneeScoreRangeSlider
                          label={
                            translation.patient_hip_pain_q5 +
                            LeftTrans(evlHipPain, translation)
                          }
                          classes=""
                          value={interferedL}
                          onChange={handleInterferedL}
                          sliderData={[
                            {
                              score: "0",
                              label: translation.patient_hip_pain_q5_a1,
                            },
                            {
                              score: "1",
                              label: translation.patient_hip_pain_q5_a2,
                            },
                            {
                              score: "2",
                              label: translation.patient_hip_pain_q5_a3,
                            },
                            {
                              score: "3",
                              label: translation.patient_hip_pain_q5_a4,
                            },
                            {
                              score: "4",
                              label: translation.patient_hip_pain_q5_a5,
                            },
                          ]}
                        />
                        {/* <Dropdown
                          data={[
=======
                      <div className={"col-md-6 col-sm-6 mb_10" +LeftSide()}>
                        <FullDropdown
                         data={[
                         
                          {
                            value: "4",
                            label:
                              translation.patient_hip_pain_q5_a1,
                          },
                          {
                            value: "3",
                            label:
                              translation.patient_hip_pain_q5_a2,
                          },
                          {
                            value: "2",
                            label:
                              translation.patient_hip_pain_q5_a3,
                          },
                          {
                            value: "1",
                            label:
                              translation.patient_hip_pain_q5_a4,
                          },
                          {
                            value: "0",
                            label:
                              translation.patient_hip_pain_q5_a5,
                          },
>>>>>>> ddce1da7eb9ca2cb9ad3f3f666048630f51d4c27

                            {
                              value: "4",
                              label:
                                translation.patient_hip_pain_q5_a1,
                            },
                            {
                              value: "3",
                              label:
                                translation.patient_hip_pain_q5_a2,
                            },
                            {
                              value: "2",
                              label:
                                translation.patient_hip_pain_q5_a3,
                            },
                            {
                              value: "1",
                              label:
                                translation.patient_hip_pain_q5_a4,
                            },
                            {
                              value: "0",
                              label:
                                translation.patient_hip_pain_q5_a5,
                            },

                            ,
                          ]}
                          label={translation.patient_hip_pain_q5 + LeftTrans(evlHipPain, translation)}
                          styleClass=""
                          value={interferedL}
                          onChange={handleInterferedL}
                        /> */}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="function_formtop">
                    <div className="row">
                      <div className="col-md-6 col-sm-6 mb_10">
                        <strong className="d-inline-block mb_15">
                          {translation.patient_hip_pain_instruction}
                        </strong>
                        <br />
                        <span dir={lg === "ara" ? "rtl" : " "}>
                          (<strong>0</strong> ={" "}
                          {translation.patient_hip_pain_instruction_0},{" "}
                          <strong>1</strong> ={" "}
                          {translation.patient_hip_pain_instruction_1},{" "}
                          <strong>2</strong> ={" "}
                          {translation.patient_hip_pain_instruction_2},{" "}
                          <strong>3</strong> =
                          {translation.patient_hip_pain_instruction_3},{" "}
                          <strong>4</strong> ={" "}
                          {translation.patient_hip_pain_instruction_4})
                        </span>
                      </div>
                      <div className="col-md-6 col-sm-6 function_note">
                        <p className="info-note">
                          {translation.patient_hip_pain_note}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      {evlHipPain === "1" || evlHipPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_hip_pain_q6 +
                              RigthTrans(evlHipPain, translation)
                            }
                            classes=""
                            value={straighteningR}
                            onChange={handleStraighteningR}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_hip_pain_q6_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_hip_pain_q6_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_hip_pain_q6_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_hip_pain_q6_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_hip_pain_q6_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlHipPain === "2" || evlHipPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_hip_pain_q6 +
                              LeftTrans(evlHipPain, translation)
                            }
                            classes=""
                            value={straighteningL}
                            onChange={handleStraighteningL}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_hip_pain_q6_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_hip_pain_q6_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_hip_pain_q6_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_hip_pain_q6_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_hip_pain_q6_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {evlHipPain === "1" || evlHipPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_hip_pain_q7 +
                              RigthTrans(evlHipPain, translation)
                            }
                            classes=""
                            value={bendingR}
                            onChange={handleBendingR}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_hip_pain_q6_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_hip_pain_q6_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_hip_pain_q6_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_hip_pain_q6_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_hip_pain_q6_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlHipPain === "2" || evlHipPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_hip_pain_q7 +
                              LeftTrans(evlHipPain, translation)
                            }
                            classes=""
                            value={bendingL}
                            onChange={handleBendingL}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_hip_pain_q7_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_hip_pain_q7_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_hip_pain_q7_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_hip_pain_q7_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_hip_pain_q7_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      {evlHipPain === "1" || evlHipPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_hip_pain_q8 +
                              RigthTrans(evlHipPain, translation)
                            }
                            value={surfaceR}
                            onChange={handleSurfaceR}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_hip_pain_q8_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_hip_pain_q8_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_hip_pain_q8_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_hip_pain_q8_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_hip_pain_q8_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlHipPain === "2" || evlHipPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_hip_pain_q8 +
                              LeftTrans(evlHipPain, translation)
                            }
                            classes=""
                            value={surfaceL}
                            onChange={handleSurfaceL}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_hip_pain_q8_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_hip_pain_q8_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_hip_pain_q8_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_hip_pain_q8_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_hip_pain_q8_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {evlHipPain === "1" || evlHipPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <FullDropdown
                            data={[
                              {
                                value: "4",
                                label: translation.patient_hip_pain_q9_a1,
                              },
                              {
                                value: "3",
                                label: translation.patient_hip_pain_q9_a2,
                              },
                              {
                                value: "2",
                                label: translation.patient_hip_pain_q9_a3,
                              },
                              {
                                value: "1",
                                label: translation.patient_hip_pain_q9_a4,
                              },
                              {
                                value: "0",
                                label: translation.patient_hip_pain_q9_a5,
                              },
                            ]}
                            label={
                              translation.patient_hip_pain_q9 +
                              RigthTrans(evlHipPain, translation)
                            }
                            styleClass=""
                            value={walkR}
                            onChange={handleWalkR}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlHipPain === "2" || evlHipPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <FullDropdown
                            data={[
                              {
                                value: "4",
                                label: translation.patient_hip_pain_q9_a1,
                              },
                              {
                                value: "3",
                                label: translation.patient_hip_pain_q9_a2,
                              },
                              {
                                value: "2",
                                label: translation.patient_hip_pain_q9_a3,
                              },
                              {
                                value: "1",
                                label: translation.patient_hip_pain_q9_a4,
                              },
                              {
                                value: "0",
                                label: translation.patient_hip_pain_q9_a5,
                              },
                            ]}
                            label={
                              translation.patient_hip_pain_q9 +
                              LeftTrans(evlHipPain, translation)
                            }
                            styleClass=""
                            value={walkL}
                            onChange={handleWalkL}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {evlHipPain === "1" || evlHipPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_hip_pain_q10 +
                              RigthTrans(evlHipPain, translation)
                            }
                            classes=""
                            value={hardSurfaceR}
                            onChange={handleHardSurfaceR}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_hip_pain_q10_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_hip_pain_q10_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_hip_pain_q10_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_hip_pain_q10_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_hip_pain_q10_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlHipPain === "2" || evlHipPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_hip_pain_q10 +
                              LeftTrans(evlHipPain, translation)
                            }
                            classes=""
                            value={hardSurfaceL}
                            onChange={handleHardSurfaceL}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_hip_pain_q10_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_hip_pain_q10_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_hip_pain_q10_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_hip_pain_q10_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_hip_pain_q10_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {evlHipPain === "1" || evlHipPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_hip_pain_q11 +
                              RigthTrans(evlHipPain, translation)
                            }
                            classes=""
                            value={unevenSurfaceR}
                            onChange={handleUnevenSurfaceR}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_hip_pain_q11_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_hip_pain_q11_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_hip_pain_q11_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_hip_pain_q11_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_hip_pain_q11_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlHipPain === "2" || evlHipPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_hip_pain_q11 +
                              LeftTrans(evlHipPain, translation)
                            }
                            classes=""
                            value={unevenSurfaceL}
                            onChange={handleUnevenSurfaceL}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_hip_pain_q11_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_hip_pain_q11_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_hip_pain_q11_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_hip_pain_q11_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_hip_pain_q11_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {evlHipPain === "1" || evlHipPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_hip_pain_q12 +
                              RigthTrans(evlHipPain, translation)
                            }
                            classes=""
                            value={stairsR}
                            onChange={handleStairsR}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_hip_pain_q12_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_hip_pain_q12_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_hip_pain_q12_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_hip_pain_q12_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_hip_pain_q12_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlHipPain === "2" || evlHipPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_hip_pain_q12 +
                              LeftTrans(evlHipPain, translation)
                            }
                            classes=""
                            value={stairsL}
                            onChange={handleStairsL}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_hip_pain_q12_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_hip_pain_q12_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_hip_pain_q12_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_hip_pain_q12_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_hip_pain_q12_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {evlHipPain === "1" || evlHipPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_hip_pain_q13 +
                              RigthTrans(evlHipPain, translation)
                            }
                            classes=""
                            value={bedR}
                            onChange={handleBedR}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_hip_pain_q13_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_hip_pain_q13_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_hip_pain_q13_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_hip_pain_q13_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_hip_pain_q13_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlHipPain === "2" || evlHipPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_hip_pain_q13 +
                              LeftTrans(evlHipPain, translation)
                            }
                            classes=""
                            value={bedL}
                            onChange={handleBedL}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_hip_pain_q13_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_hip_pain_q13_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_hip_pain_q13_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_hip_pain_q13_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_hip_pain_q13_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {evlHipPain === "1" || evlHipPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_hip_pain_q14 +
                              RigthTrans(evlHipPain, translation)
                            }
                            styleClass=""
                            value={troubledR}
                            onChange={handletroubledR}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_hip_pain_q14_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_hip_pain_q14_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_hip_pain_q14_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_hip_pain_q14_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_hip_pain_q14_a5,
                              },
                            ]}
                          />

                          {/* <FullDropdown
                            data={[
                              { value: "0", label: "No nights " },
                              { value: "1", label:  "Only 1 or 2 nights" },
                              { value: "2", label: "Some nights " },
                              { value: "3", label: "Most nights " },
                              { value: "4", label: "Every night" },
                            ]}
                            label={
                              evlHipPain === "3"
                                ? translation.Hip_NightR
                                : translation.Hip_Night
                            }
                            styleClass=""
                            value={troubledR}
                            onChange={handletroubledR}
                          /> */}
                        </div>
                      ) : (
                        ""
                      )}
                      {evlHipPain === "2" || evlHipPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_hip_pain_q14 +
                              LeftTrans(evlHipPain, translation)
                            }
                            styleClass=""
                            value={troubledL}
                            placeholder={translation.none}
                            onChange={handleTroubledL}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_hip_pain_q14_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_hip_pain_q14_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_hip_pain_q14_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_hip_pain_q14_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_hip_pain_q14_a5,
                              },
                            ]}
                          />
                          {/* <FullDropdown
                            data={[
                              { value: "0", label: "No nights " },
                              { value: "1", label: "Only 1 or 2 nights  " },
                              { value: "2", label: "Some nights " },
                              { value: "3", label: "Most nights " },
                              { value: "4", label: "Every night" },
                            ]}
                            label={
                              evlHipPain === "3"
                                ? translation.Hip_NightL
                                : translation.Hip_Night
                            }
                            styleClass=""
                            value={troubledL}
                            placeholder="None"
                            onChange={handleTroubledL}
                          /> */}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {evlHipPain === "1" || evlHipPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_hip_pain_q15 +
                              RigthTrans(evlHipPain, translation)
                            }
                            classes=""
                            value={sittingR}
                            onChange={handleSittingR}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_hip_pain_q15_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_hip_pain_q15_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_hip_pain_q15_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_hip_pain_q15_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_hip_pain_q15_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlHipPain === "2" || evlHipPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_hip_pain_q15 +
                              LeftTrans(evlHipPain, translation)
                            }
                            classes=""
                            value={sittingL}
                            onChange={handleSittingL}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_hip_pain_q15_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_hip_pain_q15_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_hip_pain_q15_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_hip_pain_q15_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_hip_pain_q15_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {evlHipPain === "1" || evlHipPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_hip_pain_q16 +
                              RigthTrans(evlHipPain, translation)
                            }
                            classes=""
                            value={uprightR}
                            onChange={handleUprightR}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_hip_pain_q16_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_hip_pain_q16_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_hip_pain_q16_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_hip_pain_q16_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_hip_pain_q16_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlHipPain === "2" || evlHipPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_hip_pain_q16 +
                              LeftTrans(evlHipPain, translation)
                            }
                            classes=""
                            value={uprightL}
                            onChange={handleUprightL}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_hip_pain_q16_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_hip_pain_q16_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_hip_pain_q16_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_hip_pain_q16_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_hip_pain_q16_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {evlHipPain === "1" || evlHipPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_hip_pain_q17 +
                              RigthTrans(evlHipPain, translation)
                            }
                            classes=""
                            value={chairR}
                            onChange={handleChairR}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_hip_pain_q17_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_hip_pain_q17_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_hip_pain_q17_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_hip_pain_q17_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_hip_pain_q17_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlHipPain === "2" || evlHipPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_hip_pain_q17 +
                              LeftTrans(evlHipPain, translation)
                            }
                            classes=""
                            value={chairL}
                            onChange={handleChairL}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_hip_pain_q17_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_hip_pain_q17_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_hip_pain_q17_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_hip_pain_q17_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_hip_pain_q17_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row">
                      {evlHipPain === "1" || evlHipPain === "3" ? (
                        <div
                          className={"col-md-6 col-sm-6 mb_10" + RightSide()}
                        >
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_hip_pain_q18 +
                              RigthTrans(evlHipPain, translation)
                            }
                            classes=""
                            value={activityR}
                            onChange={handleActivityR}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_hip_pain_q18_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_hip_pain_q18_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_hip_pain_q18_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_hip_pain_q18_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_hip_pain_q18_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {evlHipPain === "2" || evlHipPain === "3" ? (
                        <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                          <KneeScoreRangeSlider
                            label={
                              translation.patient_hip_pain_q18 +
                              LeftTrans(evlHipPain, translation)
                            }
                            classes=""
                            value={activityL}
                            onChange={handleActivityL}
                            sliderData={[
                              {
                                score: "0",
                                label: translation.patient_hip_pain_q18_a1,
                              },
                              {
                                score: "1",
                                label: translation.patient_hip_pain_q18_a2,
                              },
                              {
                                score: "2",
                                label: translation.patient_hip_pain_q18_a3,
                              },
                              {
                                score: "3",
                                label: translation.patient_hip_pain_q18_a4,
                              },
                              {
                                score: "4",
                                label: translation.patient_hip_pain_q18_a5,
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {doctorEnable === "true" && (
                <div className="row mb_30 button_box">
                   <div className="col-md-6 col-sm-6">
                    {/* <Link to="/personal-data" className="assm_back_btn">
                       <img src={backIcon} alt="" />
                       <span>{translation.back_button}</span>
                     </Link> */}
                  </div>
                <div className="col-md-6 col-sm-6 text-end">
                <button
                  className="next_btn btn_fill me-3"
                  onClick={() => handleNext()}
                >
                  {translation.next_button}
                  <span>
                    <img src={nextIcon} alt="" />
                    {/* <img src={nextWhiteIcon} alt="" /> */}
                  </span>
                </button>
                <button
                  className="next_btn btn_fill"
                  onClick={() => handleSubmit()}
                >
                  {translation.submit}
                  <span>
                    <img src={nextIcon} alt="" />
                    {/* <img src={nextWhiteIcon} alt="" /> */}
                  </span>
                </button>
              </div>
              </div>
              )}


              {doctorEnable !== "true" &&viewicon!=="true"&& (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    {backDoc != 1 ? <Link
                      to={`/patient-personal-data/${window.btoa(
                        backD.ak_pt
                      )}/${window.btoa(backD.type)}?r=${window.btoa(rId)}`}
                      className="assm_back_btn"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link> : <Link
                      to={`/personal-data/${window.btoa(
                        backD.ak_pt
                      )}/${window.btoa(backD.ak_dc)}/${window.btoa(
                        backD.org_id
                      )}/${window.btoa(backD.type)}?is_doctor_form=${isdoctorForm}&doctorvalue=0`}
                      className="assm_back_btn"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>}


                    {/* <span onClick={()=>Back()} className="assm_back_btn c_pointer">
                    <img src={backIcon} alt="" />
                    <span>{translation.back_button}</span>
                  </span> */}
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}
              { viewicon==="true"  &&(
                <div className="row mb_30 button_box">
                <div className="col-md-12 col-sm-12 col-12 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                  </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Pain;
