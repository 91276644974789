import React, { useMemo, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import RangeSlider from "../../../custom/range-slider/RangeSlider";
import "./Wosi.css";
import Tab from "../../tab-section/ShoulderTab"
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../custom/toaster/CustomToaster";
import {
  updateWOSIFunctionFieldScore,
  updateWOSIFunctionScores,
  updateShoulderFunctionScores,
  updateShoulder,
  updateShoulderPainScores,
  updateSACSFunctionScores,
  updateSatisfactionFunctionScores,
  updateTotalScores,
  updateAllData,
  updateFeedbackFunctionScores
} from "../../../../redux/actions/shoulderAction";
import useAxios from "../../../../axiosinstance";
import useTransition from '../../../customHooks/translations'
import NameCustom from "../../../custom/name/NameCustom";
function Wosi() {
  const translation = useTransition();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const { rId } = useParams();
  const axiosinstance = useAxios();
  const shoulserScore = useSelector(
    (state) => state.shoulderScore.shoulserScore
  );
  const [chk, setChk] = useState(false);
  const [updateTotal, setUpdateTotal] = useState(false);

  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=4`)
      .then((res) => {
        dispatch(updateShoulder(res.data.data2[0].shoulserScore));
        dispatch(updateShoulderPainScores(res.data.data2[0].painScore));
        dispatch(updateShoulderFunctionScores(res.data.data2[0].functionScore));
        dispatch(updateWOSIFunctionScores(res.data.data2[0].wosiScore));
        dispatch(updateSACSFunctionScores(res.data.data2[0].sacsScore));
        dispatch(
          updateSatisfactionFunctionScores(res.data.data2[0].safisScore)
        );
        dispatch(updateTotalScores(res.data.data2[0].TotalScores));
        dispatch(updateFeedbackFunctionScores(res.data.data2[0].doctorFeedback))
        setChk(true);
      }).catch((err) => {

        navigate('/un-authorized-access');

      });
  };

  useEffect(() => {
    save();
  }, []);
  const [isLoading, setIsLoading] = useState(true);
  const [wosiQL1, setWosiQL1] = useState("0");
  const [wosiQR1, setWosiQR1] = useState("0");
  const [wosiQL2, setWosiQL2] = useState("0");
  const [wosiQR2, setWosiQR2] = useState("0");
  const [wosiQL3, setWosiQL3] = useState("0");
  const [wosiQR3, setWosiQR3] = useState("0");
  const [wosiQL4, setWosiQL4] = useState("0");
  const [wosiQR4, setWosiQR4] = useState("0");
  const [wosiQL5, setWosiQL5] = useState("0");
  const [wosiQR5, setWosiQR5] = useState("0");
  const [wosiQL6, setWosiQL6] = useState("0");
  const [wosiQR6, setWosiQR6] = useState("0");
  const [wosiQL7, setWosiQL7] = useState("0");
  const [wosiQR7, setWosiQR7] = useState("0");
  const [wosiQL8, setWosiQL8] = useState("0");
  const [wosiQR8, setWosiQR8] = useState("0");
  const [wosiQL9, setWosiQL9] = useState("0");
  const [wosiQR9, setWosiQR9] = useState("0");
  const [wosiQL10, setWosiQL10] = useState("0");
  const [wosiQR10, setWosiQR10] = useState("0");
  const [wosiQL11, setWosiQL11] = useState("0");
  const [wosiQR11, setWosiQR11] = useState("0");
  const [wosiQL12, setWosiQL12] = useState("0");
  const [wosiQR12, setWosiQR12] = useState("0");
  const [wosiQL13, setWosiQL13] = useState("0");
  const [wosiQR13, setWosiQR13] = useState("0");
  const [wosiQL14, setWosiQL14] = useState("0");
  const [wosiQR14, setWosiQR14] = useState("0");
  const [wosiQL15, setWosiQL15] = useState("0");
  const [wosiQR15, setWosiQR15] = useState("0");
  const [wosiQL16, setWosiQL16] = useState("0");
  const [wosiQR16, setWosiQR16] = useState("0");
  const [wosiQL17, setWosiQL17] = useState("0");
  const [wosiQR17, setWosiQR17] = useState("0");
  const [wosiQL18, setWosiQL18] = useState("0");
  const [wosiQR18, setWosiQR18] = useState("0");
  const [wosiQL19, setWosiQL19] = useState("0");
  const [wosiQR19, setWosiQR19] = useState("0");
  const [wosiQL20, setWosiQL20] = useState("0");
  const [wosiQR20, setWosiQR20] = useState("0");
  const [wosiQL21, setWosiQL21] = useState("0");
  const [wosiQR21, setWosiQR21] = useState("0");

  const navigate = useNavigate();
  const wosisScrore = useSelector((state) => state.shoulderScore.wosiScore[0]);
  const json = useSelector((state) => state.shoulderScore);
  console.log(json)
  useEffect(() => {
    setWosiQL1(wosisScrore.wosi_q1_left);
    setWosiQR1(wosisScrore.wosi_q1_right);
    setWosiQL2(wosisScrore.wosi_q2_left);
    setWosiQR2(wosisScrore.wosi_q2_right);
    setWosiQL3(wosisScrore.wosi_q3_left);
    setWosiQR3(wosisScrore.wosi_q3_right);
    setWosiQL4(wosisScrore.wosi_q4_left);
    setWosiQR4(wosisScrore.wosi_q4_right);
    setWosiQL5(wosisScrore.wosi_q5_left);
    setWosiQR5(wosisScrore.wosi_q5_right);
    setWosiQL6(wosisScrore.wosi_q6_left);
    setWosiQR6(wosisScrore.wosi_q6_right);
    setWosiQL7(wosisScrore.wosi_q7_left);
    setWosiQR7(wosisScrore.wosi_q7_right);
    setWosiQL8(wosisScrore.wosi_q8_left);
    setWosiQR8(wosisScrore.wosi_q8_right);
    setWosiQL9(wosisScrore.wosi_q9_left);
    setWosiQR9(wosisScrore.wosi_q9_right);
    setWosiQL10(wosisScrore.wosi_q10_left);
    setWosiQR10(wosisScrore.wosi_q10_right);
    setWosiQL11(wosisScrore.wosi_q11_left);
    setWosiQR11(wosisScrore.wosi_q11_right);
    setWosiQL12(wosisScrore.wosi_q12_left);
    setWosiQR12(wosisScrore.wosi_q12_right);
    setWosiQL13(wosisScrore.wosi_q13_left);
    setWosiQR13(wosisScrore.wosi_q13_right);
    setWosiQL14(wosisScrore.wosi_q14_left);
    setWosiQR14(wosisScrore.wosi_q14_right);
    setWosiQL15(wosisScrore.wosi_q15_left);
    setWosiQR15(wosisScrore.wosi_q15_right);
    setWosiQL16(wosisScrore.wosi_q16_left);
    setWosiQR16(wosisScrore.wosi_q16_right);
    setWosiQL17(wosisScrore.wosi_q17_left);
    setWosiQR17(wosisScrore.wosi_q17_right);
    setWosiQL18(wosisScrore.wosi_q18_left);
    setWosiQR18(wosisScrore.wosi_q18_right);
    setWosiQL19(wosisScrore.wosi_q19_left);
    setWosiQR19(wosisScrore.wosi_q19_right);
    setWosiQL20(wosisScrore.wosi_q20_left);
    setWosiQR20(wosisScrore.wosi_q20_right);
    setWosiQL21(wosisScrore.wosi_q21_left);
    setWosiQR21(wosisScrore.wosi_q21_right);
  }, [wosisScrore]);

  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true" ? "1" : "0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [wosisScrore, updateTotal, doctorEnable]);
  const handleWosiQL1 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q1_left",
        value: e,
      })
    );
    setWosiQL1(e);
  };
  const handleWosiQR1 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q1_right",
        value: e,
      })
    );
    setWosiQR1(e);
  };

  const handleWosiQL2 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q2_left",
        value: e,
      })
    );
    setWosiQL2(e);
  };
  const handleWosiQR2 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q2_right",
        value: e,
      })
    );
    setWosiQR2(e);
  };

  const handleWosiQL3 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q3_left",
        value: e,
      })
    );
    setWosiQL3(e);
  };
  const handleWosiQR3 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q3_right",
        value: e,
      })
    );
    setWosiQR3(e);
  };

  const handleWosiQL4 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q4_left",
        value: e,
      })
    );
    setWosiQL4(e);
  };
  const handleWosiQR4 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q4_right",
        value: e,
      })
    );
    setWosiQR4(e);
  };

  const handleWosiQL5 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q5_left",
        value: e,
      })
    );
    setWosiQL5(e);
  };
  const handleWosiQR5 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q5_right",
        value: e,
      })
    );
    setWosiQR5(e);
  };

  const handleWosiQL6 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q6_left",
        value: e,
      })
    );
    setWosiQL6(e);
  };
  const handleWosiQR6 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q6_right",
        value: e,
      })
    );
    setWosiQR6(e);
  };

  const handleWosiQL7 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q7_left",
        value: e,
      })
    );
    setWosiQL7(e);
  };
  const handleWosiQR7 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q7_right",
        value: e,
      })
    );
    setWosiQR7(e);
  };

  const handleWosiQL8 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q8_left",
        value: e,
      })
    );
    setWosiQL8(e);
  };
  const handleWosiQR8 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q8_right",
        value: e,
      })
    );
    setWosiQR8(e);
  };

  const handleWosiQL9 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q9_left",
        value: e,
      })
    );
    setWosiQL9(e);
  };
  const handleWosiQR9 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q9_right",
        value: e,
      })
    );
    setWosiQR9(e);
  };

  const handleWosiQL10 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q10_left",
        value: e,
      })
    );
    setWosiQL10(e);
  };
  const handleWosiQR10 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q10_right",
        value: e,
      })
    );
    setWosiQR10(e);
  };

  const handleWosiQL11 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q11_left",
        value: e,
      })
    );
    setWosiQL11(e);
  };
  const handleWosiQR11 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q11_right",
        value: e,
      })
    );
    setWosiQR11(e);
  };

  const handleWosiQL12 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q12_left",
        value: e,
      })
    );
    setWosiQL12(e);
  };
  const handleWosiQR12 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q12_right",
        value: e,
      })
    );
    setWosiQR12(e);
  };

  const handleWosiQL13 = (e) => {
    setWosiQL13(e);
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q13_left",
        value: e,
      })
    );
  };
  const handleWosiQR13 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q13_right",
        value: e,
      })
    );
    setWosiQR13(e);
  };

  const handleWosiQL14 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q14_left",
        value: e,
      })
    );
    setWosiQL14(e);
  };
  const handleWosiQR14 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q14_right",
        value: e,
      })
    );
    setWosiQR14(e);
  };

  const handleWosiQL15 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q15_left",
        value: e,
      })
    );
    setWosiQL15(e);
  };
  const handleWosiQR15 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q15_right",
        value: e,
      })
    );
    setWosiQR15(e);
  };

  const handleWosiQL16 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q16_left",
        value: e,
      })
    );
    setWosiQL16(e);
  };
  const handleWosiQR16 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q16_right",
        value: e,
      })
    );
    setWosiQR16(e);
  };

  const handleWosiQL17 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q17_left",
        value: e,
      })
    );
    setWosiQL17(e);
  };
  const handleWosiQR17 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q17_right",
        value: e,
      })
    );
    setWosiQR17(e);
  };

  const handleWosiQL18 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q18_left",
        value: e,
      })
    );
    setWosiQL18(e);
  };
  const handleWosiQR18 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q18_right",
        value: e,
      })
    );
    setWosiQR18(e);
  };

  const handleWosiQL19 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q19_left",
        value: e,
      })
    );
    setWosiQL19(e);
  };
  const handleWosiQR19 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q19_right",
        value: e,
      })
    );
    setWosiQR19(e);
  };

  const handleWosiQL20 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q20_left",
        value: e,
      })
    );
    setWosiQL20(e);
  };
  const handleWosiQR20 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q20_right",
        value: e,
      })
    );
    setWosiQR20(e);
  };

  const handleWosiQL21 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q21_left",
        value: e,
      })
    );
    setWosiQL21(e);
  };
  const handleWosiQR21 = (e) => {
    dispatch(
      updateWOSIFunctionFieldScore({
        key: "wosi_q21_right",
        value: e,
      })
    );
    setWosiQR21(e);
  };

  const instabilityACjoint = useSelector((state) => state.shoulderScore.functionScore[0].instability_acjoint);

  // console.log('instabilityACjoint', instabilityACjoint)
  const continueRedirect = (e) => {
    navigate("/ac-insta/" + rId);
    // dispatch(
    //   updateWOSIFunctionScores([
    //     {
    //       wosi_q1_left: wosiQL1,
    //       wosi_q1_right: wosiQR1,
    //       wosi_q2_left: wosiQL2,
    //       wosi_q2_right: wosiQR2,
    //       wosi_q3_left: wosiQL3,
    //       wosi_q3_right: wosiQR3,
    //       wosi_q4_left: wosiQL4,
    //       wosi_q4_right: wosiQR4,
    //       wosi_q5_left: wosiQL5,
    //       wosi_q5_right: wosiQR5,
    //       wosi_q6_left: wosiQL6,
    //       wosi_q6_right: wosiQR6,
    //       wosi_q7_left: wosiQL7,
    //       wosi_q7_right: wosiQR7,
    //       wosi_q8_left: wosiQL8,
    //       wosi_q8_right: wosiQR8,
    //       wosi_q9_left: wosiQL9,
    //       wosi_q9_right: wosiQR9,
    //       wosi_q10_left: wosiQL10,
    //       wosi_q10_right: wosiQR10,
    //       wosi_q11_left: wosiQL11,
    //       wosi_q11_right: wosiQR11,
    //       wosi_q12_left: wosiQL12,
    //       wosi_q12_right: wosiQR12,
    //       wosi_q13_left: wosiQL13,
    //       wosi_q13_right: wosiQR13,
    //       wosi_q14_left: wosiQL14,
    //       wosi_q14_right: wosiQR14,
    //       wosi_q15_left: wosiQL15,
    //       wosi_q15_right: wosiQR15,
    //       wosi_q16_left: wosiQL16,
    //       wosi_q16_right: wosiQR16,
    //       wosi_q17_left: wosiQL17,
    //       wosi_q17_right: wosiQR17,
    //       wosi_q18_left: wosiQL18,
    //       wosi_q18_right: wosiQR18,
    //       wosi_q19_left: wosiQL19,
    //       wosi_q19_right: wosiQR19,
    //       wosi_q20_left: wosiQL20,
    //       wosi_q20_right: wosiQR20,
    //       wosi_q21_left: wosiQL21,
    //       wosi_q21_right: wosiQR21,
    //     },
    //   ])
    // );
  
    // if (instabilityACjoint === "1") {
    //   navigate("/sacs/" + rId);
    // } else {

    //   navigate("/satisfaction/" + rId);
    // }
  };
  let akpt = searchParams.get("akpt");

  const [msg, setMsg] = useState('');
  const [msgType, setmsgType] = useState('');
  const handleSubmit = () => {
    updateAllData(json, dispatch)
    setUpdateTotal(true)
    setMsg(translation.update)
    setUpdateTotal(!updateTotal);
    setmsgType('success')
    setTimeout(() => {
      window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + akpt
      setMsg('')
      setmsgType('success')
    }, 2000);
  }
  const handleNext = (e) => {
    navigate(`/ac-insta/${rId}?d=true&akpt=${akpt}`);

  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  const wosisScroreFF = useSelector((state) => state.shoulderScore.functionScore[0].feel_unstable);
  //   let x;
  // if(wosisScroreFF===1 && shoulserScore===1){
  // x="Left null";
  // }
  // if(wosisScroreFF===1 && shoulserScore==2){
  //   x="Right null";
  //   }
  //   if(wosisScroreFF===0 ){
  //     x="all null";
  //   }
  // console.log(wosisScroreFF);
  // console.log(x,"this is");

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="wosi_screen mb_100">
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && (
                <Tab rId={rId} />
              )}
              <CustomToaster msg={msg} msgType={msgType} />
              <NameCustom />
              <h4 className="ph_title f_40">
                {translation.patient_shoulder_wosi}
              </h4>

              <div className="function_formtop">
                <div className="row">
                  <div className="col-md-12 col-sm-12 pt_20">
                    <strong>
                      {translation.patient_shoulder_wosi_instruction}
                    </strong>
                    <p className="info-note ">
                      {translation.patient_shoulder_wosi_note}{" "}
                    </p>
                  </div>
                </div>
                <h6 class="label-title">{translation.patient_shoulder_wosi_sectionA}</h6>
                <div className="row">
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      1. {translation.patient_shoulder_wosi_q1}
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQR1"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q1_a1}
                        rightTitle={translation.patient_shoulder_wosi_q1_a2}
                        value={wosiQR1}
                        onChange={handleWosiQR1}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQL1"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q1_a1}
                        rightTitle={translation.patient_shoulder_wosi_q1_a2}
                        value={wosiQL1}
                        onChange={handleWosiQL1}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      2. {translation.patient_shoulder_wosi_q2}
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQR2"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q2_a1}
                        rightTitle={translation.patient_shoulder_wosi_q2_a2}
                        value={wosiQR2}
                        onChange={handleWosiQR2}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQL2"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q2_a1}
                        rightTitle={translation.patient_shoulder_wosi_q2_a2}
                        value={wosiQL2}
                        onChange={handleWosiQL2}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      3. {translation.patient_shoulder_wosi_q3}
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQR3"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q3_a1}
                        rightTitle={translation.patient_shoulder_wosi_q3_a2}
                        value={wosiQR3}
                        onChange={handleWosiQR3}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQL3"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q3_a1}
                        rightTitle={translation.patient_shoulder_wosi_q3_a2}
                        value={wosiQL3}
                        onChange={handleWosiQL3}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      4. {translation.patient_shoulder_wosi_q4}
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQR4"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q4_a1}
                        rightTitle={translation.patient_shoulder_wosi_q4_a2}
                        value={wosiQR4}
                        onChange={handleWosiQR4}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQL4"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q4_a1}
                        rightTitle={translation.patient_shoulder_wosi_q4_a2}
                        value={wosiQL4}
                        onChange={handleWosiQL4}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      5. {translation.patient_shoulder_wosi_q5}
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQR5"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q5_a1}
                        rightTitle={translation.patient_shoulder_wosi_q5_a2}
                        value={wosiQR5}
                        onChange={handleWosiQR5}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQL5"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q5_a1}
                        rightTitle={translation.patient_shoulder_wosi_q5_a2}
                        value={wosiQL5}
                        onChange={handleWosiQL5}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      6. {translation.patient_shoulder_wosi_q6}
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQR6"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q6_a1}
                        rightTitle={translation.patient_shoulder_wosi_q6_a2}
                        value={wosiQR6}
                        onChange={handleWosiQR6}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQL6"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q6_a1}
                        rightTitle={translation.patient_shoulder_wosi_q6_a2}
                        value={wosiQL6}
                        onChange={handleWosiQL6}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      7. {translation.patient_shoulder_wosi_q7}
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQR7"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q7_a1}
                        rightTitle={translation.patient_shoulder_wosi_q7_a2}
                        value={wosiQR7}
                        onChange={handleWosiQR7}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQL7"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q7_a1}
                        rightTitle={translation.patient_shoulder_wosi_q7_a2}
                        value={wosiQL7}
                        onChange={handleWosiQL7}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      8. {translation.patient_shoulder_wosi_q8}
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQR8"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q8_a1}
                        rightTitle={translation.patient_shoulder_wosi_q8_a2}
                        value={wosiQR8}
                        onChange={handleWosiQR8}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQL8"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q8_a1}
                        rightTitle={translation.patient_shoulder_wosi_q8_a2}
                        value={wosiQL8}
                        onChange={handleWosiQL8}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      9. {translation.patient_shoulder_wosi_q9}{" "}
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQR9"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q9_a1}
                        rightTitle={translation.patient_shoulder_wosi_q9_a2}
                        value={wosiQR9}
                        onChange={handleWosiQR9}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQL9"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q9_a1}
                        rightTitle={translation.patient_shoulder_wosi_q9_a2}
                        value={wosiQL9}
                        onChange={handleWosiQL9}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      10. {translation.patient_shoulder_wosi_q10}
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQR10"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q10_a1}
                        rightTitle={translation.patient_shoulder_wosi_q10_a2}
                        value={wosiQR10}
                        onChange={handleWosiQR10}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQL10"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q10_a1}
                        rightTitle={translation.patient_shoulder_wosi_q10_a2}
                        value={wosiQL10}
                        onChange={handleWosiQL10}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                </div>
              </div>

              <div className="function_formtop">
                <h6 class="label-title">
                  {translation.patient_shoulder_wosi_sectionB}
                </h6>
                <div className="row">
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      11. {translation.patient_shoulder_wosi_q11}
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQR11"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q11_a1}
                        rightTitle={translation.patient_shoulder_wosi_q11_a2}
                        value={wosiQR11}
                        onChange={handleWosiQR11}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQL11"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q11_a1}
                        rightTitle={translation.patient_shoulder_wosi_q11_a2}
                        value={wosiQL11}
                        onChange={handleWosiQL11}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      12. {translation.patient_shoulder_wosi_q12}{" "}
                      <small>
                        ({translation.patient_shoulder_wosi_q12_note})
                      </small>
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQR12"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q12_a1}
                        rightTitle={translation.patient_shoulder_wosi_q12_a2}
                        value={wosiQR12}
                        onChange={handleWosiQR12}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQL12"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q12_a1}
                        rightTitle={translation.patient_shoulder_wosi_q12_a2}
                        value={wosiQL12}
                        onChange={handleWosiQL12}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      13. {translation.patient_shoulder_wosi_q13}
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQR13"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q13_a1}
                        rightTitle={translation.patient_shoulder_wosi_q13_a2}
                        value={wosiQR13}
                        onChange={handleWosiQR13}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQL13"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q13_a1}
                        rightTitle={translation.patient_shoulder_wosi_q13_a2}
                        value={wosiQL13}
                        onChange={handleWosiQL13}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      14. {translation.patient_shoulder_wosi_q14}
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQR14"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q14_a1}
                        rightTitle={translation.patient_shoulder_wosi_q14_a2}
                        value={wosiQR14}
                        onChange={handleWosiQR14}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQL14"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q14_a1}
                        rightTitle={translation.patient_shoulder_wosi_q14_a2}
                        value={wosiQL14}
                        onChange={handleWosiQL14}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                </div>
              </div>

              <div className="function_formtop">
                <h6 class="label-title">{translation.patient_shoulder_wosi_sectionC}</h6>
                <div className="row">
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      15. {translation.patient_shoulder_wosi_q15}
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQR15"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q15_a1}
                        rightTitle={translation.patient_shoulder_wosi_q15_a2}
                        value={wosiQR15}
                        onChange={handleWosiQR15}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQL15"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q15_a1}
                        rightTitle={translation.patient_shoulder_wosi_q15_a2}
                        value={wosiQL15}
                        onChange={handleWosiQL15}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      16. {translation.patient_shoulder_wosi_q16}
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQR16"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q16_a1}
                        rightTitle={translation.patient_shoulder_wosi_q16_a2}
                        value={wosiQR16}
                        onChange={handleWosiQR16}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQL16"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q16_a1}
                        rightTitle={translation.patient_shoulder_wosi_q16_a2}
                        value={wosiQL16}
                        onChange={handleWosiQL16}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      17. {translation.patient_shoulder_wosi_q17}
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQR17"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q17_a1}
                        rightTitle={translation.patient_shoulder_wosi_q17_a2}
                        value={wosiQR17}
                        onChange={handleWosiQR17}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQL17"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q17_a1}
                        rightTitle={translation.patient_shoulder_wosi_q17_a2}
                        value={wosiQL17}
                        onChange={handleWosiQL17}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      18. {translation.patient_shoulder_wosi_q18}
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQR18"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q18_a1}
                        rightTitle={translation.patient_shoulder_wosi_q18_a2}
                        value={wosiQR18}
                        onChange={handleWosiQR18}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQL18"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q18_a1}
                        rightTitle={translation.patient_shoulder_wosi_q18_a2}
                        value={wosiQL18}
                        onChange={handleWosiQL18}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                </div>
              </div>

              <div className="function_formtop">
                <h6 class="label-title">{translation.patient_shoulder_wosi_sectionD}</h6>
                <div className="row">
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      19. {translation.patient_shoulder_wosi_q19}
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQR19"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q19_a1}
                        rightTitle={translation.patient_shoulder_wosi_q19_a2}
                        value={wosiQR19}
                        onChange={handleWosiQR19}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQL19"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q19_a1}
                        rightTitle={translation.patient_shoulder_wosi_q19_a2}
                        value={wosiQL19}
                        onChange={handleWosiQL19}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      20. {translation.patient_shoulder_wosi_q20}
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQR20"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q20_a1}
                        rightTitle={translation.patient_shoulder_wosi_q20_a2}
                        value={wosiQR20}
                        onChange={handleWosiQR20}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQL20"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q20_a1}
                        rightTitle={translation.patient_shoulder_wosi_q20_a2}
                        value={wosiQL20}
                        onChange={handleWosiQL20}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                  <div className="col-md-12 col-sm-12 mb_20">
                    <label className="lab_title">
                      21. {translation.patient_shoulder_wosi_q21}
                    </label>
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQR21"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Right_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q21_a1}
                        rightTitle={translation.patient_shoulder_wosi_q21_a2}
                        value={wosiQR21}
                        onChange={handleWosiQR21}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <RangeSlider
                        id="wosiQL21"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        label={translation.Left_Arm}
                        leftTitle={translation.patient_shoulder_wosi_q21_a1}
                        rightTitle={translation.patient_shoulder_wosi_q21_a2}
                        value={wosiQL21}
                        onChange={handleWosiQL21}
                        formGroupClass="label_style mb_40"
                      />
                    ) : null}

                  </div>
                </div>
              </div>
              {doctorEnable === "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6">
                    {/* <Link to="/personal-data" className="assm_back_btn">
                       <img src={backIcon} alt="" />
                       <span>{translation.back_button}</span>
                     </Link> */}
                  </div>
                  <div className="col-md-6 col-sm-6 text-end">
                    <button
                      className="next_btn btn_fill me-3"
                      onClick={(e) => handleNext(e)}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                    <button
                      className="next_btn btn_fill"
                      onClick={() => handleSubmit()}
                    >
                      {translation.submit}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
                </div>
              )}
              {doctorEnable !== "true" && (

                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">

                    <Link to={"/feeling-insta/" + rId} className="assm_back_btn">

                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Wosi;
